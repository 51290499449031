import React from 'react';

export default function Error404() {
  return (
    <section id='error' className='vh-100 background-dark-primary'>
      <div className="container d-flex align-items-center h-100 justify-content-center">
        <div className="row col-lg-9 col-12 g-4">
            <h1 className='text-white text-center m-0 fw-bold fs-56'>404</h1>
            <h2 className='text-white text-center m-0 fw-semibold mb-2 fs-40'>Oops! Page Not Found</h2>
            <p className='text-dark-tertiary text-center m-0 mb-5'>Looks like this page is hiding! But don’t worry—here’s where you can go next</p>
            <div className="col">
              <a className='btn btn-dark btn-dark-primary mx-auto w-100 h-100' href='https://www.analyticsvidhya.com/blog/'>Blogs</a>
            </div>
            <div className="col">
              <a className='btn btn-dark btn-dark-primary mx-auto w-100 h-100' href='https://www.analyticsvidhya.com/events/'>Events</a>
            </div>
            <div className="col">
              <a className='btn btn-dark btn-dark-primary mx-auto w-100 h-100' href='https://www.analyticsvidhya.com/datahack/'>Competitions</a>
            </div>
            <div className="col">
              <a className='btn btn-dark btn-dark-primary mx-auto w-100 h-100' href='https://www.analyticsvidhya.com/all-free-courses'>Free Courses</a>
            </div>
            <div className="col">
              <a className='col btn btn-dark btn-dark-primary mx-auto w-100 h-100' href='https://www.analyticsvidhya.com/contact/'>Contact Us</a>
            </div>
        </div>
      </div>
    </section>
  )
}
