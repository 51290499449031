import { getQueryData, getQueryDataElevate, getQueryDataElevateUser } from '../../utils';
import { FETCH_BLACKBELT_CONTENT_FAILURE, FETCH_BLACKBELT_CONTENT_REQUEST, FETCH_BLACKBELT_CONTENT_SUCCESS, FETCH_BLOG_CONTENT_SUCCESS, FETCH_CLOSED_BLOGATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_CLOSED_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_CLOSED_JOBATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_COMMENT_CONTENT_SUCCESS, FETCH_COMPETITION_CONTENT_SUCCESS, FETCH_COMPETITION_DETAIL_CONTENT_SUCCESS, FETCH_COMPETITION_LIST_CONTENT_SUCCESS, FETCH_DATAHOUR_CONTENT_SUCCESS, FETCH_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS, FETCH_EVENT_DATAHOUR_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS, FETCH_EVENT_LIST_CONTENT_SUCCESS, FETCH_FREE_COURSES_CONTENT_FAILURE, FETCH_FREE_COURSES_CONTENT_REQUEST, FETCH_FREE_COURSES_CONTENT_SUCCESS, FETCH_GENAI_CONTENT_FAILURE, FETCH_GENAI_CONTENT_REQUEST, FETCH_GENAI_CONTENT_SUCCESS, FETCH_HACKATHON_CONTENT_SUCCESS, FETCH_HOMEPAGE_CONTENT_FAILURE, FETCH_HOMEPAGE_CONTENT_REQUEST, FETCH_HOMEPAGE_CONTENT_SUCCESS, FETCH_PAST_EVENT_BY_PAGE_NUMBER_CONTENT_SUCCESS, UPDATE_WELCOME_LOGIN_MODAL, FETCH_HACKATHON_DETAIL_CONTENT_SUCCESS, FETCH_HACKATHON_DETAIL_REGISTRATION_SUCCESS, FETCH_HACKATHON_DETAIL_SUBMISSION_SUCCESS, FETCH_HACKATHON_TEAM_DETAIL_SUCCESS, FETCH_LATE_SUBMISSION_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_BLACKBELT_LEAD_PAGES_CONTENT_SUCCESS, FETCH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_ACTIVE_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, CLEAR_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS, CLEAR_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS, CLEAR_CSRF, FETCH_AGENTICAI_CONTENT_REQUEST, FETCH_AGENTICAI_CONTENT_SUCCESS, FETCH_AGENTICAI_CONTENT_FAILURE, FETCH_AGENTICAI_LEAD_PAGES_CONTENT_SUCCESS } from '../Contants/index';
import { FETCH_LEADING_WITH_DATA_LIST_CONTENT_SUCCESS, FETCH_LAST_WEEK_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_LAST_MONTH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_LAST_3_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_LAST_6_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS, FETCH_EVENT_LEADING_WITH_DATA_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS, FETCH_GENAI_LEAD_PAGES_CONTENT_SUCCESS, FETCH_BLOGATHON_LIST_CONTENT_SUCCESS, CLEAR_HACKATHON_DETAIL_CONTENT_SUCCESS } from '../Contants/index';

// Actions for GenAiPinnacle 
export const fetchGenAIContentRequest = () => ({
    type: FETCH_GENAI_CONTENT_REQUEST,
});
  
export const fetchGenAIContentSuccess = (data) => ({
    type: FETCH_GENAI_CONTENT_SUCCESS,
    payload: data,
});
  
export const fetchGenAIContentFailure = (error) => ({
    type: FETCH_GENAI_CONTENT_FAILURE,
    payload: error,
});
  
export const fetchGenAIContent = (email) => {
    return (dispatch) => {
        dispatch(fetchGenAIContentRequest());
        const formData = new FormData();
        if(email){
            formData.append('query', `
                query MyQuery {
                    getPlatformJsonDataByName(platformName: "genaipinnacle",utmMedium: "${email}",pageLink: "${window.location.origin}${window.location.pathname}") {
                        platformData
                        platform {
                        platformName
                        }
                    }
                    getOfferByPlatformName(platformName: "genaipinnacle", email:"${email}") {
                        active
                        pointers
                        discountPercentage
                        discountEndDate
                        heading
                        discountHeadings
                        cta
                        text
                    }
                }
            ` );
        }
        else{
            formData.append('query', `
                query MyQuery {
                    getPlatformJsonDataByName(platformName: "genaipinnacle") {
                        platformData
                        platform {
                        platformName
                        }
                    }
                }
            ` );
        }
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit",
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            dispatch(fetchGenAIContentSuccess(data));
        })
        .catch((error) => {
            dispatch(fetchGenAIContentFailure(error.message));
        });
        
    };
};


// Actions for Blackbelt 
export const fetchBlackbeltContentRequest = () => ({
    type: FETCH_BLACKBELT_CONTENT_REQUEST,
});
  
export const fetchBlackbeltContentSuccess = (data) => ({
    type: FETCH_BLACKBELT_CONTENT_SUCCESS,
    payload: data,
});
  
export const fetchBlackbeltContentFailure = (error) => ({
    type: FETCH_BLACKBELT_CONTENT_FAILURE,
    payload: error,
});
  
export const fetchBlackbeltContent = () => {
    return (dispatch) => {
        dispatch(fetchBlackbeltContentRequest());
        const formData = new FormData();
        formData.append('query', `
            query MyQuery {
            getPlatformJsonDataByName(platformName: "blackbelt") {
                platformData
                platform {
                platformName
                }
            }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit"
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            dispatch(fetchBlackbeltContentSuccess(data));
        })
        .catch((error) => {
            dispatch(fetchBlackbeltContentFailure(error.message));
        });
    };
};


// Actions for Homepage 
export const fetchHomepageContentRequest = () => ({
    type: FETCH_HOMEPAGE_CONTENT_REQUEST,
});
  
export const fetchHomepageContentSuccess = (data) => ({
    type: FETCH_HOMEPAGE_CONTENT_SUCCESS,
    payload: data,
});
  
export const fetchHomepageContentFailure = (error) => ({
    type: FETCH_HOMEPAGE_CONTENT_FAILURE,
    payload: error,
});
  
export const fetchHomepageContent = () => {
    return (dispatch) => {
        dispatch(fetchHomepageContentRequest());
        const formData = new FormData();
        formData.append('query', `
            query MyQuery {
            getPlatformJsonDataByName(platformName: "homepage") {
                platformData
                platform {
                platformName
                }
            }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit"
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            dispatch(fetchHomepageContentSuccess(data));
        })
        .catch((error) => {
            dispatch(fetchHomepageContentFailure(error.message));
        });
    };
};


// Actions for Blog 
export const fetchBlogContentSuccess = (data) => ({
    type: FETCH_BLOG_CONTENT_SUCCESS,
    payload: data,
});


// Actions for Datahour 
export const fetchDatahourContentSuccess = (data) => ({
    type: FETCH_DATAHOUR_CONTENT_SUCCESS ,
    payload: data,
});

// Actions for Hackathon 
export const fetchHackathonContentSuccess = (data) => ({
    type: FETCH_HACKATHON_CONTENT_SUCCESS ,
    payload: data,
});

// Actions for login modals
export const updateWelcomeLoginModal = (data) => ({
    type: UPDATE_WELCOME_LOGIN_MODAL ,
    payload: data,
});

export const clearCSRF = () => ({
    type: CLEAR_CSRF
})


// Actions for competitions
export const fetchCompetitionContentSuccess = (data) => ({
    type: FETCH_COMPETITION_CONTENT_SUCCESS,
    payload: data,
});


export const getCompetitionData = ()=>{
    return (dispatch) => {
        let query = `
        query {
            trendingTournaments(n:3) {
                id
                title
                prize
                registrationCount
                startDate
                endDate
                slug
                thumbnailImage
                teamsCount
            }
        }
        `;
        getQueryData(query,'newcompetition')
      .then((data)=>{
        dispatch(fetchCompetitionContentSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}

// Actions for Free Courses
export const fetchFreeCourseContentRequest = () => ({
    type: FETCH_FREE_COURSES_CONTENT_REQUEST,
});
  
export const fetchFreeCourseContentSuccess = (data) => ({
    type: FETCH_FREE_COURSES_CONTENT_SUCCESS,
    payload: data,
});
  
export const fetchFreeCourseContentFailure = (error) => ({
    type: FETCH_FREE_COURSES_CONTENT_FAILURE,
    payload: error,
});
  
export const fetchFreeCourseContent = (platform) => {
    return (dispatch) => {
        dispatch(fetchFreeCourseContentRequest());
        console.log('action',platform)
        const formData = new FormData();
        formData.append('query', `
            query MyQuery {
            getPlatformJsonDataByName(platformName: "${platform}") {
                platformData
                platform {
                platformName
                }
            }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit"
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            dispatch(fetchFreeCourseContentSuccess(data));
        })
        .catch((error) => {
            dispatch(fetchFreeCourseContentFailure(error.message));
        });
    };
};

// Actions for competitions list
export const fetchCompetitionListContentSuccess = (data) => ({
    type: FETCH_COMPETITION_LIST_CONTENT_SUCCESS,
    payload: data,
});

export const getCompetitionListData = ({filter,page_number1,page_number2,page_number3})=>{
    return (dispatch) => {
        let query = `
        query MyQuery {
            activeHackathonTournament: filteredTournament(
                status: "active"
                tournamentType: "hackathon"
                filter: "${filter?filter:"all"}"
                page: ${page_number1?page_number1:1}
                limit: 6
            ) {
                tournamentData {
                  companyLogo
                  prize
                  slug
                  registrationCount
                  startDate
                  teamsCount
                  thumbnailImage
                  title
                  endDate
                  bannerImage
                }
                totalPages
                totalTournamentCount
            }
            upcomingHackathonTournament: filteredTournament(
                status: "upcoming"
                tournamentType: "hackathon"
                filter: "${filter?filter:"all"}"
                page: 1
                limit: 12
            ) {
                tournamentData {
                  companyLogo
                  prize
                  slug
                  registrationCount
                  startDate
                  teamsCount
                  thumbnailImage
                  title
                  endDate
                }
                totalPages
                totalTournamentCount
            }
            closedHackathonTournament: filteredTournament(
                status: "closed"
                tournamentType: "hackathon"
                filter: "${filter?filter:"all"}"
                page: ${page_number2?page_number2:1}
                limit: 6
              ) {
                tournamentData {
                  companyLogo
                  prize
                  slug
                  registrationCount
                  startDate
                  teamsCount
                  thumbnailImage
                  title
                  endDate
                }
                totalPages
                totalTournamentCount
            }
            lateSubmissionHackthonTournament: filteredTournament(
                status: "late_submission"
                tournamentType: "hackathon"
                filter: "${filter?filter:"all"}"
                page: ${page_number3?page_number3:1}
                limit: 6
              ) {
                tournamentData {
                  companyLogo
                  prize
                  slug
                  registrationCount
                  startDate
                  teamsCount
                  thumbnailImage
                  title
                  endDate
                }
                totalPages
                totalTournamentCount
            }
          }
        `;
        getQueryData(query,'newcompetition')
      .then((data)=>{
        dispatch(fetchCompetitionListContentSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}

export const fetchClosedBlogathonByPageNumberContentSuccess = (data) => ({
    type: FETCH_CLOSED_BLOGATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS,
    payload: data,
});


export const fetchActiveHackathonByPageNumberContentSuccess = (data) => ({
    type: FETCH_ACTIVE_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS,
    payload: data,
});

export const fetchClosedHackathonByPageNumberContentSuccess = (data) => ({
    type: FETCH_CLOSED_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS,
    payload: data,
});

export const fetchLateSubmissionHackathonByPageNumberContentSuccess = (data) => ({
    type: FETCH_LATE_SUBMISSION_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS,
    payload: data,
});

export const fetchClosedJobathonByPageNumberContentSuccess = (data) => ({
    type: FETCH_CLOSED_JOBATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS,
    payload: data,
});

export const getActiveTournamentByPageNumberData = ({tournament,page_number,limit,setShowLoader,filter})=>{
    return (dispatch) => {
        let query = null;
        if(tournament === 'blogathon'){
            query = `
            query MyQuery {
                activeBlogathonTournament: filteredTournament(
                    status: "active"
                    tournamentType: "${tournament}"
                    page: ${page_number}
                    limit: ${limit}
                ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                    }
                    totalPages
                    totalTournamentCount
                  }
            }
            `;
        }
        else if(tournament === 'hackathon'){
            query = `
            query MyQuery {
                activeHackathonTournament: filteredTournament(
                    status: "active"
                    tournamentType: "hackathon"
                    filter: "${filter}"
                    page: ${page_number}
                    limit: ${limit}
                  ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                      endDate
                    }
                    totalPages
                    totalTournamentCount
                }
            }
            `;
        }
        else {
            query = `
            query MyQuery {
                activeJobathonTournament: filteredTournament(
                    status: "active"
                    tournamentType: "blogathon"
                    page: ${page_number}
                    limit: ${limit}
                ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                    }
                    totalPages
                    totalTournamentCount
                  }
                }
            }
            `;
        }
        getQueryData(query,'newcompetition')
        .then((data)=>{
            if(tournament === 'blogathon'){
                // dispatch(fetchActiveBlogathonByPageNumberContentSuccess(data.data));
            }
            else if(tournament === 'hackathon'){
                dispatch(fetchActiveHackathonByPageNumberContentSuccess(data.data));
            }
            else {
                // dispatch(fetchActiveJobathonByPageNumberContentSuccess(data.data));
            }
            setShowLoader(false);
        })
        .catch((error)=>{
            console.log(error);
            setShowLoader(false);
        })
    }
}

export const getClosedTournamentByPageNumberData = ({tournament,page_number,limit,setShowLoader,filter})=>{
    return (dispatch) => {
        let query = null;
        if(tournament === 'blogathon'){
            query = `
            query MyQuery {
                closedBlogathonTournament: filteredTournament(
                    status: "closed"
                    tournamentType: "${tournament}"
                    page: ${page_number}
                    limit: ${limit}
                ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      endDate
                      teamsCount
                      thumbnailImage
                      title
                    }
                    totalPages
                    totalTournamentCount
                  }
            }
            `;
        }
        else if(tournament === 'hackathon'){
            query = `
            query MyQuery {
                closedHackathonTournament: filteredTournament(
                    status: "closed"
                    tournamentType: "hackathon"
                    filter: "${filter}"
                    page: ${page_number}
                    limit: ${limit}
                  ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                      endDate
                    }
                    totalPages
                    totalTournamentCount
                }
            }
            `;
        }
        else {
            query = `
            query MyQuery {
                closedJobathonTournament: filteredTournament(
                    status: "closed"
                    tournamentType: "blogathon"
                    page: ${page_number}
                    limit: ${limit}
                ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                    }
                    totalPages
                    totalTournamentCount
                  }
                }
            }
            `;
        }
        getQueryData(query,'newcompetition')
        .then((data)=>{
            if(tournament === 'blogathon'){
                dispatch(fetchClosedBlogathonByPageNumberContentSuccess(data.data));
            }
            else if(tournament === 'hackathon'){
                dispatch(fetchClosedHackathonByPageNumberContentSuccess(data.data));
            }
            else {
                dispatch(fetchClosedJobathonByPageNumberContentSuccess(data.data));
            }
            setShowLoader(false);
        })
        .catch((error)=>{
            console.log(error);
            setShowLoader(false);
        })
    }
}

export const getLateSubmissionTournamentByPageNumberData = ({tournament,page_number,limit,setShowLoader,filter})=>{
    return (dispatch) => {
        let query = null;
        if(tournament === 'blogathon'){
            query = `
            query MyQuery {
                lateSubmissionBlogathonTournament: filteredTournament(
                    status: "late_submission"
                    tournamentType: "${tournament}"
                    page: ${page_number}
                    limit: ${limit}
                ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                    }
                    totalPages
                    totalTournamentCount
                  }
                }
            }
            `;
        }
        else if(tournament === 'hackathon'){
            query = `
            query MyQuery {
                lateSubmissionHackthonTournament: filteredTournament(
                    status: "late_submission"
                    tournamentType: "hackathon"
                    page: ${page_number}
                    filter: "${filter}"
                    limit: ${limit}
                  ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                      endDate
                    }
                    totalPages
                    totalTournamentCount
                }
            }
            `;
        }
        else {
            query = `
            query MyQuery {
                lateSubmissionJobathonTournament: filteredTournament(
                    status: "late_submission"
                    tournamentType: "jobathon"
                    page: ${page_number}
                    limit: ${limit}
                ) {
                    tournamentData {
                      companyLogo
                      prize
                      slug
                      registrationCount
                      startDate
                      teamsCount
                      thumbnailImage
                      title
                    }
                    totalPages
                    totalTournamentCount
                  }
                }
            }
            `;
        }
        getQueryData(query,'newcompetition')
        .then((data)=>{
            if(tournament === 'blogathon'){
                dispatch(fetchClosedBlogathonByPageNumberContentSuccess(data.data));
            }
            else if(tournament === 'hackathon'){
                dispatch(fetchLateSubmissionHackathonByPageNumberContentSuccess(data.data));
            }
            else {
                dispatch(fetchClosedJobathonByPageNumberContentSuccess(data.data));
            }
            setShowLoader(false);
        })
        .catch((error)=>{
            console.log(error);
            setShowLoader(false);
        })
    }
}

// Actions for competitions detail
export const fetchCompetitionDetailContentSuccess = (data) => ({
    type: FETCH_COMPETITION_DETAIL_CONTENT_SUCCESS,
    payload: data,
});

export const getCompetitionDetailData = (tournamentSlug)=>{
    return (dispatch) => {
        let query = `
		query MyQuery {
			pastTournaments(n: 10) {
                title
                description
                prize
                registrationCount
                startDate
                teamSet {
                  name
                }
            },
			tournamentData(tournamentSlug: "${tournamentSlug}") {
                id
                title
                startDate
                endDate
                prize
                registrationCount
                customFormId
                tournamentType {
                  name
                }
                isTeamRequired
                teamSize
                teamSet {
                  name
                }
            },
			stageModel(tournamentSlug: "${tournamentSlug}") {
				id
				title
				description
				startDate
				endDate
				stageType {
					name
				}
				discussId
				dailySubmissionLimit
				isActive
			}
		  }
		`
        getQueryData(query,'newcompetition')
      .then((data)=>{
        dispatch(fetchCompetitionDetailContentSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}

// Actions for event list
export const fetchEventListContentSuccess = (data) => ({
    type: FETCH_EVENT_LIST_CONTENT_SUCCESS,
    payload: data,
});

export const getEventListData = ()=>{
    return (dispatch) => {
        let query = `
        query MyQuery {
            upcomingDatahours {
                edges {
                    node {
                        sessionName
                        startDateTime
                        sessionIcon
                        sessionIconUrl
                        sessionCoverPic
                        sessionCoverPicUrl
                        slug
                        speaker {
                            companyLogo
                            companyLogoUrl
                            companyName
                            designation
                            displayPicture
                            displayPictureUrl
                            name
                            linkedin
                        }
                    }
                }
            }
            featuredDatahourSession {
                sessionCoverPic
                sessionCoverPicUrl
                slug
            }
        }
        `;
        getQueryData(query,'newevents')
      .then((data)=>{
        dispatch(fetchEventListContentSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}

// Actions for event list
export const fetchCommentContentSuccess = (data) => ({
    type: FETCH_COMMENT_CONTENT_SUCCESS,
    payload: data,
});

export const getCommentData = ({uniqueId,page,islogin,csrf,resourceName,filter})=>{
    return (dispatch) => {
        let query = `
        query {
            comments(filterBy: "${filter}",resourceId: "${uniqueId}", resourceName: "${resourceName}", limit:10, page:${page}) {
            commentData {
                id
                commentText
                updatedAt
                createdAt
                userId
                numLikesOnComment
                isEdited
                commentMentions
                isCommentedByUser
                numLikesOnComment
                totalClapsOnCommentByUser
                title
                replySet {
                id
                repliedText
                updatedAt
                createdAt
                userId
                numLikesOnReply
                isEdited
                replyMentions
                isRepliedByUser
                totalClapsOnReplyByUser
                }
            }
            totalPages
            }
        }`;
        if(!islogin) {
        getQueryDataElevate(query,'newdiscussion')
        .then((data)=>{
            dispatch(fetchCommentContentSuccess(data.data.comments));
        })
        .catch((error)=>{
            console.log(error);
        })
        }
        else {
        getQueryDataElevateUser(query,'newdiscussion', "True", csrf)
        .then((data)=>{
            dispatch(fetchCommentContentSuccess(data.data.comments));
        })
        .catch((error)=>{
            console.log(error);
        })
        }
    }
}


// Actions for event datahour detail
export const fetchEventDatahourDetailContentSuccess = (data) => ({
    type: FETCH_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS,
    payload: data,
});

export const clearEventDatahourDetailContentSuccess = (data) => ({
    type: CLEAR_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS,
});

// Actions for event datahour detail
export const fetchEventDatahourSpeakerSessionDetailContentSuccess = (data) => ({
    type: FETCH_EVENT_DATAHOUR_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS,
    payload: data,
});

export const getEventDatahourDetailData = ({eventSlug,csrf,islogin})=>{
    return (dispatch) => {
        let query = `
		query MyQuery {
            datahourSession(sessionSlug: "${eventSlug}") {
              isRegistered
              totalRegisterations
              session {
                description
                endDateTime
                id
                registrationCount
                isRegistered
                startDateTime
                sessionName
                zoomlink
                sessionCoverPic
                sessionCoverPicUrl
                ogImage
                ogImageUrl
                registrationDisabled
                speaker {
                  companyName
                  designation
                  displayPicture
                  displayPictureUrl
                  name
                  speakerDetail
                  id
                  linkedin
                }
              }
            }
            userPreferenceMapping {
                autoRegistered
            }
        }
		`;
        if(islogin){
            getQueryDataElevateUser(query,'newevents', "True", csrf)
            .then((data)=>{
                dispatch(fetchEventDatahourDetailContentSuccess(data.data));

                if(data.data.datahourSession && data.data.datahourSession.session && data.data.datahourSession.session.speaker) {
                    let speakerQuery = `query MyQuery {
                        pastSessionsBySpeaker(speakerId: ${data.data.datahourSession.session.speaker.id}) {
                        edges {
                            node {
                            description
                            mailDescription
                            sessionName
                            sessionCoverPic
                            sessionCoverPicUrl
                            sessionIcon
                            sessionIconUrl
                            startDateTime
                            slug
                            speaker {
                                companyLogo
                                companyLogoUrl
                                designation
                                name
                            }
                            }
                        }
                        }
                        datahourResource(sessionId: "${data.data.datahourSession.session.id}") {
                            file
                            fileUrl
                            resourceType
                            title
                            link
                            thumbnail
                            thumbnailUrl
                        }
                    }`;
                    getQueryDataElevateUser(speakerQuery,'newevents',"True", csrf)
                    .then((sessiondata)=>{
                        dispatch(fetchEventDatahourSpeakerSessionDetailContentSuccess(sessiondata.data));
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            });
        }
        else{
            getQueryData(query,'newevents')
            .then((data)=>{
                dispatch(fetchEventDatahourDetailContentSuccess(data.data));

                if(data.data.datahourSession && data.data.datahourSession.session && data.data.datahourSession.session.speaker) {
                    let speakerQuery = `query MyQuery {
                        pastSessionsBySpeaker(speakerId: ${data.data.datahourSession.session.speaker.id}) {
                        edges {
                            node {
                            description
                            mailDescription
                            sessionName
                            sessionCoverPic
                            sessionCoverPicUrl
                            sessionIcon
                            sessionIconUrl
                            startDateTime
                            slug
                            speaker {
                                companyLogo
                                companyLogoUrl
                                designation
                                name
                            }
                            }
                        }
                        }
                        datahourResource(sessionId: "${data.data.datahourSession.session.id}") {
                            file
                            fileUrl
                            resourceType
                            title
                            link
                            thumbnail
                            thumbnailUrl
                        }
                    }`;
                    getQueryData(speakerQuery,'newevents')
                    .then((sessiondata)=>{
                        dispatch(fetchEventDatahourSpeakerSessionDetailContentSuccess(sessiondata.data));
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            });
        }
    }
}


export const fetchPastEventByPageNumberContentSuccess = (data) => ({
    type: FETCH_PAST_EVENT_BY_PAGE_NUMBER_CONTENT_SUCCESS,
    payload: data,
});

export const getPastEventByPageNumberData = (page_number,setIsLoader)=>{
    return (dispatch) => {
        let query = `
        query MyQuery {
            completedDatahours(limit: 6, pageNumber: ${page_number}) {
                description
                startDateTime
                sessionName
                sessionIcon
                sessionIconUrl
                sessionCoverPic
                sessionCoverPicUrl
                slug
                speaker {
                    companyLogo
                    companyLogoUrl
                    companyName
                    designation
                    name
                    displayPicture
                    displayPictureUrl
                }
            }
            totalPages
        }
        `;
        
        getQueryData(query,'newevents')
        .then((data)=>{
            dispatch(fetchPastEventByPageNumberContentSuccess(data.data));
            setIsLoader(false);
        })
        .catch((error)=>{
            console.log(error);
            setIsLoader(false);
        })
    }
}

//Action for hackathon detail
export const fetchHackathonDetailContentSuccess = (data) => ({
    type: FETCH_HACKATHON_DETAIL_CONTENT_SUCCESS,
    payload: data,
});

export const getHackathonDetailData = (hackathonSlug,islogin,csrf,setShowLoader)=>{
    console.log("Hackathon Slug:", hackathonSlug);
    return (dispatch) => {
        let query = `
		query HackathonDetail {
            tournamentData(tournamentSlug: "${hackathonSlug}") {
                fullDataVisibility
                tournament {
                    id
                    title
                    bannerImage
                    description
                    startDate
                    endDate
                    registrationDisable
                    registrationCount
                    teamsCount
                    isTeamRequired
                    isAnonymous
                    customFormId
                    lateSubmissionAllowed
                    showLeaderboard
                    isPublic
                    allowedEmailDomains
                    tournamentType {
                        name
                    }
                    tournamentstagemappingSet {
                        stage {
                            id
                            title
                            description
                            slug
                            startDate
                            endDate
                            isActive
                            isPvtLbCreated
                            rakshakId
                            quizId
                            isAscendingLb
                            isCodeFileMandatory
                            stageType {
                                name
                            }
                            blogathonRewards
                            blogathonTimelines
                            blogathonWinners
                            rules
                            problemstatementSet {
                                id
                                title
                                description
                                dataDictionary
                                testFileUrl
                                trainFileUrl
                                sampleSubmissionUrl
                                allowedExtensions
                                allowedExtensionsCode
                            }
                        }
                    }
                }
            }
            }
		`
        if(!islogin) {
            getQueryData(query,'newcompetition')
            .then((data)=>{
              dispatch(fetchHackathonDetailContentSuccess(data.data));
              if(setShowLoader) {
                  setShowLoader(false);
              }
            })
            .catch((error)=>{
              console.log(error);
              if(setShowLoader) {
                  setShowLoader(false);
              }
            })
        }
        else {
            getQueryDataElevateUser(query,'newcompetition',"True",csrf)
            .then((data)=>{
              dispatch(fetchHackathonDetailContentSuccess(data.data));
              if(setShowLoader) {
                  setShowLoader(false);
              }
            })
            .catch((error)=>{
              console.log(error);
              if(setShowLoader) {
                  setShowLoader(false);
              }
            })
        }
    }
}

export const clearHackathonDetailContentSuccess = () => ({
    type: CLEAR_HACKATHON_DETAIL_CONTENT_SUCCESS
});

//Action for hackathon detail
export const fetchHackathonDetailRegistrationSuccess = (data) => ({
    type: FETCH_HACKATHON_DETAIL_REGISTRATION_SUCCESS,
    payload: data,
});

export const fetchHackathonDetailSubmissionSuccess = (data) => ({
    type: FETCH_HACKATHON_DETAIL_SUBMISSION_SUCCESS,
    payload: data,
});

export const getHackathonDetailRegistrationData = ({hackathonSlug,csrf})=>{
    return (dispatch) => {
        let query = `
		query {
            isUserRegistered(tournamentSlug: "${hackathonSlug}")
          }
		`
        getQueryDataElevateUser(query,'newcompetition',"True",csrf)
      .then((data)=>{
        dispatch(fetchHackathonDetailRegistrationSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}
 
export const getHackathonDetailSubmissionData = ({stageId,csrf,teamId})=>{
    return (dispatch) => {
        let query = `
		query MyQuery {
            submissionModel(stageId: ${stageId},teamId:${teamId?teamId:null}) {
              id
              description
              codeFileUrl
              submissionFileUrl
              createdAt
              privateScore
              publicScore
              state
              finalSubmission
              isLateSubmission
            }
          }
		`
        getQueryDataElevateUser(query,'newcompetition',"True",csrf)
      .then((data)=>{
        dispatch(fetchHackathonDetailSubmissionSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}


export const fetchHackathonTeamDetailSuccess = (data) => ({
    type: FETCH_HACKATHON_TEAM_DETAIL_SUCCESS,
    payload: data,
});

export const getHackathonTeamDetailData = ({tournamentId,csrf})=>{
    return (dispatch) => {
        let query = `
		query {
			teamDetailsByUserId(tournamentId:${tournamentId}) {
				isLeader
				isTeamMember
				teamData {
				id
				name
				logo
                userId
                createdAt
				allMembersWithUserId {
					userId
				}
				}
				
			}
		}
		`;
        getQueryDataElevateUser(query,'newcompetition','True',csrf)
        .then((data)=>{
            dispatch(fetchHackathonTeamDetailSuccess(data.data));
        })
        .catch((error)=>{
            console.log(error);
        })
    }
}
 
// Actions for leading with data list
export const fetchLeadingWithDataListContentSuccess = (data) => ({
    type: FETCH_LEADING_WITH_DATA_LIST_CONTENT_SUCCESS,
    payload: data,
});

export const getLeadingWithDataListData = ()=>{
    return (dispatch) => {
        // let query = `
        //     query Myquery {
        //         lastWeekData: allVideos(
        //             limit: 9, 
        //             page: 1, 
        //             status: "last week"
        //         ) {
        //           totalPages
        //           videoData {
        //             episodeNo
        //             id
        //             slug
        //             thumbnailUrl
        //             title
        //             bannerUrl
        //             description
        //             updatedAt
        //             videoUrl
        //             videoFileUrl
        //             uploadedAt
        //             duration
        //           }
        //         }
        //         lastMonthData: allVideos(
        //             limit: 9, 
        //             page: 1, 
        //             status: "last month"
        //         ) {
        //           totalPages
        //           videoData {
        //             episodeNo
        //             id
        //             slug
        //             thumbnailUrl
        //             title
        //             bannerUrl
        //             description
        //             updatedAt
        //             videoUrl
        //             videoFileUrl
        //             uploadedAt
        //             duration
        //           }
        //         }
        //         last3monthsData: allVideos(
        //             limit: 9, 
        //             page: 1, 
        //             status: "last 3 months"
        //         ) {
        //           totalPages
        //           videoData {
        //             episodeNo
        //             id
        //             slug
        //             thumbnailUrl
        //             title
        //             bannerUrl
        //             description
        //             updatedAt
        //             videoUrl
        //             videoFileUrl
        //             uploadedAt
        //             duration
        //           }
        //         }
        //         last6monthsData: allVideos(
        //             limit: 9, 
        //             page: 1, 
        //             status: "last 6 months"
        //         ) {
        //           totalPages
        //           videoData {
        //             episodeNo
        //             id
        //             slug
        //             thumbnailUrl
        //             title
        //             bannerUrl
        //             description
        //             updatedAt
        //             videoUrl
        //             videoFileUrl
        //             uploadedAt
        //             duration
        //           }
        //         }
        //       }
        //     `;
        let query = `query Myquery {
            allVideos(
                limit: 9, 
                page: 1
            ) {
                totalPages
                videoData {
                episodeNo
                id
                slug
                thumbnailUrl
                title
                bannerUrl
                description
                updatedAt
                videoUrl
                videoFileUrl
                uploadedAt
                duration
                }
            }
        }`;
        getQueryData(query,'newevents')
      .then((data)=>{
        dispatch(fetchLeadingWithDataListContentSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}

// export const fetchLastWeekLeadingWithDataByPageNumberContentSuccess = (data) => ({
//     type: FETCH_LAST_WEEK_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS,
//     payload: data,
// });
// export const fetchLastMonthLeadingWithDataByPageNumberContentSuccess = (data) => ({
//     type: FETCH_LAST_MONTH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS,
//     payload: data,
// });
// export const fetchLast3MonthsLeadingWithDataByPageNumberContentSuccess = (data) => ({
//     type: FETCH_LAST_3_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS,
//     payload: data,
// });
// export const fetchLast6MonthsLeadingWithDataByPageNumberContentSuccess = (data) => ({
//     type: FETCH_LAST_6_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS,
//     payload: data,
// });

export const fetchLeadingWithDataByPageNumberContentSuccess = (data) => ({
    type: FETCH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS,
    payload: data,
});

export const getLeadingWithDataByPageNumberData = (page_number,setIsLoader)=>{
    return (dispatch) => {
        let query = null;
        console.log("getLeadingWithDataByPageNumberData: ",page_number);
            query = `
            query {
                allVideos(
                    limit: 9, 
                    page: ${page_number}, 
                ) {
                  totalPages
                  videoData {
                    episodeNo
                    id
                    slug
                    thumbnailUrl
                    title
                    bannerUrl
                    description
                    updatedAt
                    videoUrl
                    videoFileUrl
                    uploadedAt
                    duration
                  }
                }
              }
            `;
        getQueryData(query,'newevents')
        .then((data)=>{
            dispatch(fetchLeadingWithDataByPageNumberContentSuccess(data.data)); 
            setIsLoader(false);
        })
        .catch((error)=>{
            console.log(error);
            setIsLoader(false);
        })
    }
}


//Actions for leading with data Detail
export const fetchLeadingWithDataDetailsSuccess = (data) => ({
    type: FETCH_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS,
    payload: data,
});

export const clearLeadingWithDataDetailsSuccess = (data) => ({
    type: CLEAR_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS,
    payload: data,
});

export const fetchEventLeadingWithDataSpeakerSessionDetailContentSuccess = (data) => ({
    type: FETCH_EVENT_LEADING_WITH_DATA_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS,
    payload: data,
});

export const getLeadingWithDataDetailsData = ({eventSlug})=>{
    return (dispatch) => {
        let query = `
          query MyQuery {
            videoData(slug: "${eventSlug}") {
              totalPages
              videoData {
                bannerUrl
                createdAt
                episodeNo
                description
                id
                thumbnailUrl
                title
                updatedAt
                videoUrl
                videoFileUrl
                slug
                uploadedAt
                duration
              }
              nextVideos {
                bannerUrl
                createdAt
                description
                episodeNo
                id
                slug
                thumbnailUrl
                title
                updatedAt
                videoUrl
                videoFileUrl
                uploadedAt
                duration
              }
              attendeeData {
                company
                createdAt
                designation
                id
                email
                linkedin
                name
                photoUrl
                updatedAt
                description
                logoUrl
              }
            }
          }
        `
        
        getQueryData(query,'newevents')
      .then((data)=>{
        dispatch(fetchLeadingWithDataDetailsSuccess(data.data));
        if(data.data.videoData) {
            let speakerQuery = `query MyQuery {
                attendeeData(attendeeId: ${data.data.videoData.attendeeData.id}, limit: 5, page: 1) {
                  attendeeData {
                    videos {
                      videoUrl
                      videoFileUrl
                      updatedAt
                      title
                      thumbnailUrl
                      slug
                      id
                      episodeNo
                      description
                      createdAt
                      bannerUrl
                      uploadedAt
                      duration
                    }
                  }
                }
              }`;
            getQueryData(speakerQuery,'newevents')
            .then((sessiondata)=>{
                dispatch(fetchEventLeadingWithDataSpeakerSessionDetailContentSuccess(sessiondata.data));
            })
            .catch((error)=>{
                console.log(error);
            })
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}


//Action for genai lead pages
export const fetchGenaiLeadPagesContentSuccess = (data) => ({
    type: FETCH_GENAI_LEAD_PAGES_CONTENT_SUCCESS,
    payload: data,
});

export const fetchGenaiLeadPagesContent = (platform) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('query', `
            query MyQuery {
            getPlatformJsonDataByName(platformName: "${platform}") {
                platformData
                platform {
                platformName
                }
            }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit"
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data)=>{
            dispatch(fetchGenaiLeadPagesContentSuccess(data));
        })
        .catch((error)=>{
            console.log(error);
        })
    };
};

// Actions for Blogathon list
export const fetchBlogathonListContentSuccess = (data) => ({
    type: FETCH_BLOGATHON_LIST_CONTENT_SUCCESS,
    payload: data,
});

export const getBlogathonListData = ()=>{
    return (dispatch) => {
        let query = `
        query MyQuery {
            activeBlogathonTournament: filteredTournament(
                status: "active"
                tournamentType: "blogathon"
                page: 1
                limit: 1
            ) {
                tournamentData {
                  bannerImage
                  slug
                  title
                  description
                  prize
                  startDate
                  endDate
                }
            }
            closedBlogathonTournament: filteredTournament(
                status: "closed"
                tournamentType: "blogathon"
                page: 1
                limit: 9
              ) {
                tournamentData {
                  companyLogo
                  prize
                  slug
                  registrationCount
                  startDate
                  teamsCount
                  thumbnailImage
                  title
                  endDate
                }
                totalPages
                totalTournamentCount
            }
          }
        `;
        getQueryData(query,'newcompetition')
      .then((data)=>{
        dispatch(fetchBlogathonListContentSuccess(data.data));
      })
      .catch((error)=>{
        console.log(error);
      })
    }
}


// Action for blackbelt lead pages
export const fetchBlackbeltLeadPagesContentSuccess = (data) => ({
    type: FETCH_BLACKBELT_LEAD_PAGES_CONTENT_SUCCESS,
    payload: data,
});

export const fetchBlackbeltLeadPagesContent = (platform) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('query', `
        query MyQuery {
            getPlatformJsonDataByName(platformName: "${platform}") {
                    platformData
                    platform {
                        platformName
                    }
                }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit"
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data)=>{
            dispatch(fetchBlackbeltLeadPagesContentSuccess(data));
        })
        .catch((error)=>{
            console.log(error);
        })
    };
};

// Actions for Agentic Ai
export const fetchAgenticAiContentRequest = () => ({
    type: FETCH_AGENTICAI_CONTENT_REQUEST,
});
  
export const fetchAgenticAiContentSuccess = (data) => ({
    type: FETCH_AGENTICAI_CONTENT_SUCCESS,
    payload: data,
});
  
export const fetchAgenticAiContentFailure = (error) => ({
    type: FETCH_AGENTICAI_CONTENT_FAILURE,
    payload: error,
});
  
export const fetchAgenticAiContent = () => {
    return (dispatch) => {
        dispatch(fetchAgenticAiContentRequest());
        const formData = new FormData();
        formData.append('query', `
            query MyQuery {
            getPlatformJsonDataByName(platformName: "agenticai") {
                platformData
                platform {
                platformName
                }
            }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit"
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            dispatch(fetchAgenticAiContentSuccess(data));
        })
        .catch((error) => {
            dispatch(fetchAgenticAiContentFailure(error.message));
        });
    };
};


// Action for agenticai lead pages
export const fetchAgenticaiLeadPagesContentSuccess = (data) => ({
    type: FETCH_AGENTICAI_LEAD_PAGES_CONTENT_SUCCESS,
    payload: data,
});

export const fetchAgenticaiLeadPagesContent = (platform) => {
    return (dispatch) => {
        const formData = new FormData();
        formData.append('query', `
        query MyQuery {
            getPlatformJsonDataByName(platformName: "${platform}") {
                    platformData
                    platform {
                        platformName
                    }
                }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit"
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data)=>{
            dispatch(fetchAgenticaiLeadPagesContentSuccess(data));
        })
        .catch((error)=>{
            console.log(error);
        })
    };
};