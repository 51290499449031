import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BlackbeltHome from '../Blackbelt/pages';
import FreeTrialBlackbelt from '../Blackbelt/pages/FreeTrialBlackbelt';
import BlackbeltLeadPagesOneField from '../Blackbelt/pages/BlackbeltLeadPagesOneField';
import Error404 from '../../../common/Alert/Error404';
import NewBlackbeltOneField from '../Blackbelt/pages/NewBlackbeltOneField';

export default function BlackbeltRouters() {
  return (
    <Routes>
        <Route path='/' exact element={<BlackbeltHome />} />
        <Route path='/free-trial' exact element={<FreeTrialBlackbelt />} />
        <Route path='/:platformName' exact element={<BlackbeltLeadPagesOneField />} />
        <Route path='/2/new' exact element={<NewBlackbeltOneField />}/>
        <Route path='*' element={<Error404 />} />
    </Routes>
  )
}
