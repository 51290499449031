import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import $ from 'jquery';
import moment from 'moment';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import 'swiper/css';
import 'swiper/css/pagination';
import { fetchAgenticAiContent } from '../../../../../store/Actions/Action';
import { getUrlParameter, scrollTop, setImpactIrclickid, submitRegisterationForm } from '../../../../../utils';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import ErrorAlert from '../../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import Cookies from '../../../../common/Footer/Cookies';
import Footer from '../../../../common/Footer/Footer';
import Title from '../../../../common/Header/Title';
import { getCSRF } from '../../../../../utils/Login';
import Flashstrip from '../../../../common/Header/Flashstrip';
import AgenticAiHeader from '../components/AgenticAiHeader';
import AgenticAiTwoFieldLeadModal from '../components/AgenticAiTwoFieldLeadModal';
import AgenticAiVideoModal from '../components/AgenticAiVideoModal';
import leftshap from '../../../../../assets/images/bb-image/left-shap.png';
import ReCAPTCHA from 'react-google-recaptcha';
const AgenticAiSwiperComponent1 = React.lazy(() => import("../components/AgenticAiSwiperComponent1"));
const AgenticAiSwiperComponent2 = React.lazy(() => import("../components/AgenticAiSwiperComponent2"));
const GenAiOtherProgramSwiperComponent = React.lazy(() => import("../../GenAiPinnacle/components/GenAiOtherProgramSwiperComponent"));
const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
var iti1 = null;
var iti2 = null;

function AgenticAiHome({fetchAgenticAiContent,agenticaicontent,country_name}) {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [success,setSuccess] = useState(false);
  const [iserror,setIsError] = useState(false);
  const [userDetails,setUserDetails] = useState({
    fullname:'',
    email:'',
    phone:'',
  })
  const [additionalDetail,setAdditionalDetail] = useState({
    experience:"0-3yrs"
  })
  const [formname,setFormname] = useState(null);
  const [section,setSection] = useState(null);
  const [successMessage,setSuccessMessage] = useState('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
  const [leadFormHeading,setLeadFormHeading] = useState(null);
  const [leadFormCta,setLeadFormCta] = useState(null);  
  const [csrf, setCsrf] = useState('');
  const recaptchaRef = useRef(null); 
  const [captcha, setCaptcha] = useState(false);
  const [videoUrl,setVideoUrl] = useState("");

  const [showAllFAQs, setShowAllFAQs] = useState(false);

  useEffect(() => {
    if(!agenticaicontent){
      fetchAgenticAiContent();
    }
    scrollTop();
  }, [fetchAgenticAiContent]);

  useEffect(()=>{
    const input = document.getElementById("agenticaiNumber");
    const input1 = document.getElementById('contactPhoneNumber');
    if(input){
      iti1 = intlTelInput(input, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry: country_name?country_name:"IN",
        preferredCountries: ["in", "us"],
      });
      if(country_name){
        iti1.setCountry(country_name);
      }
    }
    if(input1){
      iti2 = intlTelInput(input1, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry: country_name?country_name:"IN",
        preferredCountries: ["in", "us"],
      });
      if(country_name){
        iti2.setCountry(country_name);
      }
    }
  },[agenticaicontent])
  
  useEffect(()=>{
    $('a[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 100);
        return false;
        }
      }
    });

    $('.scrolling-method[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 100
        }, 100);
        return false;
        }
      }
    });   
  },[]);

  useEffect(()=>{
    getCSRF()
    .then((data)=>{
      setCsrf(data);
      console.log(data);
    })
    .catch((error)=>{
      console.log(error);
    })
  },[])

  useEffect(() => {
    // Timer Script
    if(country_name === "IN" && agenticaicontent){
    var date = new Date();
    var now = new Date();
    var diffInMilliSeconds = (moment((agenticaicontent && agenticaicontent.latestOffers.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
    if (diffInMilliSeconds >0){

    
    var days = Math.floor( diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;
    
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
    
    var countdownNumberEl = $('.seconds');
    var countdown = diffInMilliSeconds;
    countdownNumberEl.text(countdown);
    
    var countMin = $('.minutes')
    var countmin=minutes;
    countMin.text(countmin);
    
    var countHour = $('.hours')
    var counthour=hours;
    countHour.text(counthour);
    
    var countDay = $('.days')
    var countday=days;
    countDay.text(countday);
    
    var timer=setInterval(timer_function,1000);
    function timer_function() {
    if(--countdown<= -1)
    {
      if(countmin!=0)
      {
      countdown=59;
      --countmin;
      }
      else
      {
      if(counthour!=0)
      {
        countdown=59
        countmin=59;
        --counthour;
      }
      else
      {
        if(countday!=0)
        { 
        countdown=59
        countmin=59;
        counthour=23;
        --countday;
        }
        else
        {
        countdown=0;
          if (countdown==0&&countmin==0&&counthour==0)
          {
          var myobj=document.querySelectorAll('.offer-strip');
          myobj.remove();
          clearInterval(timer);
          }
        }
        }
      }
      }
    else
    {
      countdown=countdown
    }
    
    
    countdownNumberEl.text(countdown);
    countMin.text(countmin);
    countHour.text(counthour);
    countDay.text(countday);
    } 
    }
    else{
    var myobj=$('.offer-strip');
    myobj.remove();
    }
    }
    else if((country_name === "CAN" || country_name === "US" || country_name === "CA") && agenticaicontent){
        var date = new Date();
        var now = new Date();
        var diffInMilliSeconds = (moment((agenticaicontent && agenticaicontent.latestOffersUsAndCanada.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
        if (diffInMilliSeconds >0){

        
        var days = Math.floor( diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        
        const hours = Math.floor(diffInMilliSeconds / 3600);
        diffInMilliSeconds -= hours * 3600;
        
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
        
        var countdownNumberEl = $('.seconds');
        var countdown = diffInMilliSeconds;
        countdownNumberEl.text(countdown);
        
        var countMin = $('.minutes')
        var countmin=minutes;
        countMin.text(countmin);
        
        var countHour = $('.hours')
        var counthour=hours;
        countHour.text(counthour);
        
        var countDay = $('.days')
        var countday=days;
        countDay.text(countday);
        
        var timer=setInterval(timer_function,1000);
        function timer_function() {
        if(--countdown<= -1)
        {
        if(countmin!=0)
        {
        countdown=59;
        --countmin;
        }
        else
        {
        if(counthour!=0)
        {
            countdown=59
            countmin=59;
            --counthour;
        }
        else
        {
            if(countday!=0)
            { 
            countdown=59
            countmin=59;
            counthour=23;
            --countday;
            }
            else
            {
            countdown=0;
            if (countdown==0&&countmin==0&&counthour==0)
            {
            var myobj=document.querySelectorAll('.offer-strip');
            myobj.remove();
            clearInterval(timer);
            }
            }
            }
        }
        }
        else
        {
        countdown=countdown
        }
        
        
        countdownNumberEl.text(countdown);
        countMin.text(countmin);
        countHour.text(counthour);
        countDay.text(countday);
        } 
        }
        else{
        var myobj=$('.offer-strip');
        myobj.remove();
        }
    }
    else if((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && agenticaicontent){
        var date = new Date();
        var now = new Date();
        var diffInMilliSeconds = (moment((agenticaicontent && agenticaicontent.latestOffersRestOfWorld.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
        if (diffInMilliSeconds >0){

        
        var days = Math.floor( diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        
        const hours = Math.floor(diffInMilliSeconds / 3600);
        diffInMilliSeconds -= hours * 3600;
        
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
        
        var countdownNumberEl = $('.seconds');
        var countdown = diffInMilliSeconds;
        countdownNumberEl.text(countdown);
        
        var countMin = $('.minutes')
        var countmin=minutes;
        countMin.text(countmin);
        
        var countHour = $('.hours')
        var counthour=hours;
        countHour.text(counthour);
        
        var countDay = $('.days')
        var countday=days;
        countDay.text(countday);
        
        var timer=setInterval(timer_function,1000);
        function timer_function() {
        if(--countdown<= -1)
        {
        if(countmin!=0)
        {
        countdown=59;
        --countmin;
        }
        else
        {
        if(counthour!=0)
        {
            countdown=59
            countmin=59;
            --counthour;
        }
        else
        {
            if(countday!=0)
            { 
            countdown=59
            countmin=59;
            counthour=23;
            --countday;
            }
            else
            {
            countdown=0;
            if (countdown==0&&countmin==0&&counthour==0)
            {
            var myobj=document.querySelectorAll('.offer-strip');
            myobj.remove();
            clearInterval(timer);
            }
            }
            }
        }
        }
        else
        {
        countdown=countdown
        }
        
        
        countdownNumberEl.text(countdown);
        countMin.text(countmin);
        countHour.text(counthour);
        countDay.text(countday);
        } 
        }
        else{
        var myobj=$('.offer-strip');
        myobj.remove();
        }
    }
}, [agenticaicontent])

  const saveLeadForm = (e)=>{
      e.preventDefault();
      let country_code = '+'+iti1.getSelectedCountryData().dialCode;    
      if(!validatedMobile(phone)){
          $('.error').remove();
          $('#agenticaiNumber').closest('.col-xl').append(`<small class="text-danger error text-start d-block">Enter a valid phone number</small>`);
          $('#agenticaiNumber').css('border-color','red');
          $('#agenticaiNumber').css('color','red');
          setTimeout(()=>{
              $('#agenticaiNumber').css('border-color','#383838');
              $('#agenticaiNumber').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },3000)
          return false;
      }
      if(!validatedEmail(email)){
        $('.error').remove();
        $('#agenticaiEmail').closest('.col-xl').append(`<small class="text-danger error text-start d-block">Enter a valid email Id</small>`);
        $('#agenticaiEmail').css('border-color','red');
        $('#agenticaiEmail').css('color','red');
        setTimeout(()=>{
            $('#agenticaiEmail').css('border-color','#383838');
            $('#agenticaiEmail').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }
      let user_data = {
          "email":email,
          "phone":phone,
          "country_code":country_code,
      }
      let additional_detail ={
        "tnc":true,
        "is_whatsapp":true,
        "section":"hero_fold",
        "page_url":window.location.href
      }
      setPhone('');
        setEmail('');
        setSuccess(true);
        setSuccessMessage('Generating Brochure. Check Your Inbox')
        setTimeout(()=>{
          setSuccess(false);
          setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
        },4000)
        submitRegisterationForm(user_data,additional_detail,'agenticai',"brochure",csrf)
        .then((data)=>{
          
        })
      .catch((error)=>{
        console.log(error)
        // setIsError(true);
        // setTimeout(()=>{
        //   setIsError(false);
        // },4000)
      })
  }

  const playVideo = ()=>{
    $('#modalVideo').trigger('play');
  }

  const updateLeadForm = (formname,heading,cta,section)=>{
    setFormname(formname);
    setLeadFormHeading(heading);
    setLeadFormCta(cta);
    setSection(section)
  }

  function onChange(value) {
    setCaptcha(true);
  }

  const updateExperience = (value)=>{
    setAdditionalDetail((prev)=>{return({...prev,experience:value})})
  }

  const saveContactForm = (e)=>{
    e.preventDefault();
    let country_code = '+'+iti2.getSelectedCountryData().dialCode;
    if(userDetails.fullname === ''){
        $('.error').remove();
        $('#contactFullName').parent().append(`<small class="text-danger error d-block">Please Enter Your Full Name</small>`);
        $('#contactFullName').css('border-color','red');
        $('#contactFullName').css('color','red');
        setTimeout(()=>{
            $('#contactFullName').css('border-color','#383838');
            $('#contactFullName').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }
    if(!validatedMobile(userDetails.phone)){
      $('.error').remove();
      $('#contactPhoneNumber').closest('.col-md-12').append(`<small class="text-danger error d-block">Enter a valid phone number</small>`);
      $('#contactPhoneNumber').css('border-color','red');
      $('#contactPhoneNumber').css('color','red');
      setTimeout(()=>{
          $('#contactPhoneNumber').css('border-color','#383838');
          $('#contactPhoneNumber').css('color','white');
          $('.error').slideUp();
          $('.error').remove();
      },2000)
      return false;
  }
    if(!validatedEmail(userDetails.email)){
      $('.error').remove();
      $('#contactEmail').parent().append(`<small class="text-danger error d-block">Enter a valid email id</small>`);
      $('#contactEmail').css('border-color','red');
      $('#contactEmail').css('color','red');
      setTimeout(()=>{
          $('#contactEmail').css('border-color','#383838');
          $('#contactEmail').css('color','white');
          $('.error').slideUp();
          $('.error').remove();
      },2000)
      return false;
  }


  if(!captcha){
    setIsError(true);
        setTimeout(() => {
          setIsError(false);
    }, 2000);
    return false;
  }
   
    let user_data = {
        "fullname":userDetails.fullname,
        "email":userDetails.email,
        "phone":userDetails.phone,
        "country_code":country_code,
    }
    let additional_detail ={
      "experience":additionalDetail.experience,
      "tnc":true,
      "is_whatsapp":true,
      "section":"contact",
      "page_url":window.location.href
    }

    setUserDetails({
      fullname:'',
      email:'',
      phone:'',
    });
    setAdditionalDetail({
      experience:"0-3yrs"
    })
    recaptchaRef.current?.reset();
    setCaptcha(false);
    $('#contact1').prop('checked',true);
    setSuccess(true);
    setTimeout(()=>{
      setSuccess(false);
    },4000)
    submitRegisterationForm(user_data,additional_detail,'agenticai',"contact",csrf)
    .then((data)=>{
      
    })
    .catch((error)=>{
      console.log(error)
      // setIsError(true);
      // setTimeout(()=>{s
      //   setIsError(false);
      // },4000)
    })
  }

  const toggleEmiTab = (e)=>{
    const tab = $(e.target).prop('checked');
    if(tab){
      $('#toggleCard1').removeClass('active');
      $('#toggleCard2').addClass('active');
    }
    else{
      $('#toggleCard2').removeClass('active');
      $('#toggleCard1').addClass('active');
    }
  }

  return (
    <main className='background-dark-primary' id='agenticai'>
      <AgenticAiHeader updateLeadForm={updateLeadForm} />
      <Title title={'Agentic AI Pioneer Program'} description={'Enroll in the Agentic AI Pioneer Program and master cutting-edge AI techniques with mentorship to lead AI-driven projects confidently.'} image_og={cloudflare_size_url+'genaipinnacle/GenAI_og_image.jpg?format=avif'} title_og={'Agentic AI Pioneer Program | Master AI Agents for Automation'} url_og={'https://www.analyticsvidhya.com/agenticaipioneer'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
      <AgenticAiTwoFieldLeadModal formname={formname} setSuccess={setSuccess} setIsError={setIsError} country_name={country_name} setSuccessMessage={setSuccessMessage} leadFormCta={leadFormCta} leadFormHeading={leadFormHeading} section={section} csrf={csrf} platformName={"agenticai"}/>
      <AgenticAiVideoModal videoUrl={videoUrl} />
      {iserror && <ErrorAlert message={"Your details Could not be submitted. please try again later sometimes."} setIsError={setIsError} />}
      {success && <SuccessAlert message={successMessage} />}
      <section id='firstFold' className='bg-size-cover bg-repeat-0 bg-position-center bg-dark position-relative pb-5 coming-tag' style={{ backgroundImage:`url('https://imgcdn.analyticsvidhya.com/blackbelt/hero-image-bb.png')` }}>
        <div className='container justify-content-center align-items-center h-100'>
          <div className='row justify-content-center align-items-center h-100'>
            <div className='col-lg-8 mx-auto position-realtive pt-5 px-4'>
              <h1 className="fs-56 text-gradient mb-3 fw-semibold text-center">
                <span className='text-white ms-2'>Agentic AI </span>
                  <span className='text-gradient'>Pioneer </span>
                <span className='text-white'>Program</span>
              </h1>
              <h2 className='text-white fs-24 mb-4 mx-auto px-2 text-center'>Master AI Agents, Build the Future!</h2>
              <ul className='text-white mx-auto d-table d-none'>
                <li><i></i>150+ Hours of Comprehensive Learning</li>
                <li><i></i>20+ Hands-on Projects for Skill Building</li>
                <li><i></i>1:1 Mentorship with Agentic AI Experts</li>
              </ul>
              {(country_name === "IN" && agenticaicontent && agenticaicontent.latestOffers.active) && <div className='offer-block position-absolute offer-strip'>
                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                  {agenticaicontent && agenticaicontent.latestOffers.first_fold_text}
                  <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                  </svg>
                </a>
                <div className='text-white ms-auto mt-1 timmer-strip'>
                  <div className="timmer-box d-inline-block me-1">
                    <span className='days'></span>d 
                  </div>: 
                  <div className="timmer-box d-inline-block me-1">
                    <span className='hours'></span>h
                  </div>:
                  <div className="timmer-box d-inline-block me-2">
                    <span className='minutes'></span>m 
                  </div>:
                  <div className="timmer-box d-inline-block">
                    <span className='seconds'></span>s
                  </div>
                </div>
              </div>}
              {((country_name === "CAN" || country_name === "US" || country_name === "CA") && agenticaicontent && agenticaicontent.latestOffersUsAndCanada.active) && <div className='offer-block position-absolute offer-strip'>
                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                  {agenticaicontent && agenticaicontent.latestOffersUsAndCanada.first_fold_text}
                  <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                  </svg>
                </a>
                <div className='text-white ms-auto mt-1 timmer-strip'>
                  <div className="timmer-box d-inline-block me-1">
                    <span className='days'></span>d 
                  </div>: 
                  <div className="timmer-box d-inline-block me-1">
                    <span className='hours'></span>h
                  </div>:
                  <div className="timmer-box d-inline-block me-2">
                    <span className='minutes'></span>m 
                  </div>:
                  <div className="timmer-box d-inline-block">
                    <span className='seconds'></span>s
                  </div>
                </div>
              </div>}
              {((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && agenticaicontent && agenticaicontent.latestOffersRestOfWorld.active) && <div className='offer-block position-absolute offer-strip'>
                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                  {agenticaicontent && agenticaicontent.latestOffersRestOfWorld.first_fold_text}
                  <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                  </svg>
                </a>
                <div className='text-white ms-auto mt-1 timmer-strip'>
                  <div className="timmer-box d-inline-block me-1">
                    <span className='days'></span>d 
                  </div>: 
                  <div className="timmer-box d-inline-block me-1">
                    <span className='hours'></span>h
                  </div>:
                  <div className="timmer-box d-inline-block me-2">
                    <span className='minutes'></span>m 
                  </div>:
                  <div className="timmer-box d-inline-block">
                    <span className='seconds'></span>s
                  </div>
                </div>
              </div>}
              <div className='enroll-number p-4 text-center text-white w-75 mx-auto rounded-3 rainbow'>
                <h4 className='fs-24 fw-semibold mb-4'>Become an Agentic AI Expert</h4>
                <form className="w-100" role="Phone">
                  <div className="row align-items-baseline">
                    <div className="col-12 col-xl mb-3 mb-xl-0">
                      <input className="form-control px-4 py-2 ps-5" type="tel" placeholder="Your Phone Number" aria-label="Phone No." min={0} value={phone} onChange={(e)=>{setPhone(e.target.value)}} id="agenticaiNumber" autocomplete="off" />
                    </div>
                    <div className="col-12 col-xl mb-3 mb-xl-0">
                      <input className="form-control px-4 py-2" type="email" placeholder="Your Email Id" value={email} onChange={(e)=>{setEmail(e.target.value)}} id="agenticaiEmail" autocomplete="off" />
                    </div>
                  </div>
                 <div className='text-start mt-3'>
                  <div className="form-check form-check-inline mb-3">
                      <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox1" value="option1" defaultChecked disabled />
                      <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox1">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox2" value="option2" defaultChecked />
                      <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox2">Send WhatsApp Updates</label>
                    </div>
                 </div>
                  <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={saveLeadForm}>Download Brochure</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='common-style' id='whyChoose'>
        <div className='shpe-left z-0'>
          <img src={"https://imgcdn.analyticsvidhya.com/blackbelt/left-shap.png"} className="img-fluid" />
        </div>
        <div className='container z-2'>
          <div className='text-white mx-auto text-center col-lg-8 heading-with-text'>
            <h2 className='fs-40 fw-normal mb-3'>How does the Agentic AI program help you?</h2>
          </div>
          <div className='card text-bg-dark p-5 border-0 rounded-3 mt-5'>
             <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                  <div className='key-points'>
                      <h3 className='fs-24 fw-semibold mb-4'>150+ Hours of Intelligent Agent Training</h3>
                      <ul className={`mb-4 ps-3`}>
                          <li className='mb-4 fs-16 text-dark-secondary'>Build AI agents that think, learn, and act autonomously</li>
                          <li className='mb-4 fs-16 text-dark-secondary'>Master advanced Agentic AI frameworks and tools</li>
                      </ul>
                  </div>
                  <div className='key-points'>
                      <h3 className='fs-24 fw-semibold mb-4'>20+ Real-World Projects</h3>
                      <ul className={`mb-4 ps-3`}>
                          <li className='mb-4 fs-16 text-dark-secondary'>Gain hands-on experience with practical simulations</li>
                          <li className='mb-4 fs-16 text-dark-secondary'>Tackle diverse projects to enhance your skills</li>
                      </ul>
                  </div>
                  <div className='key-points'>
                      <h3 className='fs-24 fw-semibold mb-4'>1:1 Expert Mentorship</h3>
                      <ul className={`mb-4 ps-3`}>
                          <li className='mb-4 fs-16 text-dark-secondary'>Receive personalized guidance from industry leaders</li>
                          <li className='mb-4 fs-16 text-dark-secondary'>Accelerate learning with a tailored roadmap to success</li>
                      </ul>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 position-relative'>
                <div className='video-thumb position-relative mb-5'>
                  <img src="	https://imgcdn.analyticsvidhya.com/agenticaipioneer/thumanil_agenticai.jpg?format=webp&width=643" className="img-fluid rounded-2" />
                  <button className="btn play-btn position-absolute rounded-circle d-flex align-items-center justify-content-center z-3" data-bs-toggle="modal" data-bs-target="#kunalVideoModal" onClick={playVideo}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                    </svg>
                  </button>
                </div>
                <button className='btn btn-dark btn-dark-primary rounded-3 z-3' data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Enroll Now")}}>Get Started Today</button>
              </div>
             </div>
          </div>
        </div>

        <div className='container common-style z-3 position-relative'>
          <div className='text-white mx-auto text-center col-lg-8 heading-with-text'>
            <h2 className='fs-40 fw-normal mb-3'>Curriculum Statistics</h2>
          </div>
          <div className='row mt-5 px-3 px-md-0'>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
            <div className='icon-item d-flex align-items-center'>
                <div className='icon-box me-3 d-flex justify-content-center align-items-center rounded-circle'>
                    <img src="https://svgcdn.analyticsvidhya.com/blackbelt/icons/projects.svg" alt="1" className='img-fluid' />
                </div>
                <h4 className='text-white fs-24 fw-semibold m-0'>20+ Projects</h4>
                </div>
                <p className='text-dark-secondary mt-3 m-0'>Skill building with industry-relevant projects</p>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
            <div className='icon-item d-flex align-items-center'>
                <div className='icon-box me-3 d-flex justify-content-center align-items-center rounded-circle'>
                    <img src="https://svgcdn.analyticsvidhya.com/blackbelt/icons/clock.svg" alt="1" className='img-fluid' />
                </div>
                <h4 className='text-white fs-24 fw-semibold m-0'>150+ Hours</h4>
                </div>
                <p className='text-dark-secondary mt-3 m-0'>Comprehensive learning to power ahead in your AI journey</p>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
            <div className='icon-item d-flex align-items-center'>
                <div className='icon-box me-3 d-flex justify-content-center align-items-center rounded-circle'>
                    <img src="https://svgcdn.analyticsvidhya.com/blackbelt/icons/code.svg" alt="1" className='img-fluid' />
                </div>
                <h4 className='text-white fs-24 fw-semibold m-0'>15+ Tools</h4>
                </div>
                <p className='text-dark-secondary mt-3 m-0'>Master 15+ cutting-edge tools and frameworks</p>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
            <div className='icon-item d-flex align-items-center'>
                <div className='icon-box me-3 d-flex justify-content-center align-items-center rounded-circle'>
                    <img src="https://svgcdn.analyticsvidhya.com/blackbelt/icons/Icon.svg" alt="1" className='img-fluid' />
                </div>
                <h4 className='text-white fs-24 fw-semibold m-0'>12+ Assignments</h4>
                </div>
                <p className='text-dark-secondary mt-3 m-0'>Work on Agentic AI assignments and test your skills</p>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
            <div className='icon-item d-flex align-items-center'>
                <div className='icon-box me-3 d-flex justify-content-center align-items-center rounded-circle'>
                    <img src="https://svgcdn.analyticsvidhya.com/blackbelt/icons/Mentorship.svg" alt="1" className='img-fluid' />
                </div>
                <h4 className='text-white fs-24 fw-semibold m-0'>75+ Mentorship Sessions</h4>
                </div>
                <p className='text-dark-secondary mt-3 m-0'>1:1 mentorship session with leading AI experts</p>
            </div>
          </div>
        </div>

        {agenticaicontent && <section className='common-style background-dark-primary position-relative' id='roadMap'>
          <div className="container">
            <div className='text-white col-lg-8 mb-5 heading-with-text text-center mx-auto'>
              <h2 className='fs-40 fw-normal mb-3'>{agenticaicontent && agenticaicontent.personalisedRoadmap.heading}</h2>
              <p>{agenticaicontent && agenticaicontent.personalisedRoadmap.subheading}</p>
            </div>
            <ul className='roadmap-mobile'>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>1</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Introduction to Generative AI</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>2</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build Your First Agent</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>3</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Learn Coding for Agentic AI</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>4</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Learn LangChain, Prompt Engineering, RAG</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>5</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build an AI Agent from Scratch</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>6</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build ReAct Agents with LangChain</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>7</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build Your First AI Agent with LangGraph, Autogen, CrewAI</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>8</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Learn Agentic AI Architectures & Design Pattern</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>9</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build Advanced AI Agents with LangGraph, Autogen, CrewAI</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>10</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build Agentic RAG Systems with LangGraph</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>11</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build Multi-agent Systems with LangGraph, Autogen, CrewAI</span>
                </li>
                <li className='text-start d-flex align-items-center'>
                  <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center col-auto'>12</span>
                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                    <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                    <defs>
                      <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="white" stop-opacity="0"/>
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className='roadmap-text fs-14'>Build Reflective & Planning Agents with LangGraph, Autogen, CrewAI</span>
                </li>
            </ul>
          </div>
        <img src={cloudflare_size_url+(agenticaicontent && agenticaicontent.personalisedRoadmap.image.src+'?format=webp')} className="img-fluid mb-5 w-100" alt={agenticaicontent && agenticaicontent.personalisedRoadmap.image.alt} />
        <div className="container">
          <button className='btn btn-dark btn-dark-primary rounded-3 mx-auto d-table' id='roadmapBtn' data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('roadmap','Your GenAI Blueprint',"Create My Roadmap","roadmap")}}>Get Personalized Roadmap</button>
        </div>
        </section>}

        <div className='common-style container position-relative z-3' id='chooseLearn'>
          <div className='text-white col-lg-8 heading-with-text mx-auto text-center'>
            <h2 className='fs-40 fw-normal mb-3'>{agenticaicontent && agenticaicontent.chooseWhatToLearn.heading}</h2>
            <p>{agenticaicontent && agenticaicontent.chooseWhatToLearn.subheading}</p>
          </div>
          <div className='row mt-5'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{agenticaicontent && agenticaicontent.chooseWhatToLearn.projects}</div>
              <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{agenticaicontent && agenticaicontent.chooseWhatToLearn.hours}</div>
              <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{agenticaicontent && agenticaicontent.chooseWhatToLearn.tools}</div>
              <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{agenticaicontent && agenticaicontent.chooseWhatToLearn.assignments}</div>
              <div className="accordion" id="accordionChoose">
                {agenticaicontent && agenticaicontent.chooseWhatToLearn.courseDetails.map((section,index)=>{
                  return(
                    <div className="accordion-item border-0" key={index}>
                      <h2 className="accordion-header mb-3">
                        <button className={`accordion-button border-0 text-white rounded-3 ${index !== 0 && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0 ? "true":"false"} aria-controls={`collapse${index}`}>
                          <div className="content">
                            <p className='fs-20 m-0'>{section.heading}</p>
                            <p className='mb-0 d-flex align-items-center flex-wrap d-none'>
                              {section.projects && 
                              <div>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <g clip-path="url(#clip0_8995_145943)">
                                    <path d="M11.4224 2.48873L8.48968 5.42145C8.17043 5.74071 8.17043 6.25832 8.48968 6.57757L11.4224 9.5103C11.7417 9.82955 12.2593 9.82955 12.5785 9.5103L15.5113 6.57757C15.8305 6.25832 15.8305 5.74071 15.5113 5.42145L12.5785 2.48873C12.2593 2.16947 11.7417 2.16947 11.4224 2.48873Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.4224 8.48873L14.4897 11.4215C14.1704 11.7407 14.1704 12.2583 14.4897 12.5776L17.4224 15.5103C17.7417 15.8295 18.2593 15.8295 18.5785 15.5103L21.5113 12.5776C21.8305 12.2583 21.8305 11.7407 21.5113 11.4215L18.5785 8.48873C18.2593 8.16947 17.7417 8.16947 17.4224 8.48873Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.42241 8.4897L2.48968 11.4224C2.17043 11.7417 2.17043 12.2593 2.48968 12.5785L5.42241 15.5113C5.74166 15.8305 6.25927 15.8305 6.57853 15.5113L9.51125 12.5785C9.8305 12.2593 9.8305 11.7417 9.51125 11.4224L6.57852 8.4897C6.25927 8.17045 5.74166 8.17045 5.42241 8.4897Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.4224 14.4897L8.48968 17.4224C8.17043 17.7417 8.17043 18.2593 8.48968 18.5785L11.4224 21.5113C11.7417 21.8305 12.2593 21.8305 12.5785 21.5113L15.5113 18.5785C15.8305 18.2593 15.8305 17.7417 15.5113 17.4224L12.5785 14.4897C12.2593 14.1705 11.7417 14.1705 11.4224 14.4897Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_8995_145943">
                                      <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span className='me-4'>{section.projects} {section.projects==1?'Project':'Projects'}</span>
                              </div>}
                              {section.assignments && 
                              <div>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M12 5.48999V20.49" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M7.75 8.48999H5.5" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M8.5 11.49H5.5" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>{section.assignments}  {section.assignments==1?'Assignment':'Assignments'}</span>
                              </div>}
                              &nbsp;
                            </p>
                          </div>
                        </button>
                      </h2>
                      <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 && 'show'}`} data-bs-parent="#accordionChoose">
                        <div className="accordion-body border-0">
                          <ol className='px-2 px-md-4'>
                            {section.lectures.map((obj)=>{
                              return(
                                <li>
                                  <h4 className='fs-16 ms-4'>{obj}</h4>
                                </li>
                              )
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 position-relative">
              <div className="video-thumb position-relative mx-auto d-table mt-lg-0 mt-4">
                <img src={agenticaicontent && cloudflare_size_url+agenticaicontent.chooseWhatToLearn.image.src+"?format=webp&width=648"} alt={agenticaicontent && agenticaicontent.chooseWhatToLearn.image.alt} className="img-fluid rounded-2" />
                  <button className="btn play-btn position-absolute rounded-circle d-flex align-items-center justify-content-center z-3 d-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                    </svg>
                  </button>
                </div>
                <div className='row mt-5'>
                  <div className="col-12 col-lg-6">
                    <button className="btn btn-dark btn-dark-primary rounded-3 me-0 me-md-3  mb-3 w-100" data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('curriculum','Access Curriculum for Free',"Unlock Content","curriculum")}}>
                        Download Free Curriculum
                      </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>

      <section className='background-dark-primary common-style common-card-style agentic-card'>
          <div className='container'>
              <div className='row'>
                  <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                      <h2 className="fs-40 fw-normal mb-3">Insights from Industry Leaders on AI Agents</h2>
                      <p className="fs-18 fw-normal">Global Leaders on the Future of Intelligent Autonomous Agents</p>
                  </div>
              </div>
              <div className='row gx-4'>
              <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
                    <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href='#'>
                            <div className="card-img position-relative overflow-hidden rounded-2">
                                <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"agenticaipioneer/sam.jpg"} alt="Card cap" loading="lazy" width="392" height="220" />                         
                            </div>
                        </a>
                        <div className='card-body px-0'>
                            <p className='fs-14 text-dark-secondary'>“We’ve seen that with the great technological revolutions of the past. Each technological revolution has gotten faster, and this will be the fastest by far. Helpful Agents Are Poised To Become AI’s Killer Function.”</p>
                        </div>
                        <div className="card-footer border-0 px-0">
                          <p className='fs-14 mb-3 text-dark-secondary'>– Sam Altman, CEO of OpenAI</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
                    <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href='#'>
                            <div className="card-img position-relative overflow-hidden rounded-2">
                                <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"agenticaipioneer/jeff.jpg"} alt="Card cap" loading="lazy" width="392" height="220" />                           
                            </div>
                        </a>
                        <div className='card-body px-0'>
                            <p className='fs-14 text-dark-secondary'>“AI agents will become our digital assistants, helping us navigate the complexities of the modern world. They will make our lives easier and more efficient.”</p>
                        </div>
                        <div className="card-footer border-0 px-0">
                          <p className='fs-14 mb-3 text-dark-secondary'>– Jeff Bezos, Founder and Executive Chairman of Amazon</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
                    <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href='#'>
                            <div className="card-img position-relative overflow-hidden rounded-2">
                                <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"agenticaipioneer/Satya%20Nadella.jpg"} alt="Card cap" loading="lazy" width="392" height="220" />      
                            </div>
                        </a>
                        <div className='card-body px-0'>
                            <p className='fs-14 text-dark-secondary'>“AI agents will become the primary way we interact with computers in the future. They will be able to understand our needs and preferences, and proactively help us with tasks and decision making.”</p>
                        </div>
                        <div className="card-footer border-0 px-0">
                          <p className='fs-14 mb-3 text-dark-secondary'>– Satya Nadella, CEO of Microsoft</p>
                        </div>
                    </div>
                </div>
              </div>
              <div className='w-100 clearfix mt-5 mx-auto d-table d-none'>
               <button className='btn btn-dark btn-dark-primary rounded-3 d-table mx-auto' aria-label="Enroll now" data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('waitlist','Become an Agentic AI Expert : Coming Soon',"Join the Waitlist","cards")}}>Join the Waitlist</button>
            </div>
          </div>
      </section>

      <Suspense fallback={<div>Loading</div>}>
        <AgenticAiSwiperComponent2 updateLeadForm={updateLeadForm} />
      </Suspense>

      {(country_name === "IN" && agenticaicontent && agenticaicontent.latestOffers.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
              <div className='row align-items-center'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                  <h2 className='fs-40 fw-normal mb-3 text-white'>{agenticaicontent && agenticaicontent.latestOffers.heading}</h2>
                  <h3 className='fs-56 my-3 fw-bold'>{agenticaicontent && agenticaicontent.latestOffers.discountPercentage}</h3>
                  <ul className='text-white'>
                    {agenticaicontent && agenticaicontent.latestOffers.points.map((point,index)=>{
                      return(
                        <li className='fs-20 mb-4' key={index}>{point}</li>
                      )
                    })}
                  </ul>
                  <button class="btn btn-dark btn-dark-primary rounded-3 mt-3 me-3" data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Offer section")}}>Enroll Now</button>
                  <a class="btn btn-dark btn-dark-primary rounded-3 mt-3 d-none" href='#personalised'>Get Details</a>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                  <div className='timmer-card'>
                    <h4 className='text-white'>{agenticaicontent && agenticaicontent.latestOffers.text}</h4>
                    <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                      <li className='text-center'>
                        <h4 className='days'>1</h4>
                        <h5>Day</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='hours'>5</h4>
                        <h5>hr</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='minutes'>35</h4>
                        <h5>min</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='seconds'>20</h4>
                        <h5>Sec</h5>
                      </li>
                    </ul>
                    <a  data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Offer Section")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                      <div className='offer-cta text-light-primary'>
                        {agenticaicontent && agenticaicontent.latestOffers.cta.text}
                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                          <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}

      {((country_name === "CAN" || country_name === "US" || country_name === "CA") &&  agenticaicontent && agenticaicontent.latestOffersUsAndCanada.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
              <div className='row align-items-center'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                  <h2 className='fs-40 fw-normal mb-3 text-white'>{agenticaicontent && agenticaicontent.latestOffersUsAndCanada.heading}</h2>
                  <h3 className='fs-56 my-3 fw-bold'>{agenticaicontent && agenticaicontent.latestOffersUsAndCanada.discountPercentage}</h3>
                  <ul className='text-white'>
                    {agenticaicontent && agenticaicontent.latestOffersUsAndCanada.points.map((point,index)=>{
                      return(
                        <li className='fs-20 mb-4' key={index}>{point}</li>
                      )
                    })}
                  </ul>
                  <button class="btn btn-dark btn-dark-primary rounded-3 mt-3 me-3" data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Offer Section")}}>Enroll Now</button>
                  <a class="btn btn-dark btn-dark-primary rounded-3 mt-3 d-none" href='#personalised'>Get Details</a>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                  <div className='timmer-card'>
                    <h4 className='text-white'>{agenticaicontent && agenticaicontent.latestOffersUsAndCanada.text}</h4>
                    <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                      <li className='text-center'>
                        <h4 className='days'>1</h4>
                        <h5>Day</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='hours'>5</h4>
                        <h5>hr</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='minutes'>35</h4>
                        <h5>min</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='seconds'>20</h4>
                        <h5>Sec</h5>
                      </li>
                    </ul>
                    <a  data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Offer Section")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                      <div className='offer-cta text-light-primary'>
                        {agenticaicontent && agenticaicontent.latestOffersUsAndCanada.cta.text}
                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                          <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}

      {((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && agenticaicontent && agenticaicontent.latestOffersRestOfWorld.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
              <div className='row align-items-center'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                  <h2 className='fs-40 fw-normal mb-3 text-white'>{agenticaicontent && agenticaicontent.latestOffersRestOfWorld.heading}</h2>
                  <h3 className='fs-56 my-3 fw-bold'>{agenticaicontent && agenticaicontent.latestOffersRestOfWorld.discountPercentage}</h3>
                  <ul className='text-white'>
                    {agenticaicontent && agenticaicontent.latestOffersRestOfWorld.points.map((point,index)=>{
                      return(
                        <li className='fs-20 mb-4' key={index}>{point}</li>
                      )
                    })}
                  </ul>
                  <button class="btn btn-dark btn-dark-primary rounded-3 mt-3 me-3" data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Offer Section")}}>Enroll Now</button>
                  <a class="btn btn-dark btn-dark-primary rounded-3 mt-3 d-none" href='#personalised'>Get Details</a>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                  <div className='timmer-card'>
                    <h4 className='text-white'>{agenticaicontent && agenticaicontent.latestOffersRestOfWorld.text}</h4>
                    <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                      <li className='text-center'>
                        <h4 className='days'>1</h4>
                        <h5>Day</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='hours'>5</h4>
                        <h5>hr</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='minutes'>35</h4>
                        <h5>min</h5>
                      </li>
                      <li className='text-center'>
                        <h4 className='seconds'>20</h4>
                        <h5>Sec</h5>
                      </li>
                    </ul>
                    <a  data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Offer Section")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                      <div className='offer-cta text-light-primary'>
                        {agenticaicontent && agenticaicontent.latestOffersRestOfWorld.cta.text}
                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                          <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}

      <Suspense fallback={<div>Loading</div>}>
        <AgenticAiSwiperComponent1 updateLeadForm={updateLeadForm} setVideoUrl={setVideoUrl} />
      </Suspense>

      <section className='common-style background-dark-primary' id='moneyBackGaurantee'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-xl-8 col-lg-8 col-md-8 co-sm-12 order-2 order-md-1'>
              <div className='text-white heading-with-text mb-5'>
                <h2 className='fs-40 fw-normal mb-3 text-dark-secondary'>Money Back Guarantee!</h2>
                <p>Agentic AI Pioneer program comes with 7 days no questions asked Money back Guarantee. If the program is bought in pre-launch offer or on discounted price, then the fee paid is non-refundable. For more T&C,<a className='text-dark-secondary' href='https://www.analyticsvidhya.com/refund-policy' target='_blank'>Click here</a></p>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-4 col-7 order-1 order-md-2 mx-auto'>
              <img src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"blackbelt/money_back1.png?fomat=webp"} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      {agenticaicontent && <section className='py-5 background-dark-secondary clearfix' id='moneyGuarantee'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12'>
              <div className='text-white heading-with-text mb-5'>
                <h2 className='fs-40 fw-normal mb-3 fs-18'>{agenticaicontent && agenticaicontent.programmeFees.heading}</h2>
              </div>
              <div className='d-flex flex-wrap'>
                <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                  {agenticaicontent && agenticaicontent.programmeFees.points1.map((point,index)=>{
                    return(
                    <li className='mb-4'>
                      <svg className='float-start me-2 mt-1' xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                        <path d="M10 0.900391C4.62391 0.900391 0.25 5.2743 0.25 10.6504C0.25 16.0265 4.62391 20.4004 10 20.4004C15.3761 20.4004 19.75 16.0265 19.75 10.6504C19.75 5.2743 15.3761 0.900391 10 0.900391ZM15.0742 7.38273L8.77422 14.8827C8.70511 14.965 8.61912 15.0315 8.52207 15.0777C8.42502 15.1239 8.31918 15.1487 8.21172 15.1504H8.19906C8.09394 15.1504 7.99 15.1282 7.89398 15.0854C7.79797 15.0426 7.71202 14.9801 7.64172 14.902L4.94172 11.902C4.87315 11.8292 4.81981 11.7435 4.78483 11.6499C4.74986 11.5562 4.73395 11.4566 4.73805 11.3567C4.74215 11.2568 4.76617 11.1588 4.8087 11.0683C4.85124 10.9779 4.91142 10.8968 4.98572 10.83C5.06002 10.7631 5.14694 10.7118 5.24136 10.679C5.33579 10.6462 5.43581 10.6326 5.53556 10.639C5.63531 10.6454 5.73277 10.6717 5.82222 10.7163C5.91166 10.7609 5.99128 10.823 6.05641 10.8988L8.17938 13.2576L13.9258 6.41805C14.0547 6.26902 14.237 6.17671 14.4335 6.16105C14.6299 6.1454 14.8246 6.20767 14.9754 6.33441C15.1263 6.46114 15.2212 6.64215 15.2397 6.83832C15.2582 7.03449 15.1988 7.23005 15.0742 7.38273Z" fill="#85D992"/>
                      </svg>
                      <span className='overflow-hidden fs-18 d-block'>{point}</span>
                    </li>
                    )
                  })}
                </ul>
                <ul className='col-12 col-md-6 text-white mb-2 list-style-none d-none'>
                  {agenticaicontent && agenticaicontent.programmeFees.points2.map((point,index)=>{
                      return(
                      <li className='mb-4'>
                        <svg className='float-start me-2 mt-1' xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                          <path d="M10 0.900391C4.62391 0.900391 0.25 5.2743 0.25 10.6504C0.25 16.0265 4.62391 20.4004 10 20.4004C15.3761 20.4004 19.75 16.0265 19.75 10.6504C19.75 5.2743 15.3761 0.900391 10 0.900391ZM15.0742 7.38273L8.77422 14.8827C8.70511 14.965 8.61912 15.0315 8.52207 15.0777C8.42502 15.1239 8.31918 15.1487 8.21172 15.1504H8.19906C8.09394 15.1504 7.99 15.1282 7.89398 15.0854C7.79797 15.0426 7.71202 14.9801 7.64172 14.902L4.94172 11.902C4.87315 11.8292 4.81981 11.7435 4.78483 11.6499C4.74986 11.5562 4.73395 11.4566 4.73805 11.3567C4.74215 11.2568 4.76617 11.1588 4.8087 11.0683C4.85124 10.9779 4.91142 10.8968 4.98572 10.83C5.06002 10.7631 5.14694 10.7118 5.24136 10.679C5.33579 10.6462 5.43581 10.6326 5.53556 10.639C5.63531 10.6454 5.73277 10.6717 5.82222 10.7163C5.91166 10.7609 5.99128 10.823 6.05641 10.8988L8.17938 13.2576L13.9258 6.41805C14.0547 6.26902 14.237 6.17671 14.4335 6.16105C14.6299 6.1454 14.8246 6.20767 14.9754 6.33441C15.1263 6.46114 15.2212 6.64215 15.2397 6.83832C15.2582 7.03449 15.1988 7.23005 15.0742 7.38273Z" fill="#85D992"/>
                        </svg>
                        <span className='overflow-hidden fs-18 d-block'>{point}</span>
                      </li>
                      )
                    })}
                </ul>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
            {country_name && country_name === 'IN' && 
                <div className='d-flex w-100 align-items-center d-none'>
                  <h3 className='text-white text-uppercase fs-20 d-inline-block m-0' id='oneTimeToggleLabel'>{agenticaicontent && agenticaicontent.programmeFees.oneTimePayment.heading}</h3>
                  <div className="form-check form-switch ms-2 me-2">
                    <input className="form-check-input border-0 custom-control-input" type="checkbox" role="switch" id="priceCheck" onChange={toggleEmiTab} />
                    <label className="form-check-label text-dark-tertiary fs-20 ms-2 fw-medium" htmlFor="priceCheck" id='emiToggleLabel'>{agenticaicontent && agenticaicontent.programmeFees.emiPayment.heading}</label>
                  </div>
                </div>
              }
              <div className='card border-0 background-dark-tertiary p-4 rounded-3 text-white toggle-card active' id="toggleCard1">
              <h4 className='text-uppercase fs-18 mb-4'>{agenticaicontent && agenticaicontent.programmeFees.oneTimePayment.heading}</h4>
              <h5 className='fs-40 fw-bold mb-2'>{agenticaicontent &&  (country_name === 'IN'?agenticaicontent.programmeFees.oneTimePayment.indianCurrentPrice:((country_name =="US" ||country_name =="CA" ||country_name =="CAN")?agenticaicontent.programmeFees.oneTimePayment.currentPrice:agenticaicontent.programmeFees.oneTimePayment.restCurrentPrice))}</h5>
              {agenticaicontent.programmeFees.offerAvailable && <h4 className='fs-18 text-dark-secondary text-decoration-line-through'>{agenticaicontent && (country_name === 'IN'?agenticaicontent.programmeFees.oneTimePayment.indianDashedPrice:((country_name =="US" ||country_name =="CA" ||country_name =="CAN") ? agenticaicontent.programmeFees.oneTimePayment.dashedPrice:agenticaicontent.programmeFees.oneTimePayment.restDashedPrice))}</h4>}
              <h6 className='text-dark-secondary mt-3 mb-5'>{agenticaicontent && agenticaicontent.programmeFees.oneTimePayment.priceText}</h6>
              <p className='text-dark-secondary'>{agenticaicontent && agenticaicontent.programmeFees.oneTimePayment.text}</p>
              <button className='btn btn-dark btn-dark-primary rounded-3 w-100 mt-3' data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","Enroll Now")}}>Enroll Now</button>
              </div>
              <div className='card border-0 background-dark-tertiary p-4 rounded-3 text-white toggle-card' id="toggleCard2">
              <h4 className='text-uppercase fs-18 mb-4'>{agenticaicontent && agenticaicontent.programmeFees.emiPayment.heading}</h4>
              <h5 className='fs-40 fw-bold mb-2'>{agenticaicontent && (country_name === 'IN'?agenticaicontent.programmeFees.emiPayment.indianCurrentPrice:agenticaicontent.programmeFees.emiPayment.currentPrice)}</h5>
              {agenticaicontent.programmeFees.offerAvailable && <h4 className='fs-18 text-dark-secondary text-decoration-line-through'>{agenticaicontent && (country_name === 'IN'?agenticaicontent.programmeFees.emiPayment.indianDashedPrice:agenticaicontent.programmeFees.emiPayment.dashedPrice)}</h4>}
              <h6 className='text-dark-secondary mt-3 mb-5'>{agenticaicontent && agenticaicontent.programmeFees.emiPayment.priceText}</h6>
              <p className='text-dark-secondary'>{agenticaicontent && agenticaicontent.programmeFees.emiPayment.text}</p>
              <button className='btn btn-dark btn-dark-primary rounded-3 w-100 mt-3' data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('emi','Enroll Today',"Enroll Now","emi")}}>Enroll Now</button>
              </div>
            </div>
          </div>
        </div>
      </section>}
      <Suspense fallback={<div>Loading</div>}>
        <GenAiOtherProgramSwiperComponent />
      </Suspense>
      {agenticaicontent && <section className='common-style background-dark-primary pb-5' id='contact'>
        <div className='container'>
          <div className='text-white heading-with-text mb-5 text-center'>
            <h2 className='fs-40 fw-normal mb-3 fs-18'> {agenticaicontent && agenticaicontent.contactUsToday.heading}</h2>
            <p>{agenticaicontent && agenticaicontent.contactUsToday.subheading}</p>
          </div>
          <div className='row'>
            <div className='col-lg-7 col-md-12 col-sm-12 mb-3'>
              <div className="card background-dark-secondary shadow-sm rounded-4 py-3 border border-1">
                <div className="card-header border-0">
                  <h2 className='text-center fs-24 text-white fw-medium'>Upskill, Reskill, Thrive</h2>
                </div>
                <div className="card-body border-0">
                  <div className="row">
                      <div className="col-xl col-md-12 col-12 mb-3">
                          <label htmlFor="contactFullName" className="form-label text-white fs-14">Full Name</label>
                          <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactFullName" placeholder="Your Full Name" autoComplete='off' value={userDetails.fullname} onChange={e=>setUserDetails((prev)=>{return{...prev,fullname:e.target.value}})} />
                      </div>
                      <div className="col-xl col-md-12 col-12 mb-3">
                          <label htmlFor="contactPhoneNumber" className="form-label text-white fs-14">Phone Number</label>
                          <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="contactPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={userDetails.phone} onChange={e=>setUserDetails((prev)=>{return{...prev,phone:e.target.value}})} />
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-xl col-md-12 col-12">
                          <label htmlFor="contactEmail" className="form-label text-white fs-14">Email Id</label>
                          <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e=>setUserDetails((prev)=>{return{...prev,email:e.target.value}})}  />
                      </div>
                      <div className="col-xl col-md-12 col-12 my-3">
                          <label htmlFor="leadFullName" className="form-label text-white fs-14">Experience :</label>
                          <div className="experience-tag">
                              <input type='radio' className="form-check-input me-2 d-none" id={"contact1"} name="experience" onChange={()=>{updateExperience("0-3yrs")}} defaultChecked />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact1"}>0-3yrs</label>
                              <input type='radio' className="form-check-input me-2 d-none" id={"contact2"} name="experience" onChange={()=>{updateExperience("3-8yrs")}}  />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact2"}>3-8yrs</label>
                              <input type='radio' className="form-check-input me-2 d-none" id={"contact3"} name="experience" onChange={()=>{updateExperience("8-12yrs")}}  />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact3"}>8-12yrs</label>
                              <input type='radio' className="form-check-input me-2 d-none" id={"contact4"} name="experience" onChange={()=>{updateExperience("12yrs+")}}  />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact4"}>12yrs+</label>
                          </div>
                      </div>
                  </div>
                  <div className="mt-3 mb-2 d-flex flex-wrap">
                      <div className="form-check d-flex align-items-center mb-2 me-4">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                      </div>
                      <div className="form-check d-flex align-items-center">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappContactModal" defaultChecked />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappContactModal">Send WhatsApp Updates</label>
                      </div>
                  </div>
                </div>
                <div className="card-footer border-0">
                  <ReCAPTCHA
                      className='mb-3'
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={onChange}
                  />
                  <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center" onClick={saveContactForm} disabled={!captcha}>Join the program now!!</button>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-12 col-sm-12 mb-3 ps-lg-5'>
              <h3 className='text-white'>{agenticaicontent && agenticaicontent.contactUsToday.support.heading}</h3>
              <p className='text-dark-tertiary mt-4 fs-20 fw-light mb-5'>{agenticaicontent && agenticaicontent.contactUsToday.support.subheading}</p>
              <ul>
                {agenticaicontent && (country_name == "US" || country_name == "CA" || country_name == "CAN") && 
                  <li className='row mb-3'>
                    <div className='col-auto mb-3'>
                      <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                        <img src={cloudflare_url+agenticaicontent.contactUsToday.support.items[3].icon} alt={agenticaicontent.contactUsToday.support.items[3].icon} width={24} className='img-fluid' />
                      </div>
                    </div>
                    <div className='col-12 col-md'>
                    <p className='fw-bold fs-16 text-white mb-0 text-break'><a className='text-white' href='tel:+1-6147218481'>{agenticaicontent.contactUsToday.support.items[3].heading}</a></p>
                      <span className='d-block text-dark-tertiary'>{agenticaicontent.contactUsToday.support.items[3].text}</span>
                    </div>
                  </li>
                }
                {agenticaicontent && (country_name === "IN" || (country_name != "US" && country_name != "CA" && country_name != "CAN")) &&
                  <li className='row mb-3'>
                    <div className='col-auto mb-3'>
                      <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                        <img src={cloudflare_url+agenticaicontent.contactUsToday.support.items[0].icon} alt={agenticaicontent.contactUsToday.support.items[0].icon} width={24} className='img-fluid' />
                      </div>
                    </div>
                    <div className='col-12 col-md'>
                    <p className='fw-bold fs-16 text-white mb-0 text-break'><a className='text-white' href='tel:+91-9354711240'>{agenticaicontent.contactUsToday.support.items[0].heading}</a></p>
                      <span className='d-block text-dark-tertiary'>{agenticaicontent.contactUsToday.support.items[0].text}</span>
                    </div>
                  </li>
                }
                <li className='row mb-3'>
                  <div className='col-auto mb-3'>
                    <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                      <img src={cloudflare_url+agenticaicontent.contactUsToday.support.items[1].icon} alt={agenticaicontent.contactUsToday.support.items[1].icon} width={24} className='img-fluid' />
                    </div>
                  </div>
                  <div className='col-12 col-md'>
                    <a className='text-white' href='mailto:customersupport@analyticsvidhya.com'><p className='fw-bold fs-16 text-white mb-0 text-break'>{agenticaicontent.contactUsToday.support.items[1].heading}</p></a>
                    <span className='d-block text-dark-tertiary'>{agenticaicontent.contactUsToday.support.items[1].text}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>}
      {agenticaicontent && <section className='common-style background-dark-secondary pb-5' id='faq'>
        <div className='container'>
          <div className='row'>
            <div className='text-white heading-with-text mb-5 text-center'>
              <h2 className='fs-40 fw-normal mb-3 fs-18'>{agenticaicontent && agenticaicontent.frequentlyAskedQuestions.heading}</h2>
              <p>{agenticaicontent && agenticaicontent.frequentlyAskedQuestions.subheading}</p>
            </div>
            <div className='col-lg-8 mx-auto mt-2'>
                <div class="accordion" id="accordionFaq">
                  {(showAllFAQs ? agenticaicontent.frequentlyAskedQuestions.faqs : agenticaicontent.frequentlyAskedQuestions.faqs.slice(0, 3)).map((faq, index) => (
                    <div class="accordion-item border-0 mb-3" key={index}>
                      <h2 class="accordion-header border-0">
                        <button class={`accordion-button ${index !== 0 && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0 ? "true":"false"} aria-controls={`collapse${index}`}>
                          {faq.question}
                        </button>
                      </h2>
                      <div id={`collapse${index}`} class={`accordion-collapse collapse ${index === 0 && "show"}`} data-bs-parent="#accordionFaq">
                        <div class="accordion-body  border-0">
                          <p>{faq.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {agenticaicontent.frequentlyAskedQuestions.faqs.length > 3 && (
                <div className="w-100 d-table mx-auto text-center">
                  <button className="btn btn-dark btn-dark-primary rounded-3 d-table mx-auto" onClick={() => setShowAllFAQs(!showAllFAQs)}>{showAllFAQs ? "View Less" : "View More"}</button>
                </div>
                )}
              </div>
          </div>
        </div>
      </section>}
      <Cookies platform={'newagenticai'}  />
      <Footer platform={'newagenticai'} isWhatsApp={true}/>
      <Flashstrip />
      </main>
  )
}


const mapStateToProps = (state) => ({
  agenticaicontent:state.agenticAIContentReducer.agenticaicontent,
  loading:state.agenticAIContentReducer.loading,
  error:state.agenticAIContentReducer.error,
  country_name:state.agenticAIContentReducer.country_code
});

const mapDispatchToProps = {
  fetchAgenticAiContent,

};

export default connect(mapStateToProps, mapDispatchToProps)(AgenticAiHome);