import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getQueryDataElevateUser } from '../../../utils';
import { getCommentData, updateWelcomeLoginModal } from '../../../store/Actions/Action';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';

function Reply({comment,islogin,csrf,uniqueId,resourceName,getCommentDataHandler,updateWelcomeLoginModalHandler,pageNumber,reply_id,filter1,openFilePicker,openImagePicker}) {
    const [newReply, setNewReply] = useState("");
    const [wordCount,setWordCount] = useState(0);
    const [reframeComponent,setReframeComponent] = useState(false);

    useEffect(()=>{
        setReframeComponent(false);
        setTimeout(() => {
            setReframeComponent(true);
        }, 50);
    },[comment,reply_id])

    const sendReplyHandler = (comment_id,e) => {
        e.preventDefault();

        if(!islogin) {
            updateWelcomeLoginModalHandler({welcomeloginmodal:true})
            return;
        }

        if(newReply === ""){
        $('.reply-warning').remove();
        console.log(`replyEditor${comment_id}${reply_id?reply_id:""}`)
        $(`#replyEditor${comment_id}${reply_id?reply_id:""}`).css("border-color","red");
        $(`#replyEditor${comment_id}${reply_id?reply_id:""}`).parent().append(`<small class="reply-warning col-12 text-danger">Reply shouldn't be empty</small>`)
        setTimeout(() => {
            $(`#replyEditor${comment_id}${reply_id?reply_id:""}`).css("border-color","gray");
            $('.reply-warning').slideUp();
            $('.reply-warning').remove();
        }, 2000);
        return false;
        }

        let mutation = `
        mutation {
            createReply(commentId: ${comment_id}, repliedText: """${newReply}""") {
            reply {
                id
                isDeleted
                isEdited
                userId
                repliedText
                replyMentions
                updatedAt
                createdAt
            }
            }
        }
        `;
        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
            setNewReply("");
            let tempdata = {
            uniqueId:uniqueId,
            page:pageNumber,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName,
            filter:filter1
            }
            getCommentDataHandler(tempdata);
            window.$('.replyInput').collapse('hide');
            window.tinyMCE.get(`replyEditor${comment.id}${reply_id?reply_id:""}`).setContent("")
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    return (
        <>
            <div className="comment-box background-dark-tertiary mb-3 p-3 rounded-3 border border-secondary">
                {/* <textarea class="form-control background-transparent mb-3" id="replyTextarea" placeholder='What are your thoughts?...' value={newReply} onChange={(e)=>{setNewReply(e.target.value)}}></textarea> */}
                {reframeComponent && <Editor
                    id={`replyEditor${comment.id}${reply_id?reply_id:""}`}
                    tinymceScriptSrc={'https://pkg.analyticsvidhya.com/tinymce/tinymce.min.js'}
                    initialValue=""
                    init={{
                        height: 200,
                        content_css:'dark',
                        content_style: '* {background-color:transparent !important;}.mce-content-body::before{color: #8E8E8E !important;}',
                        menubar: false,
                        statusbar: false,
                        elementpath: false,
                        branding: false,
                        paste_data_images: false,
                        resize: false,
                        placeholder: "What are your thoughts?...",
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code',
                            'insertdatetime', 'media', 'table', 'code', 'wordcount','codesample','email','emoticons'
                        ],
                        toolbar: 'bold italic underline bullist numlist codesample alignleft aligncenter alignright alignjustify  outdent indent  email link emoticons customimageupload customfileupload',
                        setup: (editor) => {
                            editor.ui.registry.addButton('customfileupload', {
                                icon: 'browse', 
                                tooltip: 'File Upload',
                                onAction: () => {
                                    openFilePicker(editor,`replyEditor${comment.id}${reply_id?reply_id:""}`,csrf);
                                }
                            });
                            editor.ui.registry.addButton('customimageupload', {
                                icon: 'image', 
                                tooltip: 'Image Upload',
                                onAction: () => {
                                    openImagePicker(editor,`replyEditor${comment.id}${reply_id?reply_id:""}`,csrf);
                                }
                            });
                            editor.on('Paste', (e) => {
                              const pastedContent = e.clipboardData.getData('text/plain');
                              const pastedWordCount = pastedContent.split('').length;
                              if (pastedWordCount + editor.plugins.wordcount.body.getCharacterCount() > 1000) {
                                e.preventDefault();
                                $(".warning").remove();
                                $(`replyEditor${comment.id}${reply_id?reply_id:""}`).next().after(`<small class="text-danger warning">You have reached the comment limit.</small>`);
                                setTimeout(() => {
                                    $(".warning").slideUp(1000,()=>{
                                        $(".warning").remove();
                                    });
                                }, 3000);
                              }
                            });
                        },
                        paste_preprocess: function (plugin, args) {
                            const content = args.content;
                        
                            // Remove any <img> tags
                            const filteredContent = content.replace(/<img[^>]*>/g, '');
                        
                            // Replace the pasted content with the filtered content
                            if (content !== filteredContent) {
                              args.content = filteredContent;
                    
                              $(".warning").remove();
                              $(`replyEditor${comment.id}${reply_id?reply_id:""}`).next().after(`<small class="text-danger warning">Pasting image is not allowed.</small>`);
                                setTimeout(() => {
                                    $(".warning").slideUp(1000,()=>{
                                        $(".warning").remove();
                                    });
                                }, 3000);
                            }
                        }
                    }}
                    
                    onEditorChange={(content,editor)=>{
                        const wordCount = editor.plugins.wordcount.body.getCharacterCount();
                        if (wordCount <= 1000) {
                            setNewReply(content);
                            setWordCount(wordCount);
                        } else {
                            $(".warning").remove();
                                $(`replyEditor${comment.id}${reply_id?reply_id:""}`).next().after(`<small class="text-danger warning">You have reached the comment limit.</small>`);
                                setTimeout(() => {
                                    $(".warning").slideUp(1000,()=>{
                                        $(".warning").remove();
                                    });
                            }, 3000);
                            editor.setContent(newReply);
                        }
                    }}
                />}
                <div id={`replyEditor${comment.id}${reply_id?reply_id:""}Loader`} class="spinner-border text-light position-absolute top-50 start-50 d-none" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div className="btn-group-comment d-flex flex-wrap justify-content-end align-items-center">
                    <p className='text-white me-auto d-inline mb-0 fs-14'>{wordCount}/1000</p>
                    <button className="btn text-white border-0 text-decoration-underline" onClick={()=>{window.tinyMCE.get(`replyEditor${comment.id}${reply_id?reply_id:""}`).setContent("");setNewReply("")}} disabled={wordCount<6}>Clear</button>
                    <button className="btn btn-dark btn-dark-primary ms-3" onClick={(e)=>{sendReplyHandler(comment.id,e)}} disabled={wordCount==0}>Submit Reply</button>    
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = dispatch=>({
    updateWelcomeLoginModalHandler:data=>dispatch(updateWelcomeLoginModal(data)),
    getCommentDataHandler:data=>dispatch(getCommentData(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Reply);