import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from '../B2B/pages/LandingPage';
import Training from '../B2B/pages/Training';
import DataCulture from '../B2B/pages/DataCulture';
import AnalyticsMaturity from '../B2B/pages/AnalyticsMaturity';
import Error404 from '../../../common/Alert/Error404';

export default function EnterpriseRouters() {
  return (
    <Routes>
        <Route path='/' exact element={<LandingPage />} />
        <Route path='/training' exact element={<Training />} />
        <Route path='/data-culture' exact element={<DataCulture />} />
        <Route path='/analytics-maturity' exact element={<AnalyticsMaturity />} />
        <Route path='*' element={<Error404 />} />
    </Routes>
  )
}