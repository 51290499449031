import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import $ from 'jquery';
import moment from 'moment';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import Avhero from '../../../../../assets/images/hero-image-bb.png';
import { fetchBlackbeltContent, fetchBlackbeltLeadPagesContent } from '../../../../../store/Actions/Action';
import { scrollTop, submitRegisterationForm } from '../../../../../utils';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { getCSRF } from '../../../../../utils/Login';
import ErrorAlert from '../../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import Cookies from '../../../../common/Footer/Cookies';
import Footer from '../../../../common/Footer/Footer';
import Title from '../../../../common/Header/Title';
import BlackbeltLeadHeader from '../components/BlackbeltLeadHeader';
import BlackbeltTwoFieldLeadModal from '../components/BlackbeltTwoFieldLeadModal';
const BlackbeltLeadPagesTwoFieldSwiperComponent = React.lazy(() => import("../components/BlackbeltLeadPagesTwoFieldSwiperComponent"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
var iti1 = null;
var iti2 = null;

const blackbelt_lead_pages_list = eval(process.env.REACT_APP_BLACKBELT_LEAD_PAGES_LIST)

function BlackbeltLeadPagesTwoField({ blackbeltcontent, fetchBlackbeltContent, fetchBlackbeltContentHandler, blackbeltleadpagescontent, fetchBlackbeltLeadPagesContent, fetchBlackbeltLeadPagesContentHandler, country_name }) {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [iserror, setIsError] = useState(false);
    const [userDetails, setUserDetails] = useState({
        fullname: '',
        email: '',
        phone: '',
    })
    const [additionalDetail, setAdditionalDetail] = useState({
        experience: "0-3yrs"
    })
    const [formname, setFormname] = useState(null);
    const [section, setSection] = useState(null);
    const [successMessage, setSuccessMessage] = useState('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
    const [leadFormHeading, setLeadFormHeading] = useState(null);
    const [leadFormCta, setLeadFormCta] = useState(null);
    const [csrf, setCsrf] = useState('');
    const [popup, setPopup] = useState(false);
    const [platformType, setPlatformType] = useState('');
    const recaptchaRef = useRef(null); 
    const [captcha, setCaptcha] = useState(false);

    const { platformName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!blackbelt_lead_pages_list.includes(platformName)) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (!blackbeltleadpagescontent) {
            fetchBlackbeltLeadPagesContentHandler(platformName);
        }
        scrollTop();
    }, [fetchBlackbeltLeadPagesContentHandler]);

    useEffect(() => {
        if (!blackbeltcontent) {
            fetchBlackbeltContentHandler();
        }
        scrollTop();
    }, [fetchBlackbeltContentHandler]);

    useEffect(() => {
        const input = document.getElementById("bbNumber");
        const input1 = document.getElementById('contactPhoneNumber');
        if (input) {
            iti1 = intlTelInput(input, {
                allowDropdown: true,
                autoHideDialCode: true,
                initialCountry: country_name ? country_name : "IN",
                preferredCountries: ["in", "us"],
            });
            if (country_name) {
                iti1.setCountry(country_name);
            }
        }
        if (input1) {
            iti2 = intlTelInput(input1, {
                allowDropdown: true,
                autoHideDialCode: true,
                initialCountry: country_name ? country_name : "IN",
                preferredCountries: ["in", "us"],
            });
            if (country_name) {
                iti2.setCountry(country_name);
            }
        }
    }, [blackbeltcontent, blackbeltleadpagescontent])

    useEffect(() => {
        // Timer Script
        if(country_name === "IN" && blackbeltcontent && blackbeltleadpagescontent){
            var date = new Date();
            var now = new Date();
            var diffInMilliSeconds = (moment((blackbeltcontent && blackbeltcontent.latestOffers.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
            if (diffInMilliSeconds >0){
    
            
            var days = Math.floor( diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;
            
            const hours = Math.floor(diffInMilliSeconds / 3600);
            diffInMilliSeconds -= hours * 3600;
            
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;
            diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
            
            var countdownNumberEl = $('.seconds');
            var countdown = diffInMilliSeconds;
            countdownNumberEl.text(countdown);
            
            var countMin = $('.minutes')
            var countmin=minutes;
            countMin.text(countmin);
            
            var countHour = $('.hours')
            var counthour=hours;
            countHour.text(counthour);
            
            var countDay = $('.days')
            var countday=days;
            countDay.text(countday);
            
            var timer=setInterval(timer_function,1000);
            function timer_function() {
            if(--countdown<= -1)
            {
            if(countmin!=0)
            {
            countdown=59;
            --countmin;
            }
            else
            {
            if(counthour!=0)
            {
                countdown=59
                countmin=59;
                --counthour;
            }
            else
            {
                if(countday!=0)
                { 
                countdown=59
                countmin=59;
                counthour=23;
                --countday;
                }
                else
                {
                countdown=0;
                if (countdown==0&&countmin==0&&counthour==0)
                {
                var myobj=document.querySelectorAll('.offer-strip');
                myobj.remove();
                clearInterval(timer);
                }
                }
                }
            }
            }
            else
            {
            countdown=countdown
            }
            
            
            countdownNumberEl.text(countdown);
            countMin.text(countmin);
            countHour.text(counthour);
            countDay.text(countday);
            } 
            }
            else{
            var myobj=$('.offer-strip');
            myobj.remove();
            }
        }
        else if((country_name === "CAN" || country_name === "US" || country_name === "CA") && blackbeltcontent && blackbeltleadpagescontent){
            var date = new Date();
            var now = new Date();
            var diffInMilliSeconds = (moment((blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
            if (diffInMilliSeconds >0){
    
            
            var days = Math.floor( diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;
            
            const hours = Math.floor(diffInMilliSeconds / 3600);
            diffInMilliSeconds -= hours * 3600;
            
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;
            diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
            
            var countdownNumberEl = $('.seconds');
            var countdown = diffInMilliSeconds;
            countdownNumberEl.text(countdown);
            
            var countMin = $('.minutes')
            var countmin=minutes;
            countMin.text(countmin);
            
            var countHour = $('.hours')
            var counthour=hours;
            countHour.text(counthour);
            
            var countDay = $('.days')
            var countday=days;
            countDay.text(countday);
            
            var timer=setInterval(timer_function,1000);
            function timer_function() {
            if(--countdown<= -1)
            {
            if(countmin!=0)
            {
            countdown=59;
            --countmin;
            }
            else
            {
            if(counthour!=0)
            {
                countdown=59
                countmin=59;
                --counthour;
            }
            else
            {
                if(countday!=0)
                { 
                countdown=59
                countmin=59;
                counthour=23;
                --countday;
                }
                else
                {
                countdown=0;
                if (countdown==0&&countmin==0&&counthour==0)
                {
                var myobj=document.querySelectorAll('.offer-strip');
                myobj.remove();
                clearInterval(timer);
                }
                }
                }
            }
            }
            else
            {
            countdown=countdown
            }
            
            
            countdownNumberEl.text(countdown);
            countMin.text(countmin);
            countHour.text(counthour);
            countDay.text(countday);
            } 
            }
            else{
            var myobj=$('.offer-strip');
            myobj.remove();
            }
        }
        else if((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && blackbeltcontent && blackbeltleadpagescontent){
            var date = new Date();
            var now = new Date();
            var diffInMilliSeconds = (moment((blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
            if (diffInMilliSeconds >0){
    
            
            var days = Math.floor( diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;
            
            const hours = Math.floor(diffInMilliSeconds / 3600);
            diffInMilliSeconds -= hours * 3600;
            
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;
            diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
            
            var countdownNumberEl = $('.seconds');
            var countdown = diffInMilliSeconds;
            countdownNumberEl.text(countdown);
            
            var countMin = $('.minutes')
            var countmin=minutes;
            countMin.text(countmin);
            
            var countHour = $('.hours')
            var counthour=hours;
            countHour.text(counthour);
            
            var countDay = $('.days')
            var countday=days;
            countDay.text(countday);
            
            var timer=setInterval(timer_function,1000);
            function timer_function() {
            if(--countdown<= -1)
            {
            if(countmin!=0)
            {
            countdown=59;
            --countmin;
            }
            else
            {
            if(counthour!=0)
            {
                countdown=59
                countmin=59;
                --counthour;
            }
            else
            {
                if(countday!=0)
                { 
                countdown=59
                countmin=59;
                counthour=23;
                --countday;
                }
                else
                {
                countdown=0;
                if (countdown==0&&countmin==0&&counthour==0)
                {
                var myobj=document.querySelectorAll('.offer-strip');
                myobj.remove();
                clearInterval(timer);
                }
                }
                }
            }
            }
            else
            {
            countdown=countdown
            }
            
            
            countdownNumberEl.text(countdown);
            countMin.text(countmin);
            countHour.text(counthour);
            countDay.text(countday);
            } 
            }
            else{
            var myobj=$('.offer-strip');
            myobj.remove();
            }
        }
    }, [blackbeltcontent, blackbeltleadpagescontent])

    useEffect(() => {
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && window.location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 100);
                    return false;
                }
            }
        });

        $('.scrolling-method[href*="#"]:not([href="#"])').click(function () {
            if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && window.location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 100
                    }, 100);
                    return false;
                }
            }
        });
    }, []);

    useEffect(() => {
        getCSRF()
            .then((data) => {
                setCsrf(data);
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    const saveLeadForm = (e, formname) => {
        e.preventDefault();
        let country_code = '+' + iti1.getSelectedCountryData().dialCode;
        if (!validatedMobile(phone)) {
            $('.error').remove();
            console.log($('#bbNumber').closest('.col')[0])
            $('#bbNumber').closest('.col-12').append(`<small class="text-danger error text-start d-block">Enter a Valid Phone Number</small>`);
            $('#bbNumber').css('border-color', 'red');
            $('#bbNumber').css('color', 'red');
            setTimeout(() => {
                $('#bbNumber').css('border-color', '#383838');
                $('#bbNumber').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 3000)
            return false;
        }
        if (!validatedEmail(email)) {
            $('.error').remove();
            console.log($('#bbEmail').closest('.col')[0])
            $('#bbEmail').closest('.col-12').append(`<small class="text-danger error text-start d-block">Enter a Valid Phone Number</small>`);
            $('#bbEmail').css('border-color', 'red');
            $('#bbEmail').css('color', 'red');
            setTimeout(() => {
                $('#bbEmail').css('border-color', '#383838');
                $('#bbEmail').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 3000)
            return false;
        }
        let user_data = {
            "fullname": null,
            "email": email,
            "phone": phone,
            "country_code": country_code,
        }
        let additional_detail = {
            "tnc": true,
            "is_whatsapp": true,
            "section": "hero_fold",
            "page_url": window.location.href
        }
        setPhone('');
        setEmail('');
        if (formname === 'projects') {
            setSuccessMessage('Projects Are on Their Way to Your Inbox')
        }
        else if (formname === 'roadmap') {
            setSuccessMessage('Roadmap on Its Way! Check Your Inbox')
        }
        else if (formname === 'instructor_profile') {
            setSuccessMessage('Profiles on the way! Check Your Inbox')
        }
        else if (formname === 'tools') {
            setSuccessMessage('Tools on the way. Check Your Inbox')
        }
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
        }, 4000)
        submitRegisterationForm(user_data, additional_detail, 'blackbelt', formname, csrf)
            .then((data) => {

            })
            .catch((error) => {
                console.log(error)
                // setIsError(true);
                // setTimeout(()=>{
                //   setIsError(false);
                // },4000)
            })
    }

    const toggleEmiTab = (e) => {
        const tab = $(e.target).prop('checked');
        if (tab) {
            $('#toggleCard1').removeClass('active');
            $('#toggleCard2').addClass('active');
        }
        else {
            $('#toggleCard2').removeClass('active');
            $('#toggleCard1').addClass('active');
        }
    }

    const updateExperience = (value) => {
        setAdditionalDetail((prev) => { return ({ ...prev, experience: value }) })
    }

    const saveContactForm = (e) => {
        e.preventDefault();
        let country_code = '+' + iti2.getSelectedCountryData().dialCode;
        if (userDetails.fullname === '') {
            $('.error').remove();
            $('#contactFullName').parent().append(`<small class="text-danger error d-block">Please Enter Your Full Name</small>`);
            $('#contactFullName').css('border-color', 'red');
            $('#contactFullName').css('color', 'red');
            setTimeout(() => {
                $('#contactFullName').css('border-color', '#383838');
                $('#contactFullName').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 3000)
            return false;
        }
        if (!validatedMobile(userDetails.phone)) {
            $('.error').remove();
            $('#contactPhoneNumber').closest('.col-md-12').append(`<small class="text-danger error d-block">Enter a Valid Phone Number</small>`);
            $('#contactPhoneNumber').css('border-color', 'red');
            $('#contactPhoneNumber').css('color', 'red');
            setTimeout(() => {
                $('#contactPhoneNumber').css('border-color', '#383838');
                $('#contactPhoneNumber').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 2000)
            return false;
        }
        if (!validatedEmail(userDetails.email)) {
            $('.error').remove();
            $('#contactEmail').parent().append(`<small class="text-danger error d-block">Enter a valid email id</small>`);
            $('#contactEmail').css('border-color', 'red');
            $('#contactEmail').css('color', 'red');
            setTimeout(() => {
                $('#contactEmail').css('border-color', '#383838');
                $('#contactEmail').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 2000)
            return false;
        }
           

        if(!captcha){
            setIsError(true);
                setTimeout(() => {
                setIsError(false);
            }, 2000);
            return false;
        }

        let user_data = {
            "fullname": userDetails.fullname,
            "email": userDetails.email,
            "phone": userDetails.phone,
            "country_code": country_code,
        }
        let additional_detail = {
            "experience": additionalDetail.experience,
            "tnc": true,
            "is_whatsapp": true,
            "page_url": window.location.href
        }
        setUserDetails({
            fullname: '',
            email: '',
            phone: '',
        });
        setAdditionalDetail({
            experience: "0-3yrs"
        })
        recaptchaRef.current?.reset();
        setCaptcha(false);
        $('#contact1').prop('checked', true);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
        }, 4000)
        submitRegisterationForm(user_data, additional_detail, 'blackbelt', "contact", csrf)
            .then((data) => {
            })
            .catch((error) => {
                console.log(error)
                // setIsError(true);
                // setTimeout(()=>{
                //   setIsError(false);
                // },4000)
            })
    }

    const updateLeadForm = (formname, heading, cta, section, platform) => {
        setFormname(formname);
        setLeadFormHeading(heading);
        setLeadFormCta(cta);
        setSection(section)
        setPlatformType(platform);
    }


    function onChange(value) {
        setCaptcha(true);
    }



    return (
        <>
            <BlackbeltLeadHeader updateLeadForm={updateLeadForm} platformName={platformName} />

            <Title title={'Best Data Science Course Online: Comprehensive AI & ML Certifications program'} description={'Embark on a certified Data Science journey with our online training program. Acquire sought-after skills in Python, AI, and ML. Enroll today and chart your path to becoming a certified Data Scientist.'} image_og={cloudflare_size_url + 'blackbelt/bb_og_image.jpg?format=avif'} title_og={'Analytics Vidhya | The ultimate place for Generative AI, Data Science and Data Engineering'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
            
            <BlackbeltTwoFieldLeadModal formname={formname} setSuccess={setSuccess} setIsError={setIsError} country_name={country_name} setSuccessMessage={setSuccessMessage} leadFormCta={leadFormCta} leadFormHeading={leadFormHeading} section={section} csrf={csrf} platformName={platformType}/>
            
            {iserror && <ErrorAlert message={"Your details Could not be submitted. please try again later sometimes."} setIsError={setIsError} />}
            
            {success && <SuccessAlert message={successMessage} />}
            
            {blackbeltcontent && blackbeltleadpagescontent && <section id='firstFold' className='pb-5 bg-size-cover bg-repeat-0 bg-position-center bg-dark position-relative' style={{ backgroundImage: `url(${Avhero})` }}>
                <div className='container justify-content-center align-items-center h-100'>
                    <div className='row justify-content-center align-items-center h-100'>
                        <div className='col-lg-8 mx-auto text-center position-realtive pt-5'>
                            <h1 className="fs-56 text-gradient mb-3 fw-semibold">
                                <span className="text-white">Certified AI & ML</span> BlackBelt Plus
                                <span className='text-white ms-2'>Program</span>
                            </h1>

                            <h2 className='text-white fs-24 mb-4 mx-auto px-2 text-center'>{blackbeltleadpagescontent.firstFold.subtitle}</h2>
                            <ul className='text-white text-center'>
                                {blackbeltleadpagescontent.firstFold.list.map((item, index) => (
                                    <li key={index}><i></i>{item}</li>
                                ))}
                            </ul>

                            <div className='row gx-4 text-ceter text-white mt-5 pb-5'>
                                <div className='col-6 col-lg-3 col-xl-3'>
                                    <h3 className='fs-20 fw-light fw-semibold'>4.6</h3>
                                    <p>Top-Rated Mentorship</p>
                                </div>
                                <div className='col-6 col-lg-3 col-md-6 col-xl-3'>
                                    <h3 className='fs-20 fw-light fw-semibold'>500+ Hrs</h3>
                                    <p>Comprehensive Learning</p>
                                </div>
                                <div className='col-6 col-lg-3 col-md-6 col-xl-3'>
                                    <h3 className='fs-20 fw-light fw-semibold'>50+</h3>
                                    <p>Real-World Projects</p>
                                </div>
                                <div className='col-6 col-lg-3 col-md-6 col-xl-3'>
                                    <h3 className='fs-20 fw-light fw-semibold'>100%</h3>
                                    <p>Placement Assistance</p>
                                </div>
                            </div>
                            {(country_name === "IN" && blackbeltcontent && blackbeltcontent.latestOffers.active) && <div className='offer-block position-absolute offer-strip'>
                                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                                {blackbeltcontent && blackbeltcontent.latestOffers.first_fold_text}
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                                </svg>
                                </a>
                                <div className='text-white ms-auto mt-1 timmer-strip'>
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='days'></span>d 
                                </div>: 
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='hours'></span>h
                                </div>:
                                <div className="timmer-box d-inline-block me-2">
                                    <span className='minutes'></span>m 
                                </div>:
                                <div className="timmer-box d-inline-block">
                                    <span className='seconds'></span>s
                                </div>
                                </div>
                            </div>}
                            {((country_name === "CAN" || country_name === "US" || country_name === "CA") && blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.active) && <div className='offer-block position-absolute offer-strip'>
                                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                                {blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.first_fold_text}
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                                </svg>
                                </a>
                                <div className='text-white ms-auto mt-1 timmer-strip'>
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='days'></span>d 
                                </div>: 
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='hours'></span>h
                                </div>:
                                <div className="timmer-box d-inline-block me-2">
                                    <span className='minutes'></span>m 
                                </div>:
                                <div className="timmer-box d-inline-block">
                                    <span className='seconds'></span>s
                                </div>
                                </div>
                            </div>}
                            {((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.active) && <div className='offer-block position-absolute offer-strip'>
                                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                                {blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.first_fold_text}
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                                </svg>
                                </a>
                                <div className='text-white ms-auto mt-1 timmer-strip'>
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='days'></span>d 
                                </div>: 
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='hours'></span>h
                                </div>:
                                <div className="timmer-box d-inline-block me-2">
                                    <span className='minutes'></span>m 
                                </div>:
                                <div className="timmer-box d-inline-block">
                                    <span className='seconds'></span>s
                                </div>
                                </div>
                            </div>}
                            <div className='enroll-number p-4 text-center text-white w-75 mx-auto rounded-3'>
                                <h4 className='fs-24 fw-semibold mb-4'>Start Your AI & ML Journey</h4>
                                <form className="w-100" role="Phone">
                                    <div className="row align-items-baseline">
                                        <div className="col-12 col-md mb-3 mb-md-0">
                                            <input className="form-control px-4 py-2 ps-5" type="tel" placeholder="Your Phone Number" aria-label="Phone No." min={0} value={phone} onChange={(e) => { setPhone(e.target.value) }} id="bbNumber" autocomplete="off" />
                                        </div>
                                        <div className="col-12 col-md">
                                            <input className="form-control px-4 py-2" type="email" placeholder="Your Email Id" value={email} onChange={(e) => { setEmail(e.target.value) }} id="bbEmail" autocomplete="off" />
                                        </div>
                                    </div>
                                    <div className='text-start mt-3'>
                                        <div className="form-check form-check-inline mb-3">
                                            <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox1" value="option1" defaultChecked disabled />
                                            <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox1">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox2" value="option2" defaultChecked />
                                            <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox2">Send WhatsApp Updates</label>
                                        </div>
                                    </div>
                                    {
                                        platformName === 'blackbelt-mentors' && <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'instructor_profile')}>Download Instructors Profile</button>
                                    }

                                    {
                                        platformName === 'blackbelt-tools' && <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'tools')}>Download Toolkit</button>
                                    }

                                    {
                                        platformName === 'blackbelt-projects' && <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'projects')}>Download Projects</button>
                                    }

                                    {
                                        platformName === 'blackbelt-roadmap' && <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'roadmap')}>Download Roadmap</button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}

            <Suspense fallback={<div>Loading</div>}>
                <BlackbeltLeadPagesTwoFieldSwiperComponent platformName={platformName} updateLeadForm={updateLeadForm} />
            </Suspense>

            {
                platformName === 'blackbelt-roadmap' && (
                    <>
                        {blackbeltcontent && blackbeltleadpagescontent && <section className='common-style background-dark-primary position-relative' id='roadMap'>
                            <div className="container">
                                <div className='text-white col-lg-8 mb-5 heading-with-text text-center mx-auto'>
                                    <h2 className='fs-40 fw-normal mb-3'>{blackbeltleadpagescontent && blackbeltleadpagescontent.secondFold.title}</h2>
                                    <p>{blackbeltleadpagescontent && blackbeltleadpagescontent.secondFold.subtitle}</p>
                                </div>
                                <ul className='roadmap-mobile'>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>1</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Start</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>2</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Develop ML Skills</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>3</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Develop Problem Solving Skills</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>4</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Work on Hackathons & Projects</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>5</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Develop Storytelling Skills</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>6</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Build AI & ML Portfolio</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>7</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Start Deep Learning Journey</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>8</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Learn Natural  Language Processing</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>9</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Learn Computer Vision</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                        <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>10</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                            <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <span className='roadmap-text fs-14'>Master Apache Spark</span>
                                    </li>
                                </ul>
                            </div>
                            <img src={cloudflare_size_url + (blackbeltcontent && blackbeltcontent.personalisedRoadmap.image.src + '?format=webp')} className="img-fluid mb-5" alt={blackbeltcontent && blackbeltcontent.personalisedRoadmap.image.alt} />
                            <div className="container">
                                <button className='btn btn-dark btn-dark-primary rounded-3 mx-auto d-table' id='roadmapBtn' data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={() => { updateLeadForm('roadmap', 'Your Data Science Blueprint', "Create My Roadmap", "roadmap", "blackbelt") }}>Get Personalized Roadmap</button>
                            </div>
                        </section>}
                    </>
                )
            }
            
            {(country_name === "IN" && blackbeltcontent && blackbeltcontent.latestOffers.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
              <div className='container'>
                <div className='row'>
                  <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
                    <div className='row align-items-center'>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <h2 className='fs-40 fw-normal mb-3 text-white'>{blackbeltcontent && blackbeltcontent.latestOffers.heading}</h2>
                        <h3 className='fs-56 my-3 fw-bold'>{blackbeltcontent && blackbeltcontent.latestOffers.discountPercentage}</h3>
                        <ul className='text-white'>
                          {blackbeltcontent && blackbeltcontent.latestOffers.points.map((point,index)=>{
                            return(
                              <li className='fs-20 mb-4' key={index}>{point}</li>
                            )
                          })}
                        </ul>
                        <button class="btn btn-dark btn-dark-primary rounded-3 mt-3" data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('full_payment','Enroll Today',"Enroll Now","offer")}}>Enroll Now</button>
                      </div>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <div className='timmer-card'>
                          <h4 className='text-white'>{blackbeltcontent && blackbeltcontent.latestOffers.text}</h4>
                          <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                            <li className='text-center'>
                              <h4 className='days'>1</h4>
                              <h5>Day</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='hours'>5</h4>
                              <h5>hr</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='minutes'>35</h4>
                              <h5>min</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='seconds'>20</h4>
                              <h5>Sec</h5>
                            </li>
                          </ul>
                          <a data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('full_payment','Enroll Today',"Enroll Now","offer")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                            <div className='offer-cta text-light-primary'>
                              {blackbeltcontent && blackbeltcontent.latestOffers.cta.text}
                              <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>}
            {((country_name === "CAN" || country_name === "US" || country_name === "CA") && blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
              <div className='container'>
                <div className='row'>
                  <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
                    <div className='row align-items-center'>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <h2 className='fs-40 fw-normal mb-3 text-white'>{blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.heading}</h2>
                        <h3 className='fs-56 my-3 fw-bold'>{blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.discountPercentage}</h3>
                        <ul className='text-white'>
                          {blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.points.map((point,index)=>{
                            return(
                              <li className='fs-20 mb-4' key={index}>{point}</li>
                            )
                          })}
                        </ul>
                        <button class="btn btn-dark btn-dark-primary rounded-3 mt-3" data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('full_payment','Enroll Today',"Enroll Now","offer")}}>Enroll Now</button>
                      </div>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <div className='timmer-card'>
                          <h4 className='text-white'>{blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.text}</h4>
                          <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                            <li className='text-center'>
                              <h4 className='days'>1</h4>
                              <h5>Day</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='hours'>5</h4>
                              <h5>hr</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='minutes'>35</h4>
                              <h5>min</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='seconds'>20</h4>
                              <h5>Sec</h5>
                            </li>
                          </ul>
                          <a data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('full_payment','Enroll Today',"Enroll Now","offer")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                            <div className='offer-cta text-light-primary'>
                              {blackbeltcontent && blackbeltcontent.latestOffersUsAndCanada.cta.text}
                              <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>}
            {((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
              <div className='container'>
                <div className='row'>
                  <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
                    <div className='row align-items-center'>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <h2 className='fs-40 fw-normal mb-3 text-white'>{blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.heading}</h2>
                        <h3 className='fs-56 my-3 fw-bold'>{blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.discountPercentage}</h3>
                        <ul className='text-white'>
                          {blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.points.map((point,index)=>{
                            return(
                              <li className='fs-20 mb-4' key={index}>{point}</li>
                            )
                          })}
                        </ul>
                        <button class="btn btn-dark btn-dark-primary rounded-3 mt-3" data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('full_payment','Enroll Today',"Enroll Now","offer")}}>Enroll Now</button>
                      </div>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <div className='timmer-card'>
                          <h4 className='text-white'>{blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.text}</h4>
                          <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                            <li className='text-center'>
                              <h4 className='days'>1</h4>
                              <h5>Day</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='hours'>5</h4>
                              <h5>hr</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='minutes'>35</h4>
                              <h5>min</h5>
                            </li>
                            <li className='text-center'>
                              <h4 className='seconds'>20</h4>
                              <h5>Sec</h5>
                            </li>
                          </ul>
                          <a data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('full_payment','Enroll Today',"Enroll Now","offer")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                            <div className='offer-cta text-light-primary'>
                              {blackbeltcontent && blackbeltcontent.latestOffersRestOfWorld.cta.text}
                              <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>}

            {blackbeltcontent && <section className='common-style background-dark-primary pb-5' id='contact'>
                <div className='container'>
                    <div className='text-white heading-with-text mb-5 text-center'>
                        <h2 className='fs-40 fw-normal mb-3 fs-18'> {blackbeltcontent && blackbeltcontent.contactUsToday.heading}</h2>
                        <p>{blackbeltcontent && blackbeltcontent.contactUsToday.subheading}</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12 col-sm-12 mb-3'>
                            <div className="card background-dark-secondary shadow-sm rounded-4 py-3 border border-1">
                                <div className="card-header border-0">
                                    <h2 className='text-center fs-24 text-white fw-medium'>Discover AI & ML Opportunities</h2>
                                </div>
                                <div className="card-body border-0">
                                    <div className="row">
                                        <div className="col-lg col-md-12 col-12 mb-3">
                                            <label htmlFor="contactFullName" className="form-label text-white fs-14">Full Name</label>
                                            <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactFullName" placeholder="Your Full Name" autoComplete='off' value={userDetails.fullname} onChange={e => setUserDetails((prev) => { return { ...prev, fullname: e.target.value } })} />
                                        </div>
                                        <div className="col-lg col-md-12 col-12 mb-3">
                                            <label htmlFor="contactPhoneNumber" className="form-label text-white fs-14">Phone Number</label>
                                            <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="contactPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={userDetails.phone} onChange={e => setUserDetails((prev) => { return { ...prev, phone: e.target.value } })} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg col-md-12 col-12">
                                            <label htmlFor="contactEmail" className="form-label text-white fs-14">Email Id</label>
                                            <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e => setUserDetails((prev) => { return { ...prev, email: e.target.value } })} />
                                        </div>
                                        <div className="col-lg col-md-12 col-12 my-3">
                                            <label htmlFor="leadFullName" className="form-label text-white fs-14">Experience :</label>
                                            <div className="experience-tag">
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact1"} name="experience" onChange={() => { updateExperience("0-3yrs") }} defaultChecked />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact1"}>0-3yrs</label>
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact2"} name="experience" onChange={() => { updateExperience("3-8yrs") }} />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact2"}>3-8yrs</label>
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact3"} name="experience" onChange={() => { updateExperience("8-12yrs") }} />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact3"}>8-12yrs</label>
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact4"} name="experience" onChange={() => { updateExperience("12yrs+") }} />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact4"}>12yrs+</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-2 d-flex flex-wrap">
                                        <div className="form-check d-flex align-items-center mb-2 me-4">
                                            <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                                            <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                                        </div>
                                        <div className="form-check d-flex align-items-center">
                                            <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" id="isWhatsappContactModal" defaultChecked />
                                            <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappContactModal">Send WhatsApp Updates</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0">
                                    <ReCAPTCHA
                                        className='mb-3'
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={onChange}
                                    />
                                    <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center" onClick={saveContactForm} disabled={!captcha}>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12 col-sm-12 mb-3 ps-lg-5'>
                            <h3 className='text-white'>{blackbeltcontent && blackbeltcontent.contactUsToday.support.heading}</h3>
                            <p className='text-dark-tertiary mt-4 fs-20 fw-light'>{blackbeltcontent && blackbeltcontent.contactUsToday.support.subheading}</p>
                            <ul>
                                <li className='row mb-3'>
                                    <div className='col-auto mb-3'>
                                        <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                                            <img src={cloudflare_url + blackbeltcontent.contactUsToday.support.items[0].icon} alt={blackbeltcontent.contactUsToday.support.items[0].icon} width={24} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md'>
                                        <p className='fw-bold fs-16 text-white mb-0 text-break'><a className='text-white' href='tel:+91-9354711240'>+91-9354711240</a></p>
                                        <span className='d-block text-dark-tertiary'>{blackbeltcontent.contactUsToday.support.items[0].text}</span>
                                    </div>
                                </li>
                                <li className='row mb-3'>
                                    <div className='col-auto mb-3'>
                                        <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                                            <img src={cloudflare_url + blackbeltcontent.contactUsToday.support.items[1].icon} alt={blackbeltcontent.contactUsToday.support.items[1].icon} width={24} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md'>
                                        <a className='text-white' href='mailto:customersupport@analyticsvidhya.com'><p className='fw-bold fs-16 text-white mb-0 text-break'>{blackbeltcontent.contactUsToday.support.items[1].heading}</p></a>
                                        <span className='d-block text-dark-tertiary'>{blackbeltcontent.contactUsToday.support.items[1].text}</span>
                                    </div>
                                </li>
                                <li className='row mb-3 d-none'>
                                    <div className='col-auto mb-3'>
                                        <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                                            <img src={cloudflare_url + blackbeltcontent.contactUsToday.support.items[2].icon} alt={blackbeltcontent.contactUsToday.support.items[2].icon} width={24} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md'>
                                        <p className='fw-bold fs-16 text-white mb-0 text-break'>{blackbeltcontent.contactUsToday.support.items[2].heading}</p>
                                        <span className='d-block text-dark-tertiary'>{blackbeltcontent.contactUsToday.support.items[2].text}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>}

            <Cookies platform={platformName} />

            <Footer platform={platformName} isWhatsApp={true}/>

        </>
    )
}


const mapStateToProps = (state) => ({
    blackbeltcontent: state.blackbeltContentReducer.blackbeltcontent,
    loading: state.blackbeltContentReducer.loading,
    error: state.blackbeltContentReducer.error,
    country_name: state.blackbeltContentReducer.country_code,
    blackbeltleadpagescontent: state.blackbeltLeadPagesContentReducer.blackbeltleadpagescontent
});

const mapDispatchToProps = dispatch => ({
    fetchBlackbeltContentHandler: data => dispatch(fetchBlackbeltContent(data)),
    fetchBlackbeltLeadPagesContentHandler: data => dispatch(fetchBlackbeltLeadPagesContent(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlackbeltLeadPagesTwoField);
