import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagenumber from '../../common/Page/Pagination';
import { getCommentData, updateWelcomeLoginModal } from '../../../store/Actions/Action';
import { convertDateInFormat, convertDateToLocalDatetime, getQueryDataElevateUser } from '../../../utils';
import $ from 'jquery';
import Reply from './Reply';
import { Editor } from '@tinymce/tinymce-react';
import ErrorAlert from '../Alert/ErrorAlert';
import SuccessAlert from '../Alert/SuccessAlert';


const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
let search_timeout = {};
let search_timeout_reply = {};
let flag = true;

function Comment({uniqueId,islogin,updateWelcomeLoginModalHandler,csrf,getCommentDataHandler,commentData,lastpage,resourceName,isUserRegistered,user_data}) {
    const [newCommentTitle, setNewCommentTitle] = useState("");
    const [newComment, setNewComment] = useState("");
    const [wordCount,setWordCount] = useState(0);
    const [updateWordCount,setUpdateWordCount] = useState(0);
    const [pageNumber,setPageNumber] = useState(1);
    const [updateCommentId,setUpdateCommentId] = useState(null);
    const [updateCommentText,setUpdateCommentText] = useState("");
    const [updateCommentTextChanged,setUpdateCommentTextChanged] = useState("");
    const [updateCommentTitle,setUpdateCommentTitle] = useState("");
    const [updateReplyId,setUpdateReplyId] = useState(null);
    const [updateReplyText,setUpdateReplyText] = useState("");
    const [updateReplyTextChanged,setUpdateReplyTextChanged] = useState("");
    const [feedback,setFeedback] = useState("");
    const [feedbackId,setFeedbackId] = useState("");
    const [feedbackType,setFeedbackType] = useState("");
    const [filter1,setFilter1] = useState("latest");
    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");
    const [errorMessage,setErrorMessage] = useState("");
    const [countOfLikesComment,setCountofLikesComment] = useState({});
    const [countOfLikesReply,setCountofLikesReply] = useState({});
    const [indexOfReplyRegardingComment,setIndexOfReplyRegardingComment] = useState({});

    useEffect(()=>{
        if(csrf != ""){
        let data = {
            uniqueId:uniqueId,
            page:1,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName,
            filter:filter1
        }
        getCommentDataHandler(data);
        window.$('.replyInput').collapse('hide');
        }
    },[islogin,csrf,filter1]);

    useEffect(()=>{
        if(commentData && flag){
            commentData.map((obj)=>{
                setIndexOfReplyRegardingComment((prev)=>{
                    return {...prev,[obj.id]:2}
                })
            })
            flag = false;
        }
    },[commentData])

    const createNewComment = (e) => {
        e.preventDefault();

        if(!islogin) {
                updateWelcomeLoginModalHandler({welcomeloginmodal:true})
        return;
            }

        if(newCommentTitle.trim() === ""){
            $('.comment-warning').remove();
            $('#commentEditor').css("border-color","red");
            $('#commentEditor').parent().append(`<small class="comment-warning col-12 text-danger">Comment title shouldn't be empty</small>`)
            setTimeout(() => {
                $('#commentEditor').css("border-color","gray");
                $('.comment-warning').slideUp();
                $('.comment-warning').remove();
            }, 2000);
            return false;
        }

        if(newComment === ""){
            $('.comment-warning').remove();
            $('#commentEditor').css("border-color","red");
            $('#commentEditor').parent().append(`<small class="comment-warning col-12 text-danger">Comment shouldn't be empty</small>`)
            setTimeout(() => {
                $('#commentEditor').css("border-color","gray");
                $('.comment-warning').slideUp();
                $('.comment-warning').remove();
            }, 2000);
            return false;
        }

        let mutation = `
        mutation {
            createComment(title:"${newCommentTitle}",commentText: """${newComment}""", resourceId: "${uniqueId}",resourceName:"${resourceName}") {
            comment {
                userId
                commentMentions
                commentText
                createdAt
                id
                isDeleted
                isEdited
                resourceId
                updatedAt
                createdAt
            }
            }
        }
        `;

        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
            setNewComment("");
            let tempdata = {
            uniqueId:uniqueId,
            page:1,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName,
            filter:filter1
            }
            getCommentDataHandler(tempdata);
            setNewCommentTitle("");
            window.tinyMCE.get("commentEditor").setContent("");
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const updateComment = () => {
        if(updateCommentTitle.trim() === ""){
            $('.comment-warning').remove();
            $('#updateCommentEditor').css("border-color","red");
            $('#updateCommentEditor').parent().append(`<small class="comment-warning col-12 text-danger">Comment title shouldn't be empty</small>`)
            setTimeout(() => {
                $('#updateCommentEditor').css("border-color","gray");
                $('.comment-warning').slideUp();
                $('.comment-warning').remove();
            }, 2000);
            return false;
        }
        if(updateCommentTextChanged === ""){
            $('.comment-warning').remove();
            $('#updateCommentEditor').css("border-color","red");
            $('#updateCommentEditor').parent().append(`<small class="comment-warning col-12 text-danger">Comment shouldn't be empty</small>`)
            setTimeout(() => {
                $('#updateCommentEditor').css("border-color","gray");
                $('.comment-warning').slideUp();
                $('.comment-warning').remove();
            }, 2000);
            return false;
        }

        let mutation = `
        mutation {
            updateComment(title:"${updateCommentTitle}",commentId: ${updateCommentId},commentText: """${updateCommentTextChanged}""") {
            comment {
                userId
                commentMentions
                commentText
                createdAt
                id
                isDeleted
                isEdited
                resourceId
                updatedAt
                createdAt
            }
            }
        }
        `;

        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
            let tempdata = {
            uniqueId:uniqueId,
            page:pageNumber,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName,
            filter:filter1
            }
            getCommentDataHandler(tempdata);
            window.$("#discussionEditComment").modal("hide");
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const deleteComment = (comment_id) => {
        let query = `
            mutation MyMutation {
            deleteComment(commentId: ${comment_id}) {
                success
            }
        }`;
        getQueryDataElevateUser(query, 'newdiscussion', "True", csrf)
        .then((data)=>{
            let tempdata = {
                uniqueId:uniqueId,
                page:pageNumber,
                islogin:islogin,
                csrf:csrf,
                resourceName:resourceName,
                filter:filter1
            }
            getCommentDataHandler(tempdata);
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const setUpdateCommentValues = (comment_id,comment_text,comment_title) =>{
        setUpdateCommentId(comment_id);
        setUpdateCommentTitle(comment_title);
        setUpdateCommentText(comment_text);
    }

    const debounceTheCommentLikeFunction = (comment_id) => {
        setCountofLikesComment((prev) => ({
            ...prev,
            [comment_id]: (prev[comment_id] || 0) + 1,
        }));

        if (search_timeout[comment_id]) {
            clearTimeout(search_timeout[comment_id]);
        }

        search_timeout[comment_id] = setTimeout(() => {
            setCountofLikesComment((prev) => {
                const likes = prev[comment_id] || 0; 
                likeCommentHandler(comment_id, likes); 

                return {
                    ...prev,
                    [comment_id]: 0,
                };
            });
        }, 500);
    };

    const likeCommentHandler = (comment_id,number_of_claps) => {
        if(!islogin) {
        updateWelcomeLoginModalHandler({welcomeloginmodal:true})
        return;
        }
        let mutation = `
        mutation{
            likeComment(commentId: "${comment_id}",totalClaps: ${number_of_claps}) {
                message
            }
        }
        `;
        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
        let tempdata = {
            uniqueId:uniqueId,
            page:pageNumber,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName,
            filter:filter1
        }
        getCommentDataHandler(tempdata);
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const updateCommentByPageNumber = (page_number)=>{
        if(csrf != ""){
            setPageNumber(page_number);
            let data = {
                uniqueId:uniqueId,
                page:page_number,
                islogin:islogin,
                csrf:csrf,
                resourceName:resourceName,
                filter:filter1
            }
            getCommentDataHandler(data);
        }
    };

    const debounceTheReplyLikeFunction = (reply_id) => {
        setCountofLikesReply((prev) => ({
            ...prev,
            [reply_id]: (prev[reply_id] || 0) + 1,
        }));

        if (search_timeout_reply[reply_id]) {
            clearTimeout(search_timeout_reply[reply_id]);
        }

        search_timeout_reply[reply_id] = setTimeout(() => {
            setCountofLikesReply((prev) => {
                const likes = prev[reply_id] || 0; 
                likeReplyHandler(reply_id, likes); 

                return {
                    ...prev,
                    [reply_id]: 0,
                };
            });
        }, 500);
    };

    const likeReplyHandler = (reply_id,number_of_claps) => {
        if(!islogin) {
        updateWelcomeLoginModalHandler({welcomeloginmodal:true})
        return;
        }
        let mutation = `
            mutation{
                likeReply(replyId: "${reply_id}",totalClaps: ${number_of_claps}) {
                    message
                }
            }
        `
        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
        let tempdata = {
            uniqueId:uniqueId,
            page:pageNumber,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName,
            filter:filter1
        }
        getCommentDataHandler(tempdata);
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const deleteReply = (reply_id_id) => {
        let query = `
            mutation MyMutation {
            deleteReply(replyId: ${reply_id_id}) {
                success
            }
        }`;
        getQueryDataElevateUser(query, 'newdiscussion', "True", csrf)
        .then((data)=>{
            let tempdata = {
                uniqueId:uniqueId,
                page:pageNumber,
                islogin:islogin,
                csrf:csrf,
                resourceName:resourceName,
                filter:filter1
            }
            getCommentDataHandler(tempdata);
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const updateReply = () => {
        if(updateReplyTextChanged === ""){
            $('.comment-warning').remove();
            $('#updateReplyEditor').css("border-color","red");
            $('#updateReplyEditor').parent().append(`<small class="comment-warning col-12 text-danger">Reply shouldn't be empty</small>`)
            setTimeout(() => {
                $('#updateReplyEditor').css("border-color","gray");
                $('.comment-warning').slideUp();
                $('.comment-warning').remove();
            }, 2000);
            return false;
        }

        let mutation = `
        mutation {
            updateReply(replyId: ${updateReplyId},repliedText: """${updateReplyTextChanged}""") {
            reply {
                updatedAt
                createdAt
            }
            }
        }
        `;

        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
            let tempdata = {
            uniqueId:uniqueId,
            page:pageNumber,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName,
            filter:filter1
            }
            getCommentDataHandler(tempdata);
            window.$("#discussionEditReply").modal("hide");
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const reportCommentOrReply = () => {
        if(feedback.trim() === ""){
            $('.comment-warning').remove();
            $('#feedbackInput').parent().append(`<small class="comment-warning col-12 text-danger">Reason shouldn't be empty</small>`)
            setTimeout(() => {
                $('.comment-warning').slideUp();
                $('.comment-warning').remove();
            }, 2000);
            return false;
        }

        let mutation = null; 

        if(feedbackType == "comment"){
            mutation = `
                mutation MyMutation {
                reportComment(reportText: """${feedback}""", commentId: "${feedbackId}",eventLink: "${window.location.origin}${window.location.pathname}") {
                    message
                    success
                }
                }
            `;
        }
        else{
            mutation = `
                mutation MyMutation {
                reportReply(reportText: """${feedback}""", replyId: "${feedbackId}",eventLink: "${window.location.origin}${window.location.pathname}") {
                    message
                    success
                }
                }
            `;
        }


        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
            setIsSuccess(true);
            if(feedbackType == "comment"){
                setMessage(data.data.reportComment.message);
            }
            else{
                setMessage(data.data.reportReply.message);
            }
            setTimeout(() => {
                setIsSuccess(false);
            }, 3000);
            setFeedback("");
            window.$("#feedbackModal").modal("hide");
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const setUpdateReplyValues = (reply_id,reply_text) =>{
        setUpdateReplyId(reply_id);
        setUpdateReplyText(reply_text);
    }

    const openFilePicker = (editor,editorId,tempcsrf) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', '.pdf,.doc,.docx,.txt,.xlsx,.csv');

        input.onchange = async () => {
            const file = input.files[0];

            if (file.size > 500 * 1024) { // 500 KB 
                setErrorMessage('File size exceeds 500 KB.');
                setIsError(true)
                setTimeout(() => {
                    setIsError(false);
                }, 2000);
                return;
            }

            
            $(`#${editorId}Loader`).removeClass("d-none");

            const payload = {
                "bucket": "av-eks-newdiscussion",
                "path": "user-discussion/files/",
                "content_type": file.type
            };
    
            const formData = new FormData();
            formData.append("file", file);
            formData.append("platform", 'newmarketingpages');
            formData.append("url", 'api/s3-upload/');
            formData.append("payload", JSON.stringify(payload));
            formData.append("forward_user_data","True");

            try {
                const response = await fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        "X-CSRFToken":tempcsrf,
                    },
                    credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        
                });

                if (!response.ok) {
                    throw new Error('Something went wrong at server end.');
                }

                const data = await response.json();

                if (data.response.status_code !== 200) {
                    throw new Error('Something went wrong at server end.');
                }

                const fileUrl = "https://av-eks-newdiscussion.s3.amazonaws.com/"+data.response.file_path; 
                const fileName = file.name; 

                editor.insertContent(`<a class="text-white" href="${fileUrl}" target="_blank">${fileName}</a>`);

                $(`#${editorId}Loader`).addClass("d-none");
            } catch (error) {

                $(`#${editorId}Loader`).addClass("d-none");
                console.error('File upload failed:', error);
                setErrorMessage(error);
                setIsError(true)
                setTimeout(() => {
                    setIsError(false);
                }, 2000);
            }
    };

    // Trigger the file input dialog
    input.click();
    };
    
    const openImagePicker = (editor,editorId,tempcsrf) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', '.webp,.png,.jpg');
        const allowedTypes = ['image/png', 'image/jpeg', 'image/webp'];

        input.onchange = async () => {
            const file = input.files[0];

            if (file.size > 100 * 1024) { // 100 KB 
                setErrorMessage('Image size exceeds 500 KB.');
                setIsError(true)
                setTimeout(() => {
                    setIsError(false);
                }, 2000);
                return;
            }

            if (!allowedTypes.includes(file.type)) {
                setErrorMessage('Only WEBP format is allowed.');
                setIsError(true)
                setTimeout(() => {
                    setIsError(false);
                }, 2000);
                return;
            }

            
            $(`#${editorId}Loader`).removeClass("d-none");

            const payload = {
                "bucket": "av-eks-newdiscussion",
                "path": "user-discussion/images/",
                "content_type": file.type
            };
    
            const formData = new FormData();
            formData.append("file", file);
            formData.append("platform", 'newmarketingpages');
            formData.append("url", 'api/s3-upload/');
            formData.append("payload", JSON.stringify(payload));
            formData.append("forward_user_data","True");

            try {
                const response = await fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        "X-CSRFToken":tempcsrf,
                    },
                    credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        
                });

                if (!response.ok) {
                    throw new Error('Something went wrong at server end.');
                }

                const data = await response.json();

                if (data.response.status_code !== 200) {
                    throw new Error('Something went wrong at server end.');
                }

                const fileUrl = "https://av-eks-newdiscussion.s3.amazonaws.com/"+data.response.file_path; 
                const fileName = file.name; 

                editor.insertContent(`<img class="img-fluid" src="${fileUrl}" alt="${fileName}" title="${fileName}" />`);
                $(`#${editorId}Loader`).addClass("d-none");
            } catch (error) {
                $(`#${editorId}Loader`).addClass("d-none");
                console.error('File upload failed:', error);
                setErrorMessage(error);
                setIsError(true)
                setTimeout(() => {
                    setIsError(false);
                }, 2000);
            }
    };

    // Trigger the file input dialog
    input.click();
    };


  return (
    <>
        {iserror && <ErrorAlert message={errorMessage} setIsError={setIsError} />}
        {issuccess && <SuccessAlert message={message} />}
        <div className="comment-section position-relative">
            {!islogin && <div className="not-login-blur position-absolute d-flex align-items-center justify-content-center">
            <div className="blur"></div>
            <p className='text-white mb-0 background-dark-tertiary p-4 w-auto rounded-2 cursor-pointer' onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
            <svg className='background-dark-primary me-3 p-1 rounded-circle' xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                <path d="M11.1641 12V9.33333C11.1641 6.38781 13.5519 4 16.4974 4C19.4429 4 21.8307 6.38781 21.8307 9.33333V12M11.4307 28H21.5641C23.0575 28 23.8043 28 24.3747 27.7094C24.8765 27.4537 25.2844 27.0457 25.5401 26.544C25.8307 25.9735 25.8307 25.2268 25.8307 23.7333V16.2667C25.8307 14.7732 25.8307 14.0265 25.5401 13.456C25.2844 12.9543 24.8765 12.5463 24.3747 12.2906C23.8043 12 23.0575 12 21.5641 12H11.4307C9.93726 12 9.19052 12 8.62009 12.2906C8.11832 12.5463 7.71037 12.9543 7.45471 13.456C7.16406 14.0265 7.16406 14.7732 7.16406 16.2667V23.7333C7.16406 25.2268 7.16406 25.9735 7.45471 26.544C7.71037 27.0457 8.11832 27.4537 8.62009 27.7094C9.19052 28 9.93726 28 11.4307 28Z" stroke="white" stroke-width="2.55" stroke-linecap="round"/>
            </svg>
                Please login/signup to participate in the discussion</p>
            </div>}
            {islogin && isUserRegistered == false && <div className="not-login-blur position-absolute d-flex align-items-center justify-content-center">
            <div className="blur"></div>
            <p className='text-white mb-0 background-dark-tertiary p-4 w-auto rounded-2'>
            <svg className='background-dark-primary me-3 p-1 rounded-circle' xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                <path d="M11.1641 12V9.33333C11.1641 6.38781 13.5519 4 16.4974 4C19.4429 4 21.8307 6.38781 21.8307 9.33333V12M11.4307 28H21.5641C23.0575 28 23.8043 28 24.3747 27.7094C24.8765 27.4537 25.2844 27.0457 25.5401 26.544C25.8307 25.9735 25.8307 25.2268 25.8307 23.7333V16.2667C25.8307 14.7732 25.8307 14.0265 25.5401 13.456C25.2844 12.9543 24.8765 12.5463 24.3747 12.2906C23.8043 12 23.0575 12 21.5641 12H11.4307C9.93726 12 9.19052 12 8.62009 12.2906C8.11832 12.5463 7.71037 12.9543 7.45471 13.456C7.16406 14.0265 7.16406 14.7732 7.16406 16.2667V23.7333C7.16406 25.2268 7.16406 25.9735 7.45471 26.544C7.71037 27.0457 8.11832 27.4537 8.62009 27.7094C9.19052 28 9.93726 28 11.4307 28Z" stroke="white" stroke-width="2.55" stroke-linecap="round"/>
            </svg>
                Please register to participate in the discussion</p>
            </div>}
            <div className="comment-box background-dark-tertiary mb-3 p-3 rounded-3 border border-secondary position-relative">
                <div className="commment-title-box border-bottom border-secondary d-flex">
                    <textarea class="form-control background-transparent mb-3 rounded-0" id="commentTitle" placeholder='Add Title here' value={newCommentTitle} onChange={(e)=>{e.target.value.split("").length<=100 && setNewCommentTitle(e.target.value)}}></textarea>
                    <span className='text-white me-auto d-inline mb-0 fs-14'>{newCommentTitle ? newCommentTitle.split("").length:0}/100</span>
                </div>
                <Editor
                    id="commentEditor"
                    tinymceScriptSrc={'https://pkg.analyticsvidhya.com/tinymce/tinymce.min.js'}
                    initialValue=""
                    init={{
                        height: 200,
                        content_css: 'dark',
                        content_style: '* {background-color:transparent !important;}.mce-content-body::before{color: #8E8E8E !important;}',
                        menubar: false,
                        statusbar: false,
                        elementpath: false,
                        branding: false,
                        paste_data_images: false,
                        resize: false,
                        placeholder: "What are your thoughts?...",
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code',
                            'insertdatetime', 'media', 'table', 'code', 'wordcount', 'codesample', 'email','emoticons'
                        ],
                        toolbar: 'bold italic underline bullist numlist codesample alignleft aligncenter alignright alignjustify outdent indent email link emoticons customimageupload customfileupload',
                        setup: (editor) => {
                            editor.ui.registry.addButton('customfileupload', {
                                icon: 'browse', 
                                tooltip: 'File Upload',
                                onAction: () => {
                                    openFilePicker(editor,"commentEditor",csrf);
                                }
                            });
                            editor.ui.registry.addButton('customimageupload', {
                                icon: 'image', 
                                tooltip: 'Image Upload',
                                onAction: () => {
                                    openImagePicker(editor,"commentEditor",csrf);
                                }
                            });
                            editor.on('Paste', (e) => {
                                if (e.clipboardData && e.clipboardData.items) {
                                    for (let i = 0; i < e.clipboardData.items.length; i++) {
                                        const item = e.clipboardData.items[i];
                                        if (item.type.startsWith('image/')) {
                                            e.preventDefault();
                                            $(".warning").remove();
                                            $("#commentEditor").next().after(
                                                `<small class="text-danger warning">Pasting images is not allowed.</small>`
                                            );
                                            setTimeout(() => {
                                                $(".warning").slideUp(1000, () => {
                                                    $(".warning").remove();
                                                });
                                            }, 3000);
                                            return;
                                        }
                                    }
                                }
                            });

                    },
                    paste_preprocess: function (plugin, args) {
                        const content = args.content;
                    
                        // Remove any <img> tags
                        const filteredContent = content.replace(/<img[^>]*>/g, '');
                    
                        // Replace the pasted content with the filtered content
                        if (content !== filteredContent) {
                          args.content = filteredContent;
                
                          $(".warning").remove();
                          $("#commentEditor").next().after(`<small class="text-danger warning">Pasting image is not allowed.</small>`);
                            setTimeout(() => {
                                $(".warning").slideUp(1000,()=>{
                                    $(".warning").remove();
                                });
                            }, 3000);
                        }
                    }
                    
                }}
                onEditorChange={(content,editor)=>{
                    const wordCount = editor.plugins.wordcount.body.getCharacterCount();
                    if (wordCount <= 1000) {
                        setNewComment(content);
                        setWordCount(wordCount);
                    } else {
                        $(".warning").remove();
                            $("#commentEditor").next().after(`<small class="text-danger warning">You have reached the comment limit.</small>`);
                            setTimeout(() => {
                                $(".warning").slideUp(1000,()=>{
                                    $(".warning").remove();
                                });
                        }, 3000);
                        editor.setContent(newComment);
                    }
                }}
            />
             <div id="commentEditorLoader" class="spinner-border text-light position-absolute top-50 start-50 d-none" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div className="btn-group-comment d-flex flex-wrap justify-content-end align-items-center">
                <p className='text-white me-auto d-inline mb-0 fs-14'>{wordCount}/1000</p>
                <button className="btn text-white border-0 text-decoration-underline" onClick={()=>{window.tinyMCE.get("commentEditor").setContent("");setNewComment("")}} disabled={wordCount<6}>Clear</button>
                <button className="btn btn-dark btn-dark-primary ms-3" onClick={createNewComment} disabled={wordCount==0}>Submit Reply</button>
            </div>
            </div>
            {commentData && commentData.length > 0 ?
            <div className="card background-dark-primary border-dark mb-3"  id='commentBox'>
                <div className="card-header">
                    <div className="filter-row row">
                        <div className="col-auto position-relative mt-1">
                            <svg className='position-absolute z-3' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.3905 3.33398H3.60941C3.01547 3.33398 2.71803 4.05208 3.138 4.47206L6.47134 7.80539C6.59636 7.93041 6.6666 8.09998 6.6666 8.27679V11.0007C6.6666 11.2105 6.7654 11.4081 6.93327 11.534L8.79993 12.934C9.01968 13.0988 9.33327 12.942 9.33327 12.6673V8.27679C9.33327 8.09998 9.4035 7.93041 9.52853 7.80539L12.8619 4.47206C13.2818 4.05208 12.9844 3.33398 12.3905 3.33398Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                            <select class="form-select background-dark-tertiary text-white border-0 ps-5 pe-2" aria-label="Default select example" value={filter1} onChange={(e)=>{setFilter1(e.target.value)}}>
                                <option value="latest">Latest</option>
                                <option value="oldest">Oldest</option>
                                <option value="popular">Popular</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="card-body overflow-auto">
                    {commentData && commentData.map((comment,index)=>{
                    return(
                        <div className="comment-card py-3" key={index}>
                        <div className="row">
                            <div className="col-auto">
                            {/* <div className="avtar px-0 overflow-hidden rounded-circle">
                                <img src="https://secure.gravatar.com/avatar/039be6ff55635c93638f6fbbc0be78cf?s=96&d=mm&r=g" alt="avtar images" width={48} height={48} className="rounded-circle object-fit-contain" loading="lazy" />
                            </div> */}
                            <p class="avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle">{comment.username[0]}</p>
                            </div>
                            <div className="col position-relative">
                                <div className="d-flex">
                                    <h3 className='text-white fs-20 fw-normal text-decoration-underline mb-0'>{comment.username}</h3>
                                    <div className="col-auto">
                                        { comment.isCommentedByUser && <span className=' fs-14 green-text-light bg-success bg-opacity-25 text-decoration-none ms-3 py-1 px-2 rounded-1'>Author</span>}
                                    </div>
                                </div>
                                <p className='text-dark-tertiary fw-normal fs-16'>{convertDateInFormat(convertDateToLocalDatetime(comment.updatedAt),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(comment.updatedAt),"hh:mm A")}</p>
                                <div class="discussion-dropdown dropdown position-absolute top-0 end-0">
                                    <button class="btn dropdown-toggle border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <ul class="dropdown-menu background-dark-tertiary dropdown-menu-end shadow border-0 px-3" aria-labelledby="dropdownMenuButton1">
                                        {user_data.username === comment.username && <li>
                                            <button class="btn btn-dark bg-transparent text-white border-0 dropdown-item rounded-2 py-2" data-bs-toggle="modal" data-bs-target="#discussionEditComment" onClick={()=>{setUpdateCommentValues(comment.id,comment.commentText,comment.title)}}>Edit</button>
                                        </li>}
                                        {user_data.username === comment.username &&<li>
                                            <button class="btn btn-dark bg-transparent text-white border-0 dropdown-item rounded-2 py-2"  onClick={()=>{deleteComment(comment.id)}}>Delete</button>
                                        </li>}
                                        {user_data.username !== comment.username &&<li>
                                            <button class="btn btn-dark bg-transparent text-white border-0 dropdown-item rounded-2 py-2" data-bs-toggle="modal" data-bs-target="#feedbackModal" onClick={()=>{setFeedbackId(comment.id);setFeedbackType("comment");}}>Report</button>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p className='fs-20 text-white fw-bold'>{comment.title}</p>
                        <p className='fs-16 text-white' dangerouslySetInnerHTML={{__html: comment.commentText }}></p>
                        <div className="comment-btn-group d-flex">
                            <button className='btn text-white d-flex border-0' onClick={()=>{debounceTheCommentLikeFunction(comment.id)}} disabled={comment.totalClapsOnCommentByUser == 50}>
                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clip-path="url(#clip0_14754_207828)">
                                <path d="M5.19571 6.61772C4.44689 5.86819 5.73599 4.68957 6.3792 5.33431L7.4974 6.29839M5.19571 6.61772C6.15886 7.5818 7.44306 9.1886 8.3055 10.1548M5.19571 6.61772C3.59045 4.36821 1.62729 6.15966 2.8468 7.58397M2.8468 7.58397C4.77284 9.83349 6.27219 11.0118 7.0213 11.7616M2.8468 7.58397C2.20526 8.0125 1.22141 8.69813 1.8845 9.83349C2.2586 10.474 4.7731 12.5113 5.73625 13.3683L2.90073 11.1168C2.25863 11.1168 1.56234 12.0808 1.88364 12.7256C2.60019 14.1634 7.76402 19.4721 10.8739 19.1528C11.6516 19.0729 12.3677 18.4052 13.1213 17.9405M7.4974 6.29839L11.8371 10.7974C12.157 11.1168 12.157 10.7934 11.1938 8.22452C10.8923 7.42024 11.195 7.26044 11.516 7.04473M7.4974 6.29839C7.4974 5.44153 8.03663 4.36821 8.94649 5.01093C10.2901 5.96005 11.195 6.79595 11.516 7.04473M11.516 7.04473C11.8371 6.93908 12.1581 6.61772 12.4792 6.94111C12.6313 7.1239 12.8527 7.41584 13.1213 7.80513M13.1213 7.80513C13.7969 8.78468 14.7707 10.3805 15.6897 12.4042C16.8579 14.9767 15.4692 16.2585 13.1213 17.9405M13.1213 7.80513C13.3353 6.9822 14.185 5.975 14.7265 6.29839C15.1487 6.55055 17.939 10.7954 18.2581 12.4042C18.6378 14.3193 17.616 19.301 13.1213 17.9405M10.2318 0.833252V2.44005M13.1213 1.15461L12.2112 2.44005M7.71645 1.15461L8.6796 2.44005" stroke="white" stroke-width="1.5"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_14754_207828">
                                    <rect width="20" height="20" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                            {comment.numLikesOnComment}
                            </button>
                            <button className='btn text-white d-flex border-0 ms-3'  data-bs-toggle="collapse" data-bs-target={`#replyinput${comment.id}`} aria-expanded="false" aria-controls={`replyinput${comment.id}`} onClick={()=>window.$(`#reply${comment.id}`).collapse('show')}>
                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 17.5C8.51664 17.5 7.06659 17.0601 5.83322 16.236C4.59986 15.4119 3.63856 14.2406 3.0709 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53683C2.9335 7.08197 3.64781 5.7456 4.6967 4.6967C5.74559 3.64781 7.08197 2.9335 8.53682 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.07091C14.2406 3.63856 15.4119 4.59986 16.236 5.83323C17.0601 7.0666 17.5 8.51664 17.5 10C17.5 11.24 17.2 12.4083 16.6667 13.4392L17.5 17.5L13.4392 16.6667C12.4092 17.1992 11.2392 17.5 10 17.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Reply
                            </button>
                            {comment.replySet.length > 0 && <button className="btn text-white border-0 ms-auto d-table" data-bs-toggle="collapse" data-bs-target={`#reply${comment.id}`} aria-expanded="false" aria-controls={`reply${comment.id}`}>Show {comment.replySet.length} reply</button>}
                        </div>
                        <div class="collapse replyInput" id={`replyinput${comment.id}`} data-bs-parent="#commentBox">
                            <Reply comment={comment} uniqueId={uniqueId} resourceName={resourceName} pageNumber={pageNumber} filter1={filter1} openFilePicker={openFilePicker} openImagePicker={openImagePicker}  />
                        </div>
                        <div class="collapse" id={`reply${comment.id}`}>
                            {comment.replySet && comment.replySet.map((reply,index)=>{
                            if(!indexOfReplyRegardingComment[comment.id] || index<= indexOfReplyRegardingComment[comment.id]){
                            return(
                                <div className="reply-card ps-5 py-4" key={`reply${index}`}>
                                <div className="row">
                                <div className="col-auto">
                                    {/* <div className="avtar px-0 overflow-hidden rounded-circle">
                                    <img src="https://secure.gravatar.com/avatar/039be6ff55635c93638f6fbbc0be78cf?s=96&d=mm&r=g" alt="avtar images" width={48} height={48} className="rounded-circle object-fit-contain" loading="lazy" />
                                    </div> */}
                                    <p class="avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle">{reply.username[0]}</p>
                                </div>
                                <div className="col position-relative">
                                    <div className="d-flex">
                                        <h3 className='text-white fs-20 fw-normal text-decoration-underline mb-0'>{reply.username}</h3>
                                        <div className="col-auto">
                                            { reply.isRepliedByUser && <span className=' fs-14 green-text-light bg-success bg-opacity-25 text-decoration-none ms-3 py-1 px-2 rounded-1'>Author</span>}
                                        </div>
                                    </div>
                                    <p className='text-dark-tertiary fw-normal fs-16'>{convertDateInFormat(convertDateToLocalDatetime(reply.updatedAt),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(reply.updatedAt),"hh:mm A")}</p>
                                    <div class="discussion-dropdown dropdown position-absolute top-0 end-0">
                                        <button class="btn dropdown-toggle border-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                        <ul class="dropdown-menu background-dark-tertiary dropdown-menu-end shadow border-0 px-3" aria-labelledby="dropdownMenuButton1">
                                            {user_data.username === reply.username && <li>
                                                <button class="btn btn-dark bg-transparent text-white border-0 dropdown-item rounded-2 py-2" data-bs-toggle="modal" data-bs-target="#discussionEditReply" onClick={()=>{setUpdateReplyValues(reply.id,reply.repliedText)}}>Edit</button>
                                            </li>}
                                            {user_data.username === reply.username &&<li>
                                                <button class="btn btn-dark bg-transparent text-white border-0 dropdown-item rounded-2 py-2"  onClick={()=>{deleteReply(reply.id)}}>Delete</button>
                                            </li>}
                                            {user_data.username !== reply.username &&<li>
                                                <button class="btn btn-dark bg-transparent text-white border-0 dropdown-item rounded-2 py-2" data-bs-toggle="modal" data-bs-target="#feedbackModal" onClick={()=>{setFeedbackId(reply.id);setFeedbackType("reply");}}>Report</button>
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                                </div>
                                <p className='fs-16 text-white' dangerouslySetInnerHTML={{__html: reply.repliedText }}></p>
                                <div className="comment-btn-group d-flex">
                                <button className='btn text-white d-flex border-0' onClick={()=>{debounceTheReplyLikeFunction(reply.id)}} disabled={reply.totalClapsOnReplyByUser == 50}>
                                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <g clip-path="url(#clip0_14754_207828)">
                                        <path d="M5.19571 6.61772C4.44689 5.86819 5.73599 4.68957 6.3792 5.33431L7.4974 6.29839M5.19571 6.61772C6.15886 7.5818 7.44306 9.1886 8.3055 10.1548M5.19571 6.61772C3.59045 4.36821 1.62729 6.15966 2.8468 7.58397M2.8468 7.58397C4.77284 9.83349 6.27219 11.0118 7.0213 11.7616M2.8468 7.58397C2.20526 8.0125 1.22141 8.69813 1.8845 9.83349C2.2586 10.474 4.7731 12.5113 5.73625 13.3683L2.90073 11.1168C2.25863 11.1168 1.56234 12.0808 1.88364 12.7256C2.60019 14.1634 7.76402 19.4721 10.8739 19.1528C11.6516 19.0729 12.3677 18.4052 13.1213 17.9405M7.4974 6.29839L11.8371 10.7974C12.157 11.1168 12.157 10.7934 11.1938 8.22452C10.8923 7.42024 11.195 7.26044 11.516 7.04473M7.4974 6.29839C7.4974 5.44153 8.03663 4.36821 8.94649 5.01093C10.2901 5.96005 11.195 6.79595 11.516 7.04473M11.516 7.04473C11.8371 6.93908 12.1581 6.61772 12.4792 6.94111C12.6313 7.1239 12.8527 7.41584 13.1213 7.80513M13.1213 7.80513C13.7969 8.78468 14.7707 10.3805 15.6897 12.4042C16.8579 14.9767 15.4692 16.2585 13.1213 17.9405M13.1213 7.80513C13.3353 6.9822 14.185 5.975 14.7265 6.29839C15.1487 6.55055 17.939 10.7954 18.2581 12.4042C18.6378 14.3193 17.616 19.301 13.1213 17.9405M10.2318 0.833252V2.44005M13.1213 1.15461L12.2112 2.44005M7.71645 1.15461L8.6796 2.44005" stroke="white" stroke-width="1.5"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_14754_207828">
                                        <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    {reply.numLikesOnReply}
                                </button>
                                <button className='btn text-white d-flex border-0 ms-3'   data-bs-toggle="collapse" data-bs-target={`#replyinput${comment.id}-${reply.id}`} aria-expanded="false" aria-controls={`replyinput${comment.id}-${reply.id}`}>
                                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M10 17.5C8.51664 17.5 7.06659 17.0601 5.83322 16.236C4.59986 15.4119 3.63856 14.2406 3.0709 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53683C2.9335 7.08197 3.64781 5.7456 4.6967 4.6967C5.74559 3.64781 7.08197 2.9335 8.53682 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.07091C14.2406 3.63856 15.4119 4.59986 16.236 5.83323C17.0601 7.0666 17.5 8.51664 17.5 10C17.5 11.24 17.2 12.4083 16.6667 13.4392L17.5 17.5L13.4392 16.6667C12.4092 17.1992 11.2392 17.5 10 17.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Reply
                                </button>
                                </div>
                                <div class="collapse replyInput" id={`replyinput${comment.id}-${reply.id}`}>
                                    <Reply comment={comment} uniqueId={uniqueId} resourceName={resourceName} pageNumber={pageNumber} reply_id={reply.id} filter1={filter1} openFilePicker={openFilePicker} openImagePicker={openImagePicker}  />
                                </div>
                                </div>)
                            }})}
                            {indexOfReplyRegardingComment[comment.id] < comment.replySet.length-1  && <button className='btn btn-dark btn-dark-primary mt-4 mb-5 d-table mx-auto' onClick={()=>{setIndexOfReplyRegardingComment((prev)=>{return{...prev,[comment.id]:prev[comment.id]+3}})}}>View more replies</button>}
                        </div>
                        </div>)
                    })}
                </div>
            </div>
            :<div className='my-5'>
                <img className='img-fluid mx-auto d-table' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/no-comment.png"} alt="no comment" />
            </div>}
            <Pagenumber last_page={lastpage} updatePageNumber={updateCommentByPageNumber} />
        </div>
        <div className="modal lead-modal" data-bs-backdrop="static" id='discussionEditComment' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content background-dark-primary shadow-sm rounded-4">
                <div className="modal-header border-0 p-0">
                <button type="button" className="btn background-dark-secondary p-2 rounded-circle close ms-auto" data-bs-dismiss="modal" aria-label="Close" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </button>
                </div>
                <div className="modal-body">
                    <div className="comment-box background-dark-tertiary mb-3 p-3 rounded-3 border border-secondary position-relative">
                        <div className="commment-title-box border-bottom border-secondary d-flex">
                            <textarea class="form-control background-transparent mb-3 rounded-0" id="commentTitle" placeholder='Add Title here' value={updateCommentTitle} onChange={(e)=>{e.target.value.split(" ").length<=100 && setUpdateCommentTitle(e.target.value)}}></textarea>
                            <span className='text-white me-auto d-inline mb-0 fs-14'>{newCommentTitle ? newCommentTitle.split(" ").length:0}/100</span>
                        </div>
                    <Editor
                        id='updateCommentEditor'
                        tinymceScriptSrc={'https://pkg.analyticsvidhya.com/tinymce/tinymce.min.js'}
                        initialValue={updateCommentText}
                        init={{
                            height: 200,
                            content_css:'dark',
                            content_style: '* {background-color:transparent !important;}.mce-content-body::before{color: #8E8E8E !important;}',
                            menubar: false,
                            statusbar: false,
                            elementpath: false,
                            branding: false,
                            paste_data_images: false,
                            resize: false,
                            placeholder: "What are your thoughts?...",
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code',
                                'insertdatetime', 'media', 'table', 'code', 'wordcount','codesample','email','emoticons'
                            ],
                            toolbar: 'bold italic underline bullist numlist codesample alignleft aligncenter alignright alignjustify  outdent indent  email link emoticons customimageupload customfileupload',
                            setup: (editor) => {
                                editor.ui.registry.addButton('customfileupload', {
                                    icon: 'browse', 
                                    tooltip: 'File Upload',
                                    onAction: () => {
                                        openFilePicker(editor,"updateCommentEditor",csrf);
                                    }
                                });
                                editor.ui.registry.addButton('customimageupload', {
                                    icon: 'image', 
                                    tooltip: 'Image Upload',
                                    onAction: () => {
                                        openImagePicker(editor,"updateCommentEditor",csrf);
                                    }
                                });
                                editor.on('Paste', (e) => {
                                const pastedContent = e.clipboardData.getData('text/plain');
                                const pastedWordCount = pastedContent.split('').length;
                                if (pastedWordCount + editor.plugins.wordcount.body.getCharacterCount() > 1000) {
                                    e.preventDefault();
                                    $(".warning").remove();
                                    $("#updateCommentEditor").next().after(`<small class="text-danger warning">You have reached the comment limit.</small>`);
                                    setTimeout(() => {
                                        $(".warning").slideUp(1000,()=>{
                                            $(".warning").remove();
                                        });
                                    }, 3000);
                                }
                                });
                            },
                            paste_preprocess: function (plugin, args) {
                                const content = args.content;
                            
                                // Remove any <img> tags
                                const filteredContent = content.replace(/<img[^>]*>/g, '');
                            
                                // Replace the pasted content with the filtered content
                                if (content !== filteredContent) {
                                  args.content = filteredContent;
                        
                                  $(".warning").remove();
                                  $("#updateCommentEditor").next().after(`<small class="text-danger warning">Pasting image is not allowed.</small>`);
                                    setTimeout(() => {
                                        $(".warning").slideUp(1000,()=>{
                                            $(".warning").remove();
                                        });
                                    }, 3000);
                                }
                            }
                            
                        }}
                        onEditorChange={(content,editor)=>{
                            const wordCount = editor.plugins.wordcount.body.getCharacterCount();
                            if (wordCount <= 1000) {
                                setUpdateCommentTextChanged(content);
                                setUpdateWordCount(wordCount);
                            } else {
                                $(".warning").remove();
                                    $("#updateCommentEditor").next().after(`<small class="text-danger warning">You have reached the comment limit.</small>`);
                                    setTimeout(() => {
                                        $(".warning").slideUp(1000,()=>{
                                            $(".warning").remove();
                                        });
                                }, 3000);
                                editor.setContent(updateCommentTextChanged);
                            }
                        }}
                    />
                    <div id="updateCommentEditorLoader" class="spinner-border text-light position-absolute top-50 start-50 d-none" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div className="btn-group-comment d-flex flex-wrap justify-content-end align-items-center">
                        <p className='text-white me-auto d-inline mb-0 fs-14'>{updateWordCount}/1000</p>
                        <button className="btn btn-dark btn-dark-primary ms-3" onClick={updateComment} disabled={updateWordCount==0}>Submit Reply</button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className="modal lead-modal" data-bs-backdrop="static" id='discussionEditReply' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content background-dark-primary shadow-sm rounded-4">
                <div className="modal-header border-0 p-0">
                <button type="button" className="btn background-dark-secondary p-2 rounded-circle close ms-auto" data-bs-dismiss="modal" aria-label="Close" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </button>
                </div>
                <div className="modal-body">
                    <div className="comment-box background-dark-tertiary mb-3 p-3 rounded-3 border border-secondary position-relative">
                    <Editor
                        id='updateReplyEditor'
                        tinymceScriptSrc={'https://pkg.analyticsvidhya.com/tinymce/tinymce.min.js'}
                        initialValue={updateReplyText}
                        init={{
                            height: 200,
                            content_css:'dark',
                            content_style: '* {background-color:transparent !important;}.mce-content-body::before{color: #8E8E8E !important;}',
                            menubar: false,
                            statusbar: false,
                            elementpath: false,
                            branding: false,
                            paste_data_images: false,
                            resize: false,
                            placeholder: "What are your thoughts?...",
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code',
                                'insertdatetime', 'media', 'table', 'code', 'wordcount','codesample','email','emoticons'
                            ],
                            toolbar: 'bold italic underline bullist numlist codesample alignleft aligncenter alignright alignjustify  outdent indent  email link  emoticons customimageupload customfileupload',
                            setup: (editor) => {
                                editor.ui.registry.addButton('customfileupload', {
                                    icon: 'browse', 
                                    tooltip: 'File Upload',
                                    onAction: () => {
                                        openFilePicker(editor,"updateReplyEditor",csrf);
                                    }
                                });
                                editor.ui.registry.addButton('customimageupload', {
                                    icon: 'image', 
                                    tooltip: 'Image Upload',
                                    onAction: () => {
                                        openImagePicker(editor,"updateReplyEditor",csrf);
                                    }
                                });
                                editor.on('Paste', (e) => {
                                const pastedContent = e.clipboardData.getData('text/plain');
                                const pastedWordCount = pastedContent.split('').length;
                                if (pastedWordCount + editor.plugins.wordcount.body.getCharacterCount() > 1000) {
                                    e.preventDefault();
                                    $(".warning").remove();
                                    $("#updateReplyEditor").next().after(`<small class="text-danger warning">You have reached the comment limit.</small>`);
                                    setTimeout(() => {
                                        $(".warning").slideUp(1000,()=>{
                                            $(".warning").remove();
                                        });
                                    }, 3000);
                                }
                                });
                            },
                            paste_preprocess: function (plugin, args) {
                                const content = args.content;
                            
                                // Remove any <img> tags
                                const filteredContent = content.replace(/<img[^>]*>/g, '');
                            
                                // Replace the pasted content with the filtered content
                                if (content !== filteredContent) {
                                  args.content = filteredContent;
                        
                                  $(".warning").remove();
                                  $("#updateReplyEditor").next().after(`<small class="text-danger warning">Pasting image is not allowed.</small>`);
                                    setTimeout(() => {
                                        $(".warning").slideUp(1000,()=>{
                                            $(".warning").remove();
                                        });
                                    }, 3000);
                                }
                            }
                            
                        }}
                        onEditorChange={(content,editor)=>{
                            const wordCount = editor.plugins.wordcount.body.getCharacterCount();
                            if (wordCount <= 1000) {
                                setUpdateReplyTextChanged(content);
                                setUpdateWordCount(wordCount);
                            } else {
                                $(".warning").remove();
                                    $("#updateReplyEditor").next().after(`<small class="text-danger warning">You have reached the comment limit.</small>`);
                                    setTimeout(() => {
                                        $(".warning").slideUp(1000,()=>{
                                            $(".warning").remove();
                                        });
                                }, 3000);
                                editor.setContent(updateReplyTextChanged);
                            }
                        }}
                    />
                    <div id="updateReplyEditorLoader" class="spinner-border text-light position-absolute top-50 start-50 d-none" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div className="btn-group-comment d-flex flex-wrap justify-content-end align-items-center">
                        <p className='text-white me-auto d-inline mb-0 fs-14'>{updateWordCount}/1000</p>
                        <button className="btn btn-dark btn-dark-primary ms-3" onClick={updateReply} disabled={updateWordCount==0}>Submit Reply</button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div className="modal lead-modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id='feedbackModal' tabIndex="-1" aria-labelledby="feedbackLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content background-dark-primary shadow-sm rounded-4">
                    <div className="modal-header border-0 py-2 text-white">
                        Write your reason here:
                        <button type="button" className="btn background-dark-secondary p-2 rounded-circle close ms-auto" data-bs-dismiss="modal" aria-label="Close" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <textarea className='form-control background-dark-secondary text-white border border-secondary' name="feedback" id="feedbackInput" cols="30" rows="10" value={feedback} onChange={(e)=>{setFeedback(e.target.value)}} required></textarea>
                        </form>
                    </div>
                    <div className="modal-footer border-0">
                        <button className='btn btn-dark btn-dark-primary mx-auto d-table' onClick={reportCommentOrReply}>Submit</button>
                    </div>
                </div>
                </div>
        </div>
    </>
  )
}


const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
    commentData:state.commentReducer.commentData,
    lastpage:state.commentReducer.lastpage,
    user_data: state.LoginModalReducer.userData,
  });
  
  const mapDispatchToProps = dispatch=>({
    updateWelcomeLoginModalHandler:data=>dispatch(updateWelcomeLoginModal(data)),
    getCommentDataHandler:data=>dispatch(getCommentData(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Comment);