import React from 'react'
import Header from '../Header/Header'

export default function MandatoryLogin() {
  return (
    <>
        <Header />
        <section className='background-dark-primary vh-100'>
            <div className="container h-100">
                <div className="row h-100 align-items-center justify-content-center">
                    <h1 className='text-white text-center'>Welcome to Analytics Vidhya! 🚀 <br />Please login to access the unsubscribe page.<br/> Thank you! 🙏</h1>
                </div>
            </div>
        </section>
    </>
  )
}
