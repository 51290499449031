import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import Footer from '../../../common/Footer/Footer';
import Flashstrip from '../../../common/Header/Flashstrip';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import Pagination from '../../../common/Page/Pagination';
import { connect } from 'react-redux';
import { getHackathonDetailData, getHackathonDetailRegistrationData } from '../../../../store/Actions/Action';
import { Link, useParams } from 'react-router-dom';
import { getQueryData, getQueryDataElevateUser, scrollTop } from '../../../../utils';
import Loader from '../../../common/Loader';
import Cookies from '../../../common/Footer/Cookies';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isValidURL } from '../../../../utils/InputValidation';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import $ from 'jquery';

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;

function Leaderboard({islogin,csrf,tournamentData,stagesData,isUserRegistered,getHackathonDetailDataHandler,getHackathonDetailRegistrationDataHandler,user_data}) {
    const [activeTab, setActiveTab] = useState('overall');
    const [activeIndex, setActiveIndex] = useState(-1);
    const [overallLeaderboard, setOverallLeaderboard] = useState([]);
    const [leaderboardsData, setLeaderboardsData] = useState([]);
    const [pvtLeaderboardsData, setPvtLeaderboardsData] = useState([]);
    const [showPrivateLeaderboard, setShowPrivateLeaderboard] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    const [teamData, setTeamData] = useState("");
    const [approachLink, setApproachLink] = useState("");
    const [userApproachLinkList, setUserApproachLinkList] = useState([]);
    const [success, setSuccess] = useState(false);
    const [iserror,setIsError] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");  
    const [successMessage, setSuccessMessage] = useState('You have successfully registered for the event, we have added the event to your calendar you will be notified before the event starts.');


    const {hackathonSlug} = useParams();
    useEffect(()=>{
        if(!tournamentData || !stagesData) {
            getHackathonDetailDataHandler(hackathonSlug);
        }
    },[])

    useEffect(()=> {
        scrollTop();
    },[])

    useEffect(()=>{
        //If logout, set isuserregistered to false here
        if(csrf != ""){
          let tempdata = {
            hackathonSlug:hackathonSlug,
            csrf:csrf
          }
          if(islogin) {
            getHackathonDetailRegistrationDataHandler(tempdata);
          }
        }
    },[csrf,islogin,hackathonSlug]);

    useEffect(()=>{
        if(csrf && islogin && tournamentData) {
            let query = `
            query {
                teamDetailsByUserId(tournamentId: ${tournamentData.id}) {
                  teamData {
                    id
                  }
                }
            }`
            getQueryDataElevateUser(query,'newcompetition', "True", csrf)
                .then((data)=>{
                    setTeamData(data?.data?.teamDetailsByUserId?.teamData);
                })
                .catch((error)=>{
                    console.log(error);
                })
        }
    },[csrf,islogin,tournamentData]);

    useEffect(()=>{
        if(stagesData && stagesData.length>=1) {
            setActiveIndex(0);
            let stageType = stagesData[0].stage?.stageType?.name;
            let isAnonymous = tournamentData?.isAnonymous;
            if(stageType === 'practice') {
                setActiveTab('coderound');
                setShowPrivateLeaderboard((!stagesData[0]?.stage?.isActive && stagesData[0]?.stage?.isPvtLbCreated) ? true : false);
            }   else if(stageType === 'skill test') {
                setActiveTab('skilltest');
            }


            stagesData.map((stage,index)=>{
                if(stage?.stage?.id && index === 0) {
                    let stageId = stage?.stage?.id;

                    if(stage?.stage?.stageType?.name === 'skill test') {
                        let skillTestLeaderboardQuery = `
                        query {
                            skillTestLeaderboard(stageId: "${stageId}", page: 1, limit: 10) {
                              totalPages
                              skilltestLeaderboardData {
                                rank
                                teamName
                                userId
                                score
                                accuracy
                                isAvccMember
                              }
                              skilltestCurrentUserData {
                                rank
                                score
                                teamName
                                userId
                                accuracy
                                isAvccMember
                              }
                            }
                        }`
                        if(islogin) {
                            if(csrf) {
                                getQueryDataElevateUser(skillTestLeaderboardQuery, 'newleaderboard',"True",csrf)
                                    .then((data) => {
                                        setLeaderboardsData((prevState)=>{
                                            const newState = [...prevState];
                                            newState[index] = data.data;
                                            return newState;
                                        });
                                        setShowLoader(false);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        setShowLoader(false);
                                    })
                            }
                        }   else if(islogin === false) {
                            getQueryData(skillTestLeaderboardQuery, 'newleaderboard')
                                .then((data) => {
                                    setLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setShowLoader(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
                    } else if(stage?.stage?.stageType?.name === 'practice') {
                        let isAscendingLb = stage?.stage?.isAscendingLb;
                        let query = `
                        query {
                            hackathonPublicLeaderboard(stageId: "${stageId}", page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}, isAscendingLb: ${isAscendingLb}, isAnonymousLb: ${isAnonymous}) {
                              totalPages
                              hackathonLeaderboardData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                scoreHistory
                                publicScore
                                isAvccMember
                              }
                              hackathonCurrentUserData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                scoreHistory
                                publicScore
                                isAvccMember
                              }
                            }
                        }`
                        if(islogin) {
                            if(csrf && teamData !== "") {
                                getQueryDataElevateUser(query,'newleaderboard', "True", csrf)
                                    .then((data)=>{
                                        setLeaderboardsData((prevState)=>{
                                            const newState = [...prevState];
                                            newState[index] = data.data;
                                            return newState;
                                        });
                                        setShowLoader(false);
                                        console.log("Leaderboard Data: ", data?.data?.hackathonPublicLeaderboard);
                                        console.log(data?.data?.hackathonPublicLeaderboard?.totalPages);
                                    })
                                    .catch((error)=>{
                                        console.log(error);
                                        setShowLoader(false);
                                    })
                            }
                        }   else if(islogin === false){
                            getQueryData(query,'newleaderboard')
                                .then((data)=>{
                                    setLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setShowLoader(false);
                                    console.log("Leaderboard Data: ", data?.data?.hackathonPublicLeaderboard);
                                    console.log(data?.data?.hackathonPublicLeaderboard?.totalPages);
                                })
                                .catch((error)=>{
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
                        if(!stage.stage.isActive && stage.stage.isPvtLbCreated) {
                            let pvtLeaderboardQuery = `
                            query {
                                hackathonPrivateLeaderboard(stageId: "${stageId}", page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}, isAscendingLb: ${isAscendingLb}, isAnonymousLb: ${isAnonymous}) {
                                totalPages
                                hackathonLeaderboardData {
                                    rank
                                    userId
                                    isAnonymous
                                    anonymousName
                                    submissionId
                                    teamName
                                    privateScore
                                    showCodeOnLb
                                    isAvccMember
                                }
                                hackathonCurrentUserData {
                                    rank
                                    userId
                                    isAnonymous
                                    anonymousName
                                    submissionId
                                    teamName
                                    privateScore
                                    showCodeOnLb
                                    isAvccMember
                                }
                                }
                            }`
                            if(islogin) {
                                if(csrf && teamData !== "") {
                                    getQueryDataElevateUser(pvtLeaderboardQuery,'newleaderboard',"True",csrf)
                                    .then((data)=>{
                                        setPvtLeaderboardsData((prevState)=>{
                                            const newState = [...prevState];
                                            newState[index] = data.data;
                                            return newState;
                                        });
                                        setUserApproachOnIndex(data.data.hackathonPrivateLeaderboard.hackathonLeaderboardData.map((obj)=>{return obj.submissionId}),index)
                                        setShowLoader(false);
                                    console.log("Leaderboard Data: ", data?.data?.hackathonPrivateLeaderboard?.hackathonLeaderboardData);
                                    console.log("Total Pages: ",data?.data?.hackathonPrivateLeaderboard?.totalPages);
                                    })
                                    .catch((error)=>{
                                        console.log(error);
                                        setShowLoader(false);
                                    })
                                }
                            }   else if(islogin === false){
                                getQueryData(pvtLeaderboardQuery,'newleaderboard')
                                .then((data)=>{
                                    setPvtLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setUserApproachOnIndex(data.data.hackathonPrivateLeaderboard.hackathonLeaderboardData.map((obj)=>{return obj.submissionId}),index);
                                    setShowLoader(false);
                                console.log("Leaderboard Data: ", data?.data?.hackathonPrivateLeaderboard?.hackathonLeaderboardData);
                                console.log("Total Pages: ",data?.data?.hackathonPrivateLeaderboard?.totalPages);
                                })
                                .catch((error)=>{
                                    console.log(error);
                                    setShowLoader(false);
                                })
                            }
                        }   
                    }
                }
            })
        }
    },[stagesData,teamData,islogin,csrf])

    // don't remove this code (for overall leaderboard) !important;
    // useEffect(()=>{
    //     if(tournamentData && stagesData && stagesData.length>1) {
    //         if(islogin) {
    //             if(teamData !== "") {
    //                 let overallLeaderboardQuery = `
    //                 query  {
    //                     overallLeaderboard(tournamentId:${tournamentData.id}, page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}) {
    //                       totalPages
    //                       overallLeaderboardData {
    //                         rank
    //                         userId
    //                         anonymousName
    //                         isAnonymous
    //                         teamName
    //                         score
    //                       }
    //                       overallCurrentUserData {
    //                         rank
    //                         userId
    //                         anonymousName
    //                         isAnonymous
    //                         teamName
    //                         score
    //                       }
    //                     }
    //                 }`
    //                 if(csrf) {
    //                     getQueryDataElevateUser(overallLeaderboardQuery, 'newleaderboard',"True",csrf)
    //                         .then((data) => {
    //                             setOverallLeaderboard(data.data);
    //                             setShowLoader(false);
    //                             console.log("Overall Leaderboard Data:", data.data.overallLeaderboardData);
    //                             console.log("Overall Current User Leaderboard Data:", data.data.overallLeaderboardData.overallCurrentUserData);
    //                             console.log("Overall Total Pages:",data?.data?.overallLeaderboardData?.totalPages);
    //                         })
    //                         .catch((error) => {
    //                             console.log(error);
    //                             setShowLoader(false);
    //                         })
    //                 }
    //             }
    //         } else if(islogin === false){
    //             let overallLeaderboardQuery = `
    //             query  {
    //                 overallLeaderboard(tournamentId:${tournamentData.id}, page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}) {
    //                   totalPages
    //                   overallLeaderboardData {
    //                     rank
    //                     userId
    //                     anonymousName
    //                     isAnonymous
    //                     teamName
    //                     score
    //                   }
    //                   overallCurrentUserData {
    //                     rank
    //                     userId
    //                     anonymousName
    //                     isAnonymous
    //                     teamName
    //                     score
    //                   }
    //                 }
    //             }`
    //             getQueryData(overallLeaderboardQuery, 'newleaderboard')
    //                 .then((data) => {
    //                     setOverallLeaderboard(data.data);
    //                     setShowLoader(false);
    //                     console.log("Overall Leaderboard Data:", data.data.overallLeaderboardData);
    //                     console.log("Overall Current User Leaderboard Data:", data.data.overallLeaderboardData.overallCurrentUserData);
    //                     console.log("Overall Total Pages:",data?.data?.overallLeaderboardData?.totalPages);
    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                     setShowLoader(false);
    //                 })
    //         }
    //     }
    // },[tournamentData,teamData,islogin,csrf])


    let setUserApproachOnIndex = (submissionIdList,index) =>{
        let query = `query MyQuery {
            ${submissionIdList.map((obj,index)=>{
                return(`user${index} :userApproach(submissionId: ${obj})`)
            })}
        }`;
        getQueryData(query,'newcompetition')
        .then((data)=>{
            let newState = [...userApproachLinkList];
            newState[index] = data.data;
            setUserApproachLinkList(newState);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        if(pvtLeaderboardsData[activeIndex]?.hackathonPrivateLeaderboard?.hackathonCurrentUserData?.submissionId){
            let query = `query MyQuery {
                userApproach(submissionId: ${pvtLeaderboardsData[activeIndex]?.hackathonPrivateLeaderboard?.hackathonCurrentUserData?.submissionId})
            }`;
            getQueryData(query,'newcompetition')
            .then((data)=>{
                setApproachLink(data.data.userApproach);
            })
            .catch((error)=>{
                console.log(error);
            })
        }

    },[activeIndex,pvtLeaderboardsData])

    const pageChangeHandler = (tab,index,page,stageId,isAscendingLb) => {
        let isAnonymous = tournamentData?.isAnonymous;
        if(stageId && page) {
            if(tab==='skilltest') {
                let skillTestLeaderboardQuery = `
                        query {
                            skillTestLeaderboard(stageId: "${stageId}", page: ${page}, limit: 10) {
                              totalPages
                              skilltestLeaderboardData {
                                rank
                                teamName
                                userId
                                score
                                accuracy
                                isAvccMember
                              }
                              skilltestCurrentUserData {
                                rank
                                score
                                teamName
                                userId
                                accuracy
                                isAvccMember
                              }
                            }
                        }`
                        if(islogin) {
                            if(csrf) {
                                setShowLoader(true);
                                getQueryDataElevateUser(skillTestLeaderboardQuery, 'newleaderboard',"True",csrf)
                                    .then((data) => {
                                        setLeaderboardsData((prevState)=>{
                                            const newState = [...prevState];
                                            newState[index] = data.data;
                                            return newState;
                                        });
                                        setShowLoader(false);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        setShowLoader(false);
                                    })
                            }
                        }   else {
                            setShowLoader(true);
                            getQueryData(skillTestLeaderboardQuery, 'newleaderboard')
                                .then((data) => {
                                    setLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setShowLoader(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
            }   else if(tab==='coderound') {
                    if(!showPrivateLeaderboard) {
                        let query = `
                        query {
                            hackathonPublicLeaderboard(stageId: "${stageId}", page: ${page}, limit: 10, teamId: ${teamData ? teamData.id : null}, isAscendingLb: ${isAscendingLb}, isAnonymousLb: ${isAnonymous}) {
                              totalPages
                              hackathonLeaderboardData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                scoreHistory
                                publicScore
                                isAvccMember
                              }
                              hackathonCurrentUserData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                scoreHistory
                                publicScore
                                isAvccMember
                              }
                            }
                        }`
                        if(islogin) {
                            if(csrf) {
                                setShowLoader(true);
                                getQueryDataElevateUser(query,'newleaderboard', "True", csrf)
                                    .then((data)=>{
                                        setLeaderboardsData((prevState)=>{
                                            const newState = [...prevState];
                                            newState[index] = data.data;
                                            return newState;
                                        });
                                        setShowLoader(false);
                                        console.log("Leaderboard Data: ", data?.data?.hackathonPublicLeaderboard);
                                        console.log(data?.data?.hackathonPublicLeaderboard?.totalPages);
                                    })
                                    .catch((error)=>{
                                        console.log(error);
                                        setShowLoader(false);
                                    })
                            }
                        }   else {
                            setShowLoader(true);
                            getQueryData(query,'newleaderboard')
                                .then((data)=>{
                                    setLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setShowLoader(false);
                                    console.log("Leaderboard Data: ", data?.data?.hackathonPublicLeaderboard);
                                    console.log(data?.data?.hackathonPublicLeaderboard?.totalPages);
                                })
                                .catch((error)=>{
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
                    }   
                    else {
                        let pvtLeaderboardQuery = `
                        query {
                            hackathonPrivateLeaderboard(stageId: "${stageId}", page: ${page}, limit: 10, teamId: ${teamData ? teamData.id : null}, isAscendingLb: ${isAscendingLb}, isAnonymousLb: ${isAnonymous}) {
                              totalPages
                              hackathonLeaderboardData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                privateScore
                                showCodeOnLb
                                isAvccMember
                              }
                              hackathonCurrentUserData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                privateScore
                                showCodeOnLb
                                isAvccMember
                              }
                            }
                        }`
                        if(islogin) {
                            if(csrf) {
                                setShowLoader(true);
                                getQueryDataElevateUser(pvtLeaderboardQuery,'newleaderboard',"True",csrf)
                                .then((data)=>{
                                    setPvtLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setUserApproachOnIndex(data.data.hackathonPrivateLeaderboard.hackathonLeaderboardData.map((obj)=>{return obj.submissionId}),index)
                                    setShowLoader(false);
                                console.log("Leaderboard Data: ", data?.data?.hackathonPrivateLeaderboard?.hackathonLeaderboardData);
                                console.log("Total Pages: ",data?.data?.hackathonPrivateLeaderboard?.totalPages);
                                })
                                .catch((error)=>{
                                    console.log(error);
                                    setShowLoader(false);
                                })
                            }
                        }   else {
                            setShowLoader(true);
                            getQueryData(pvtLeaderboardQuery,'newleaderboard')
                            .then((data)=>{
                                setPvtLeaderboardsData((prevState)=>{
                                    const newState = [...prevState];
                                    newState[index] = data.data;
                                    return newState;
                                });
                                setUserApproachOnIndex(data.data.hackathonPrivateLeaderboard.hackathonLeaderboardData.map((obj)=>{return obj.submissionId}),index)
                                setShowLoader(false);
                            console.log("Leaderboard Data: ", data?.data?.hackathonPrivateLeaderboard?.hackathonLeaderboardData);
                            console.log("Total Pages: ",data?.data?.hackathonPrivateLeaderboard?.totalPages);
                            })
                            .catch((error)=>{
                                console.log(error);
                                setShowLoader(false);
                            })
                        }
                    }
            }   else if(tab==='overall') {
                    let overallLeaderboardQuery = `
                    query  {
                        overallLeaderboard(tournamentId:${tournamentData.id}, page: ${page}, limit: 10, teamId: ${teamData ? teamData.id : null}) {
                          totalPages
                          overallLeaderboardData {
                            rank
                            userId
                            anonymousName
                            isAnonymous
                            teamName
                            score
                            isAvccMember
                          }
                          overallCurrentUserData {
                            rank
                            userId
                            anonymousName
                            isAnonymous
                            teamName
                            score
                            isAvccMember
                          }
                        }
                    }`
                    if(islogin) {
                        if(csrf) {
                            setShowLoader(true);
                            getQueryDataElevateUser(overallLeaderboardQuery, 'newleaderboard',"True",csrf)
                                .then((data) => {
                                    setOverallLeaderboard(data.data);
                                    setShowLoader(false);
                                    console.log("Overall Leaderboard Data:", data.data.overallLeaderboardData);
                                    console.log("Overall Current User Leaderboard Data:", data.data.overallLeaderboardData.overallCurrentUserData);
                                    console.log("Overall Total Pages:",data?.data?.overallLeaderboardData?.totalPages);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
                    }   else {
                        setShowLoader(true);
                        getQueryData(overallLeaderboardQuery, 'newleaderboard')
                            .then((data) => {
                                setOverallLeaderboard(data.data);
                                setShowLoader(false);
                                console.log("Overall Leaderboard Data:", data.data.overallLeaderboardData);
                                console.log("Overall Current User Leaderboard Data:", data.data.overallLeaderboardData.overallCurrentUserData);
                                console.log("Overall Total Pages:",data?.data?.overallLeaderboardData?.totalPages);
                            })
                            .catch((error) => {
                                console.log(error);
                                setShowLoader(false);
                            })
                        }
            }
        }
    }

    const privateLeaderboardSwitchHandler = () => {
        setShowPrivateLeaderboard(!showPrivateLeaderboard);
    }

    const navtabsClickHandler = (tab,index) => {
        let isAnonymous = tournamentData?.isAnonymous;
        setActiveTab(tab);
        setActiveIndex(index);
        if(tab === 'coderound') {
            setShowPrivateLeaderboard((!stagesData[index].stage.isActive && stagesData[index].stage.isPvtLbCreated) ? true : false);
        }

        if(tab === "overall") {
            if(tournamentData && !overallLeaderboard.overallLeaderboard) {
                if(islogin) {
                    if(teamData !== "") {
                        let overallLeaderboardQuery = `
                        query  {
                            overallLeaderboard(tournamentId:${tournamentData.id}, page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}) {
                              totalPages
                              overallLeaderboardData {
                                rank
                                userId
                                anonymousName
                                isAnonymous
                                teamName
                                score
                                isAvccMember
                              }
                              overallCurrentUserData {
                                rank
                                userId
                                anonymousName
                                isAnonymous
                                teamName
                                score
                                isAvccMember
                              }
                            }
                        }`
                        if(csrf) {
                            setShowLoader(true);
                            getQueryDataElevateUser(overallLeaderboardQuery, 'newleaderboard',"True",csrf)
                                .then((data) => {
                                    setOverallLeaderboard(data.data);
                                    setShowLoader(false);
                                    console.log("Overall Leaderboard Data:", data.data.overallLeaderboardData);
                                    console.log("Overall Current User Leaderboard Data:", data.data.overallLeaderboardData.overallCurrentUserData);
                                    console.log("Overall Total Pages:",data?.data?.overallLeaderboardData?.totalPages);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
                    }
                } else {
                    let overallLeaderboardQuery = `
                    query  {
                        overallLeaderboard(tournamentId:${tournamentData.id}, page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}) {
                          totalPages
                          overallLeaderboardData {
                            rank
                            userId
                            anonymousName
                            isAnonymous
                            teamName
                            score
                            isAvccMember
                          }
                          overallCurrentUserData {
                            rank
                            userId
                            anonymousName
                            isAnonymous
                            teamName
                            score
                            isAvccMember
                          }
                        }
                    }`
                    setShowLoader(false);
                    getQueryData(overallLeaderboardQuery, 'newleaderboard')
                        .then((data) => {
                            setOverallLeaderboard(data.data);
                            setShowLoader(false);
                            console.log("Overall Leaderboard Data:", data.data.overallLeaderboardData);
                            console.log("Overall Current User Leaderboard Data:", data.data.overallLeaderboardData.overallCurrentUserData);
                            console.log("Overall Total Pages:",data?.data?.overallLeaderboardData?.totalPages);
                        })
                        .catch((error) => {
                            console.log(error);
                            setShowLoader(false);
                        })
                }
            }
        }
        if(index>=0) {
            let stageId = stagesData[index]?.stage?.id;
    
            if(stagesData[index]?.stage?.stageType?.name === 'skill test') {
                let skillTestLeaderboardQuery = `
                query {
                    skillTestLeaderboard(stageId: "${stageId}", page: 1, limit: 10) {
                      totalPages
                      skilltestLeaderboardData {
                        rank
                        teamName
                        userId
                        score
                        accuracy
                        isAvccMember
                      }
                      skilltestCurrentUserData {
                        rank
                        score
                        teamName
                        userId
                        accuracy
                        isAvccMember
                      }
                    }
                }`
                if(!(leaderboardsData[index] && leaderboardsData[index].skillTestLeaderboard)) {
                    if(islogin) {
                        if(csrf) {
                            setShowLoader(true);
                            getQueryDataElevateUser(skillTestLeaderboardQuery, 'newleaderboard',"True",csrf)
                                .then((data) => {
                                    setLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setShowLoader(false);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
                    }   else {
                        setShowLoader(true);
                        getQueryData(skillTestLeaderboardQuery, 'newleaderboard')
                            .then((data) => {
                                setLeaderboardsData((prevState)=>{
                                    const newState = [...prevState];
                                    newState[index] = data.data;
                                    return newState;
                                });
                                setShowLoader(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                setShowLoader(false);
                            })
                    }
                }
            } else if(stagesData[index]?.stage?.stageType?.name === 'practice') {
                let isAscendingLb = stagesData[index]?.stage?.isAscendingLb;
                let query = `
                query {
                    hackathonPublicLeaderboard(stageId: "${stageId}", page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}, isAscendingLb: ${isAscendingLb}, isAnonymousLb: ${isAnonymous}) {
                      totalPages
                      hackathonLeaderboardData {
                        rank
                        userId
                        isAnonymous
                        anonymousName
                        submissionId
                        teamName
                        scoreHistory
                        publicScore
                        isAvccMember
                      }
                      hackathonCurrentUserData {
                        rank
                        userId
                        isAnonymous
                        anonymousName
                        submissionId
                        teamName
                        scoreHistory
                        publicScore
                        isAvccMember
                      }
                    }
                }`
                if(!(leaderboardsData[index] && leaderboardsData[index].hackathonPublicLeaderboard)) {
                    if(islogin) {
                        if(csrf && teamData !== "") {
                            setShowLoader(true);
                            getQueryDataElevateUser(query,'newleaderboard', "True", csrf)
                                .then((data)=>{
                                    setLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });
                                    setShowLoader(false);
                                    console.log("Leaderboard Data: ", data?.data?.hackathonPublicLeaderboard);
                                    console.log(data?.data?.hackathonPublicLeaderboard?.totalPages);
                                })
                                .catch((error)=>{
                                    console.log(error);
                                    setShowLoader(false);
                                })
                        }
                    }   else {
                        setShowLoader(true);
                        getQueryData(query,'newleaderboard')
                            .then((data)=>{
                                setLeaderboardsData((prevState)=>{
                                    const newState = [...prevState];
                                    newState[index] = data.data;
                                    return newState;
                                });
                                setShowLoader(false);
                                console.log("Leaderboard Data: ", data?.data?.hackathonPublicLeaderboard);
                                console.log(data?.data?.hackathonPublicLeaderboard?.totalPages);
                            })
                            .catch((error)=>{
                                console.log(error);
                                setShowLoader(false);
                            })
                    }
                }
                if(!(pvtLeaderboardsData[index] && pvtLeaderboardsData[index].hackathonPrivateLeaderboard)) {
                    if(!stagesData[index].stage.isActive && stagesData[index].stage.isPvtLbCreated) {
                        let pvtLeaderboardQuery = `
                        query {
                            hackathonPrivateLeaderboard(stageId: "${stageId}", page: 1, limit: 10, teamId: ${teamData ? teamData.id : null}, isAscendingLb: ${isAscendingLb}, isAnonymousLb: ${isAnonymous}) {
                            totalPages
                            hackathonLeaderboardData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                privateScore
                                showCodeOnLb
                                isAvccMember
                            }
                            hackathonCurrentUserData {
                                rank
                                userId
                                isAnonymous
                                anonymousName
                                submissionId
                                teamName
                                privateScore
                                showCodeOnLb
                                isAvccMember
                            }
                            }
                        }`
                        if(islogin) {
                            if(csrf && teamData !== "") {
                                setShowLoader(true);
                                getQueryDataElevateUser(pvtLeaderboardQuery,'newleaderboard',"True",csrf)
                                .then((data)=>{
                                    setPvtLeaderboardsData((prevState)=>{
                                        const newState = [...prevState];
                                        newState[index] = data.data;
                                        return newState;
                                    });                                    
                                    setUserApproachOnIndex(data.data.hackathonPrivateLeaderboard.hackathonLeaderboardData.map((obj)=>{return obj.submissionId}),index)

                                    setShowLoader(false);
                                console.log("Leaderboard Data: ", data?.data?.hackathonPrivateLeaderboard?.hackathonLeaderboardData);
                                console.log("Total Pages: ",data?.data?.hackathonPrivateLeaderboard?.totalPages);
                                })
                                .catch((error)=>{
                                    console.log(error);
                                    setShowLoader(false);
                                })
                            }
                        }   else {
                            setShowLoader(true);
                            getQueryData(pvtLeaderboardQuery,'newleaderboard')
                            .then((data)=>{
                                setPvtLeaderboardsData((prevState)=>{
                                    const newState = [...prevState];
                                    newState[index] = data.data;
                                    return newState;
                                });                                    
                                setUserApproachOnIndex(data.data.hackathonPrivateLeaderboard.hackathonLeaderboardData.map((obj)=>{return obj.submissionId}),index)

                                setShowLoader(false);
                            console.log("Leaderboard Data: ", data?.data?.hackathonPrivateLeaderboard?.hackathonLeaderboardData);
                            console.log("Total Pages: ",data?.data?.hackathonPrivateLeaderboard?.totalPages);
                            })
                            .catch((error)=>{
                                console.log(error);
                                setShowLoader(false);
                            })
                        }
                    }
                }
            }
        }
    }

    const codeDownloadHandler = (submissionId) => {
        if(!submissionId) {
          return;
        }
        let query = `
        query {
          codeFileUrl(submissionId: ${submissionId})
        }
        `
        getQueryData(query,'newcompetition')
        .then((data)=>{
          const codeFileUrl = data?.data?.codeFileUrl;
    
          if (codeFileUrl) {
            const link = document.createElement('a');
            link.href = codeFileUrl;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          } else {
            console.error('Code file URL not found in the response');
          }
    
        })
        .catch((error)=>{
          console.log(error);
        })
    }

    const renderAvccTooltip = (props) => (
        <Tooltip id="avcc-tooltip" {...props}>
          Analytics Vidhya Creators' Club Member
        </Tooltip>
    );

    const upsertApproach = () => {
        if(approachLink === ""){
            $(".warning").remove();
            $("#approachLink").after(`<small class="warning text-danger d-block">Please Enter approach url</small>`)
            setTimeout(() => {
                $(".warning").slideUp(500,()=>{
                    $(".warning").remove();
                })
            }, 3000);
            return false;
        }
        
        if(!isValidURL(approachLink)){
            $(".warning").remove();
            $("#approachLink").after(`<small class="warning text-danger d-block">Enter a valid approach url</small>`)
            setTimeout(() => {
                $(".warning").slideUp(500,()=>{
                    $(".warning").remove();
                })
            }, 3000);
            return false;
        }
        let query = `
            mutation MyMutation {
                upsertUserApproach(submissionId: "${pvtLeaderboardsData[activeIndex].hackathonPrivateLeaderboard.hackathonCurrentUserData?.submissionId}", userApproachLink: "${approachLink}",teamId: "${teamData ? teamData.id:""}") {
                    message
                    success
                }
            }
        `
        getQueryDataElevateUser(query,'newcompetition',"True",csrf)
        .then((data)=>{
            if(!data.data.upsertUserApproach.success){
                setErrorMessage(data.data.upsertUserApproach.message);
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                    setErrorMessage("");
                }, 3000);
            }
            else{
                window.$("#approachModal").modal("hide");
                setSuccessMessage(data.data.upsertUserApproach.message);
                setSuccess(true);
                setTimeout(()=>{
                    setSuccess(false);
                },[3000])
            }
        })
        .catch((error)=>{
            console.log(error);
            setErrorMessage(error.message);
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
                setErrorMessage("");
            }, 3000);
        })

    };

    return (
        <>
            {showLoader && <Loader />}
            <Header />
            {success && <SuccessAlert message={successMessage} />}
            {iserror && <ErrorAlert message={errorMessage} setIsError={setIsError} />}
            <section className='common-style background-dark-primary pb-5' id="leaderboard">
                <div className="container">
                    <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                        <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item text-dark-tertiary"><a href={`/datahack/contest/${hackathonSlug}`} className='text-dark-tertiary text-decoration-none'>Hackathon</a></li>
                        <li class="breadcrumb-item active text-white" aria-current="page">Leaderboard</li>
                        </ol>
                    </nav>
                    <div className='text-white heading-with-text mb-5'>
                        <h2 className='fs-32 fw-normal'>{tournamentData && tournamentData.title}</h2>
                    </div>
                    <ul class="nav nav-tabs px-2 py-3 rounded-2 av-tab-design overflow-auto flex-nowrap" id="hackathonDetailTab" role="tablist">
                    {stagesData && stagesData.length>1 && <li class="nav-item d-none" role="presentation">
                        <button class={`nav-link ${(activeIndex === -1)? 'active' : ''}`} id="overall-tab" data-bs-toggle="tab" data-bs-target="#overall" type="button" role="tab" aria-controls="overall" aria-selected={(activeIndex === -1) ? "true" : "false"} onClick={()=>{navtabsClickHandler('overall',-1)}}>Overall</button>
                    </li>}
                    {stagesData && stagesData.length>0 && stagesData.map((stage,index)=>(
                        (stage.stage.stageType.name === 'skill test') ?
                        <li class="nav-item" role="presentation" key={index}>
                            <button class={`nav-link text-nowrap ${(activeIndex === index)? 'active' : ''}`} id={`skilltest-tab${index}`} data-bs-toggle="tab" data-bs-target={`#skilltest${index}`} type="button" role="tab" aria-controls={`skilltest${index}`} aria-selected={(activeIndex === index) ? "true" : "false"} onClick={()=>{navtabsClickHandler('skilltest',index)}}>Round {index+1}</button>
                        </li>
                        :
                        <li class="nav-item" role="presentation" key={index}>
                            <button class={`nav-link text-nowrap ${(activeIndex === index)? 'active' : ''}`} id={`coderound-tab${index}`} data-bs-toggle="tab" data-bs-target={`#coderound${index}`} type="button" role="tab" aria-controls={`coderound${index}`} aria-selected={(activeIndex === index) ? "true" : "false"} onClick={()=>{navtabsClickHandler('coderound',index)}}>Round {index+1}</button>
                        </li>
                    ))}
                        {activeTab == "coderound" && <div className="private-leaderboard-switch d-flex align-items-center ms-auto">
                            <h2 className='text-white fs-20 m-0 text-nowrap'>Private leaderboard</h2>
                            <div className="form-check form-switch ms-3 mb-0">
                                <input className="form-check-input border-0 custom-control-input" type="checkbox" role="switch" id="priceCheck" checked={showPrivateLeaderboard} onChange={() => privateLeaderboardSwitchHandler()}/>
                            </div>
                        </div>}
                    </ul>
                    <div class="tab-content py-4" id="hackathonDetailContent">
                        {stagesData && stagesData.length>1 && <div class={`tab-pane fade ${(activeIndex === -1)? 'show active' : ''} d-none`} id="overall" role="tabpanel" aria-labelledby="overall-tab">
                            <div class="table-responsive my-3">
                                <table class="table text-white background-dark-secondary table-striped">
                                    <thead className='text-white border-bottom border-dark'>
                                        <td>Rank</td>
                                        <td>Username</td>
                                        <td>Score</td>
                                    </thead>
                                    {overallLeaderboard && overallLeaderboard.overallLeaderboard && overallLeaderboard.overallLeaderboard.overallCurrentUserData && 
                                        <tr className='active'>
                                            <td>#{overallLeaderboard.overallLeaderboard.overallCurrentUserData.rank}</td>
                                            <td className='row align-items-center px-2'>
                                                <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                    {overallLeaderboard && overallLeaderboard.overallLeaderboard && overallLeaderboard.overallLeaderboard.overallCurrentUserData  && overallLeaderboard.overallLeaderboard.overallCurrentUserData.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                        <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                        <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                        <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                        <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                        <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                        <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                        <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                        <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                        <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                        <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                        <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                        <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                        </svg></a>
                                                    </OverlayTrigger>}
                                                    <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{overallLeaderboard.overallLeaderboard.overallCurrentUserData?.username ? overallLeaderboard.overallLeaderboard.overallCurrentUserData?.username[0].toUpperCase() : (overallLeaderboard.overallLeaderboard.overallCurrentUserData?.anonymousName ? overallLeaderboard.overallLeaderboard.overallCurrentUserData?.anonymousName[0].toUpperCase() : (overallLeaderboard.overallLeaderboard.overallCurrentUserData?.teamName ? overallLeaderboard.overallLeaderboard.overallCurrentUserData?.teamName[0].toUpperCase() : ""))}</p>
                                                </div>
                                                <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                    <a href={overallLeaderboard.overallLeaderboard.overallCurrentUserData?.username ? `/profile/${overallLeaderboard.overallLeaderboard.overallCurrentUserData?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">You</a>
                                                </p>
                                            </td>
                                            <td>{(overallLeaderboard.overallLeaderboard.overallCurrentUserData.score % 1 !== 0) ? overallLeaderboard.overallLeaderboard.overallCurrentUserData.score?.toFixed(10) : overallLeaderboard.overallLeaderboard.overallCurrentUserData.score}</td>
                                        </tr>
                                    }
                                    {overallLeaderboard && overallLeaderboard.overallLeaderboard && overallLeaderboard.overallLeaderboard.overallLeaderboardData && overallLeaderboard.overallLeaderboard.overallLeaderboardData.length>0 && overallLeaderboard.overallLeaderboard.overallLeaderboardData.map((data,index)=>(
                                    <tr key={index}>
                                        <td>#{data.rank}</td>
                                        <td className='row align-items-center px-2'>
                                            <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                {data && data.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                    <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                    <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                    <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                    <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                    <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                    <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                    <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                    <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                    <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                    <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                    <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                    <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                    </svg></a>
                                                </OverlayTrigger>}
                                                <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{data?.username ? data?.username[0].toUpperCase() : (data?.anonymousName ? data?.anonymousName[0].toUpperCase() : (data?.teamName ? data?.teamName[0].toUpperCase() : ""))}</p>
                                            </div>
                                            <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                <a href={data?.username ? `/profile/${data?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">{data?.username ? data?.username : (data?.anonymousName ? data?.anonymousName : data?.teamName)}</a>
                                            </p>
                                        </td>
                                        <td>{(data.score % 1 !== 0) ? data.score?.toFixed(10) : data.score}</td>
                                    </tr>
                                    ))}
                                </table>
                            </div>
                            {tournamentData && overallLeaderboard && overallLeaderboard.overallLeaderboard && overallLeaderboard.overallLeaderboard.totalPages && <Pagination last_page={overallLeaderboard.overallLeaderboard.totalPages} updatePageNumber={(page)=>pageChangeHandler('overall',-1,page,tournamentData.id)}/>}
                        </div>}
                        {stagesData && stagesData.length>0 && stagesData.map((stage,index)=>(
                            (stage.stage.stageType.name === 'skill test') ?
                            <div class={`tab-pane fade ${(activeIndex === index)? 'show active' : ''}`} id={`skilltest${index}`} role="tabpanel" aria-labelledby={`skilltest-tab${index}`} key={index}>
                                <div class="table-responsive my-3">
                                    <table class="table text-white background-dark-secondary table-striped">
                                        <thead className='text-white border-bottom border-dark'>
                                            <td>Rank</td>
                                            <td>Username</td>
                                            <td>Score</td>
                                        </thead>
                                        {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].skillTestLeaderboard && leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData && 
                                            <tr className='active'>
                                            <td>#{leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData.rank}</td>
                                            <td className='row align-items-center px-2'>
                                                <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                    {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].skillTestLeaderboard && leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData && leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                        <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                        <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                        <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                        <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                        <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                        <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                        <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                        <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                        <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                        <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                        <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                        <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                        </svg></a>
                                                    </OverlayTrigger>}
                                                    <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData?.username ? leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData?.username[0].toUpperCase() : (leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData?.anonymousName ? leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData?.anonymousName[0].toUpperCase() : leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData?.teamName[0].toUpperCase())}</p>
                                                </div>
                                                <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                    <a href={leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData?.username ? `/profile/${leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">You</a>
                                                </p>
                                            </td>
                                            <td>{(leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData.score % 1 !== 0) ? leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData.score?.toFixed(3) : leaderboardsData[index].skillTestLeaderboard.skilltestCurrentUserData.score}</td>
                                            </tr>
                                        }
                                        {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].skillTestLeaderboard && leaderboardsData[index].skillTestLeaderboard.skilltestLeaderboardData && leaderboardsData[index].skillTestLeaderboard.skilltestLeaderboardData.length>0 && leaderboardsData[index].skillTestLeaderboard.skilltestLeaderboardData.map((data,index)=>(
                                            <tr>
                                            <td>#{data.rank}</td>
                                            <td className='row align-items-center px-2'>
                                                <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                    {data && data.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                        <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                        <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                        <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                        <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                        <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                        <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                        <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                        <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                        <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                        <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                        <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                        <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                        </svg></a>
                                                    </OverlayTrigger>}
                                                    <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{data?.username ? data?.username[0].toUpperCase() : (data?.anonymousName ? data?.anonymousName[0].toUpperCase() : (data?.teamName ? data?.teamName[0].toUpperCase() : ""))}</p>
                                                </div>
                                                <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                    <a href={data?.username ? `/profile/${data?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">{data?.username ? data?.username : (data?.anonymousName ? data?.anonymousName : data?.teamName)}</a>
                                                </p>
                                            </td>
                                            <td>{(data.score % 1 !== 0) ? data.score?.toFixed(3) : data.score}</td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                                {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].skillTestLeaderboard && leaderboardsData[index].skillTestLeaderboard.totalPages &&<Pagination last_page={leaderboardsData[index].skillTestLeaderboard.totalPages} updatePageNumber={(page)=>pageChangeHandler('skilltest',index,page,stage.stage.id)}/>}
                            </div> :
                            <div class={`tab-pane fade ${(activeIndex === index)? 'show active' : ''}`} id={`coderound${index}`} role="tabpanel" aria-labelledby={`coderound-tab${index}`} key={index}>
                                <div className={showPrivateLeaderboard && "d-none"}> 
                                    <div class="table-responsive my-3">
                                        <table class="table text-white background-dark-secondary table-striped">
                                            <thead className='text-white border-bottom border-dark'>
                                                <td>Rank</td>
                                                <td>Username</td>
                                                <td>Score</td>
                                                <td>Submission trend</td>
                                            </thead>
                                            {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].hackathonPublicLeaderboard && leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData && 
                                                <tr className='active'>
                                                    <td>#{leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.rank}</td>
                                                    <td className='row align-items-center px-2'>
                                                        <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                            {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].hackathonPublicLeaderboard && leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData && leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                                <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                                <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                                <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                                <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                                <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                                <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                                <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                                <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                                <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                                <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                                <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                                <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                                </svg></a>
                                                            </OverlayTrigger>}
                                                            <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.username ? leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.username[0].toUpperCase() : (leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.anonymousName ? leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.anonymousName[0].toUpperCase() : (leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.teamName ? leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.teamName[0].toUpperCase() : ""))}</p>
                                                        </div>
                                                        <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                            <a href={leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.username ? `/profile/${leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">You</a>
                                                        </p>
                                                    </td>
                                                    <td>{(leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.publicScore % 1 !== 0) ? leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.publicScore?.toFixed(10) : leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.publicScore}</td>
                                                    <td>
                                                        <Sparklines data={leaderboardsData[index].hackathonPublicLeaderboard.hackathonCurrentUserData?.scoreHistory || []} width={200} height={20}>
                                                            <svg>
                                                                <defs>
                                                                    <LinearGradientFill />
                                                                </defs>
                                                            </svg>
                                                            <SparklinesLine
                                                                style={{
                                                                    strokeWidth: 1,
                                                                    fill: 'url(#gradient)',
                                                                    stroke:'url(#gradient)'
                                                                }}
                                                            />
                                                        </Sparklines>
                                                    </td>
                                                </tr>
                                            }
                                            {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].hackathonPublicLeaderboard && leaderboardsData[index].hackathonPublicLeaderboard.hackathonLeaderboardData && leaderboardsData[index].hackathonPublicLeaderboard.hackathonLeaderboardData.length>0 && leaderboardsData[index].hackathonPublicLeaderboard.hackathonLeaderboardData.map((data,index)=>(
                                                <tr key={index}>
                                                    <td>#{data?.rank}</td>
                                                    <td className='row align-items-center px-2'>
                                                        <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                            {data && data.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                                <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                                <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                                <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                                <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                                <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                                <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                                <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                                <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                                <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                                <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                                <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                                <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                                </svg></a>
                                                            </OverlayTrigger>}
                                                            <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{data?.username ? data?.username[0].toUpperCase() : (data?.anonymousName ? data?.anonymousName[0].toUpperCase() : (data?.teamName ? data?.teamName[0].toUpperCase() : ""))}</p>
                                                        </div>
                                                        <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                            <a href={data?.username ? `/profile/${data?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">{data?.teamName ? data?.teamName : (data?.username ? data?.username : data?.anonymousName)}</a>
                                                        </p>
                                                    </td>
                                                    <td>{(data?.publicScore % 1 !== 0) ? data?.publicScore?.toFixed(10) : data?.publicScore}</td>
                                                    <td>
                                                        <Sparklines data={data?.scoreHistory || []} width={200} height={20}>
                                                            <svg>
                                                                <defs>
                                                                    <LinearGradientFill />
                                                                </defs>
                                                            </svg>
                                                            <SparklinesLine
                                                                style={{
                                                                    strokeWidth: 1,
                                                                    fill: 'url(#gradient)',
                                                                    stroke:'url(#gradient)'
                                                                }}
                                                            />
                                                        </Sparklines>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                    {leaderboardsData && leaderboardsData[index] && leaderboardsData[index].hackathonPublicLeaderboard && leaderboardsData[index].hackathonPublicLeaderboard.totalPages && <Pagination last_page={leaderboardsData[index].hackathonPublicLeaderboard.totalPages} updatePageNumber={(page)=>pageChangeHandler('coderound',index,page,stage.stage.id,stage.stage?.isAscendingLb)}/>}
                                </div>
                                <div className={!showPrivateLeaderboard && 'd-none'}>
                                    {pvtLeaderboardsData && pvtLeaderboardsData[index] && pvtLeaderboardsData[index].hackathonPrivateLeaderboard && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonLeaderboardData && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonLeaderboardData.length>0 ? 
                                    <div class="table-responsive my-3">
                                        <table class="table text-white background-dark-secondary table-striped">
                                            <thead className='text-white border-bottom border-dark'>
                                                <td>Rank</td>
                                                <td>Username</td>
                                                <td>Score</td>
                                                <td>Participant's code</td>
                                                <td>Participant's approach</td>
                                            </thead>
                                            {pvtLeaderboardsData && pvtLeaderboardsData[index] && pvtLeaderboardsData[index].hackathonPrivateLeaderboard && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData && 
                                                <tr className='active'>
                                                    <td>#{pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.rank}</td>
                                                    <td className='row align-items-center px-2'>
                                                        <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                            {pvtLeaderboardsData && pvtLeaderboardsData[index] && pvtLeaderboardsData[index].hackathonPrivateLeaderboard && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                                <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                                <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                                <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                                <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                                <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                                <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                                <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                                <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                                <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                                <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                                <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                                <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                                </svg></a>
                                                            </OverlayTrigger>}
                                                            <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.username ? pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.username[0].toUpperCase() : (pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.anonymousName ? pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.anonymousName[0].toUpperCase() : (pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.teamName ? pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.teamName[0].toUpperCase() : ""))}</p>
                                                        </div>
                                                        <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                            <a href={pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.username ? `/profile/${pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">You</a>
                                                        </p>
                                                    </td>
                                                    <td>{(pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.privateScore % 1 !== 0) ? pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.privateScore?.toFixed(10) : pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.privateScore}</td>
                                                    <td>
                                                        {pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.showCodeOnLb && <button className='btn bg-transparent text-white border-0' onClick={()=>codeDownloadHandler(pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonCurrentUserData?.submissionId)}>
                                                            Download
                                                            <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path d="M15.75 11.25V12.15C15.75 13.4101 15.75 14.0402 15.5048 14.5215C15.289 14.9448 14.9448 15.289 14.5215 15.5048C14.0402 15.75 13.4101 15.75 12.15 15.75H5.85C4.58988 15.75 3.95982 15.75 3.47852 15.5048C3.05516 15.289 2.71095 14.9448 2.49524 14.5215C2.25 14.0402 2.25 13.4101 2.25 12.15V11.25M12.75 7.5L9 11.25M9 11.25L5.25 7.5M9 11.25V2.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </button>}
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-dark btn-dark-primary text-white' data-bs-toggle="modal" data-bs-target="#approachModal">{approachLink?"Edit":"Add"} approach</button>
                                                    </td>
                                                </tr>
                                            }
                                            {pvtLeaderboardsData && pvtLeaderboardsData[index] && pvtLeaderboardsData[index].hackathonPrivateLeaderboard && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonLeaderboardData && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonLeaderboardData.length>0 && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.hackathonLeaderboardData.map((data,userindex)=>(
                                                <tr key={userindex}>
                                                    <td>#{data?.rank}</td>
                                                    <td className='row align-items-center px-2'>
                                                        <div className="leaderboard-avtar-wrapper rounded-circle col-auto p-0 me-2 position-relative">
                                                            {data && data.isAvccMember && <OverlayTrigger overlay={renderAvccTooltip}>
                                                                <a href="https://www.analyticsvidhya.com/creators-club/" target="_blank">
                                                                <svg className='p-0 position-absolute' width="33" height="20" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M45.934 40H6.90835C6.6101 40 6.35375 39.788 6.29734 39.4952L2.91872 21.9206L1.47519 15.5437C1.46896 15.5163 1.46481 15.4885 1.46233 15.4612L0.015473 9.06481C-0.0372077 8.83294 0.0469984 8.59152 0.232003 8.4426C0.416593 8.29369 0.670871 8.26258 0.886157 8.36379L8.96248 12.1464C9.13297 12.2265 9.25824 12.3796 9.30263 12.5625C9.34743 12.7454 9.30677 12.9387 9.19187 13.0885L6.46285 16.6492L16.4826 21.0466L22.2994 16.4816L19.5194 11.7565C19.4049 11.5615 19.4049 11.3201 19.5194 11.1256L25.885 0.306544C25.997 0.116561 26.2011 0 26.4214 0C26.6416 0 26.8457 0.116561 26.9577 0.306544L33.3225 11.1256C33.437 11.3205 33.437 11.562 33.3225 11.7565L30.5425 16.4816L36.3594 21.0474L46.3795 16.6492L43.65 13.0885C43.5355 12.9391 43.4945 12.7454 43.5393 12.5625C43.5837 12.3796 43.7089 12.2265 43.8794 12.1464L51.9558 8.36379C52.1715 8.26299 52.4253 8.2941 52.6099 8.4426C52.7949 8.59152 52.8791 8.83294 52.8264 9.06481L51.3804 15.462C51.3779 15.4894 51.3734 15.5167 51.3676 15.5437L49.9174 21.9537L46.545 39.4952C46.4886 39.788 46.2322 40 45.934 40ZM7.4223 38.7556H45.42L48.6995 21.6991L50.1343 15.3541C50.1368 15.3268 50.1414 15.2998 50.1472 15.2724L51.3327 10.0301L45.1085 12.9453L47.8496 16.5218C47.9657 16.6737 48.0059 16.8703 47.9587 17.0553C47.911 17.2403 47.7807 17.3933 47.6057 17.4701L36.5186 22.3366C36.3088 22.4283 36.0648 22.398 35.8848 22.2566L29.3466 17.125C29.1014 16.9325 29.0363 16.5886 29.1944 16.3198L32.0648 11.4413L26.4218 1.84922L20.7783 11.4413L23.6488 16.3198C23.8068 16.5882 23.7417 16.9325 23.4966 17.125L16.9583 22.2557C16.7779 22.3976 16.5344 22.4279 16.3245 22.3362L5.23709 17.4701C5.06204 17.3933 4.9322 17.2403 4.88408 17.0553C4.8368 16.8703 4.87662 16.6737 4.99318 16.5218L7.73382 12.9453L1.50961 10.0301L2.69514 15.2724C2.70136 15.2998 2.70551 15.3276 2.708 15.355L4.13577 21.6659L7.4223 38.7556Z" fill="white"/>
                                                                <path d="M26.4144 36.643C22.4725 36.643 19.2656 33.4357 19.2656 29.493C19.2656 25.5506 22.4725 22.3438 26.4144 22.3438C30.3568 22.3438 33.5641 25.5506 33.5641 29.493C33.5641 33.4353 30.3568 36.643 26.4144 36.643ZM26.4144 23.5878C23.1586 23.5878 20.5101 26.2367 20.5101 29.4926C20.5101 32.7492 23.159 35.3982 26.4144 35.3982C29.6707 35.3982 32.3197 32.7488 32.3197 29.4926C32.3197 26.2367 29.6707 23.5878 26.4144 23.5878Z" fill="white"/>
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1245 30.1233H32.9419C33.2853 30.1233 33.5645 29.8446 33.5645 29.5011C33.5645 29.1577 33.2857 28.8789 32.9423 28.8789H29.1202C29.1675 29.0837 29.1918 29.2954 29.1918 29.5109C29.1918 29.7196 29.169 29.9246 29.1245 30.1233ZM25.0102 28.8789H27.8123C27.9008 29.0748 27.9474 29.2891 27.9474 29.5105C27.9474 29.7249 27.9036 29.9326 27.8204 30.1233H25.0019C24.9189 29.9326 24.8753 29.7249 24.8753 29.5105C24.8753 29.2891 24.9218 29.0748 25.0102 28.8789ZM23.7024 28.8789H19.8878C19.5444 28.8789 19.2656 29.1577 19.2656 29.5011C19.2656 29.8446 19.5444 30.1233 19.8878 30.1233H23.6982C23.6537 29.9245 23.6309 29.7193 23.6309 29.5105C23.6309 29.2952 23.6551 29.0836 23.7024 28.8789Z" fill="white"/>
                                                                <path d="M26.4171 39.4885C26.0737 39.4885 25.7949 39.2098 25.7949 38.8663V36.0128C25.7949 35.6694 26.0737 35.3906 26.4171 35.3906C26.7606 35.3906 27.0393 35.6694 27.0393 36.0128V38.8663C27.0393 39.2098 26.7606 39.4885 26.4171 39.4885Z" fill="white"/>
                                                                <path d="M18.1522 36.6351H17.1749C16.8315 36.6351 16.5527 36.3563 16.5527 36.0128C16.5527 35.6694 16.8315 35.3906 17.1749 35.3906H18.1522C18.4957 35.3906 18.7745 35.6694 18.7745 36.0128C18.7745 36.3563 18.4961 36.6351 18.1522 36.6351Z" fill="white"/>
                                                                <path d="M15.1312 36.6351H14.1535C13.81 36.6351 13.5312 36.3563 13.5312 36.0128C13.5312 35.6694 13.81 35.3906 14.1535 35.3906H15.1312C15.4746 35.3906 15.7534 35.6694 15.7534 36.0128C15.7534 36.3563 15.4746 36.6351 15.1312 36.6351Z" fill="white"/>
                                                                <path d="M12.1159 36.6351H6.5226C6.17914 36.6351 5.90039 36.3563 5.90039 36.0128C5.90039 35.6694 6.17914 35.3906 6.5226 35.3906H12.1155C12.4589 35.3906 12.7377 35.6694 12.7377 36.0128C12.7377 36.3563 12.4593 36.6351 12.1159 36.6351Z" fill="white"/>
                                                                <path d="M19.966 36.6272C19.8038 36.6272 19.6424 36.5608 19.5259 36.4447C19.4101 36.3289 19.3438 36.1667 19.3438 36.005C19.3438 35.839 19.4101 35.6814 19.5259 35.5648C19.7586 35.3326 20.1734 35.3326 20.4052 35.5648C20.5214 35.681 20.5878 35.8432 20.5878 36.005C20.5878 36.1667 20.5214 36.3285 20.4052 36.4447C20.2895 36.5608 20.1315 36.6272 19.966 36.6272Z" fill="white"/>
                                                                <path d="M35.6737 36.6351H34.6964C34.353 36.6351 34.0742 36.3563 34.0742 36.0128C34.0742 35.6694 34.353 35.3906 34.6964 35.3906H35.6737C36.0172 35.3906 36.2959 35.6694 36.2959 36.0128C36.2959 36.3563 36.0172 36.6351 35.6737 36.6351Z" fill="white"/>
                                                                <path d="M38.6839 36.6351H37.7062C37.3627 36.6351 37.084 36.3563 37.084 36.0128C37.084 35.6694 37.3627 35.3906 37.7062 35.3906H38.6839C39.0274 35.3906 39.3061 35.6694 39.3061 36.0128C39.3061 36.3563 39.0278 36.6351 38.6839 36.6351Z" fill="white"/>
                                                                <path d="M46.3205 36.6351H40.7277C40.3842 36.6351 40.1055 36.3563 40.1055 36.0128C40.1055 35.6694 40.3842 35.3906 40.7277 35.3906H46.3205C46.664 35.3906 46.9428 35.6694 46.9428 36.0128C46.9428 36.3563 46.664 36.6351 46.3205 36.6351Z" fill="white"/>
                                                                <path d="M32.8644 36.6272C32.7022 36.6272 32.5408 36.5608 32.4243 36.4447C32.3086 36.3289 32.2422 36.1667 32.2422 36.005C32.2422 35.839 32.3086 35.6814 32.4243 35.5648C32.657 35.3326 33.0718 35.3326 33.3037 35.5648C33.4198 35.681 33.4862 35.8432 33.4862 36.005C33.4862 36.1667 33.4198 36.3285 33.3037 36.4447C33.1875 36.5608 33.0262 36.6272 32.8644 36.6272Z" fill="white"/>
                                                                </svg></a>
                                                            </OverlayTrigger>}
                                                            <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{data?.username ? data?.username[0].toUpperCase() : (data?.anonymousName ? data?.anonymousName[0].toUpperCase() : (data?.teamName ? data?.teamName[0].toUpperCase() : ""))}</p>
                                                        </div>
                                                        <p className='bg-transparent text-white col mb-0 text-truncate'>
                                                            <a href={data?.username ? `/profile/${data?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">{data?.teamName ? data?.teamName : (data?.username ? data?.username : data?.anonymousName)}</a>
                                                        </p>
                                                    </td>
                                                    <td>{(data?.privateScore % 1 !== 0) ? data?.privateScore?.toFixed(10) : data?.privateScore}</td>
                                                    <td>
                                                        {data?.showCodeOnLb && <button className='btn bg-transparent text-white border-0' onClick={()=>codeDownloadHandler(data?.submissionId)}>
                                                            Download
                                                            <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path d="M15.75 11.25V12.15C15.75 13.4101 15.75 14.0402 15.5048 14.5215C15.289 14.9448 14.9448 15.289 14.5215 15.5048C14.0402 15.75 13.4101 15.75 12.15 15.75H5.85C4.58988 15.75 3.95982 15.75 3.47852 15.5048C3.05516 15.289 2.71095 14.9448 2.49524 14.5215C2.25 14.0402 2.25 13.4101 2.25 12.15V11.25M12.75 7.5L9 11.25M9 11.25L5.25 7.5M9 11.25V2.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </button>}
                                                    </td>
                                                    <td>
                                                        {data?.username && (data?.username === user_data.username) ? (<button className='btn btn-dark btn-dark-primary text-white' data-bs-toggle="modal" data-bs-target="#approachModal">{approachLink?"Edit":"Add"} approach</button>):
                                                        <>{userApproachLinkList[index] && userApproachLinkList[index][`user${userindex}`] && <a href={userApproachLinkList[index][`user${userindex}`]} target='_blank' className='text-white bg-transparent'>View</a>}</>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div> : <p className='text-dark-secondary'>Private leaderboard is not published yet. Please try once competition has ended.</p>}
                                    {pvtLeaderboardsData && pvtLeaderboardsData[index] && pvtLeaderboardsData[index].hackathonPrivateLeaderboard && pvtLeaderboardsData[index].hackathonPrivateLeaderboard.totalPages && <Pagination last_page={pvtLeaderboardsData[index].hackathonPrivateLeaderboard.totalPages} updatePageNumber={(page)=>pageChangeHandler('coderound',index,page,stage.stage.id,stage.stage?.isAscendingLb)}/>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <div className="modal lead-modal" data-bs-backdrop="static" id='approachModal' tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content background-dark-primary shadow-sm rounded-4">
                    <div className="modal-header border-0 p-0">
                    <h2 className='text-white fs-32'>{approachLink?"Edit":"Add"} approach</h2>
                    <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </button>
                    </div>
                    <div className="modal-body">
                        <div class="mb-5">
                            <label for="approachLink" class="form-label text-white fs-14 fw-normal">Approach Url</label>
                            <input type="text" class="form-control background-dark-tertiary text-white" id="approachLink" placeholder="Enter Approach Url" value={approachLink} onChange={(e)=>{setApproachLink(e.target.value)}} autoComplete='off'/>
                        </div>
                    </div>
                    <div className="modal-footer border-0 p-0">
                        <button id="addAndUpdateApproach" className="btn btn-dark btn-dark-primary rounded-3 fs-16" onClick={upsertApproach}>{approachLink?"Update":"Add"} Approach</button>
                    </div>
                </div>
                </div>
            </div>
            <Footer platform={"newcompetition_leaderboard"}/>
            <Cookies platform={'newcompetition'}  />
            <Flashstrip />
        </>
    )
}


const LinearGradientFill = stopColor => {
	return (
		<linearGradient id="gradient" x1="100.251" y1="3.84746" x2="100.139" y2="24.9994" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D22D1E"/>
            <stop offset="0.523988" stopColor="#963AB1"/>
            <stop offset="1" stopColor="#20469B"/>
            <stop offset="1" stopColor="#20469B"/>
		</linearGradient>
	);
};

const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
    tournamentData:state.hackathonContentReducer.tournamentData,
    stagesData:state.hackathonContentReducer.stagesData,
    isUserRegistered:state.hackathonContentReducer.isUserRegistered,
    user_data: state.LoginModalReducer.userData,
  });
  
  const mapDispatchToProps = dispatch=>({
    getHackathonDetailDataHandler:data=>dispatch(getHackathonDetailData(data)),
    getHackathonDetailRegistrationDataHandler:data=>dispatch(getHackathonDetailRegistrationData(data)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);