import intlTelInput from 'intl-tel-input';
import $ from 'jquery';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { fetchBlogContentSuccess, fetchFreeCourseContent } from '../../../../../store/Actions/Action';
import { fetchBlogData, scrollTop, submitRegisterationForm } from '../../../../../utils';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { getCSRF } from '../../../../../utils/Login';
import ErrorAlert from '../../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import Cookies from '../../../../common/Footer/Cookies';
import Footer from '../../../../common/Footer/Footer';
import Flashstrip from '../../../../common/Header/Flashstrip';
import Title from '../../../../common/Header/Title';
import FreeCoursesHeader from '../components/FreeCoursesHeader';
import LeadModal from '../components/LeadModal';
const CourseSwiperComponent1 = React.lazy(() => import("../components/CourseSwiperComponent1"));
const CourseSwiperComponent2 = React.lazy(() => import("../components/CourseSwiperComponent2"));
const CourseSwiperComponent3 = React.lazy(() => import("../components/CourseSwiperComponent3"));
const CourseSwiperComponent4 = React.lazy(() => import("../components/CourseSwiperComponent4"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
var iti1 = null;

const free_courses_list = eval(process.env.REACT_APP_FREE_COURSES_LIST)

function BuildingLanguageModelsForCode({blogContent,fetchBlogContentSuccessHandler,country_name,freecoursecontent,fetchFreeCourseContentHandler}) {
  const [formname,setFormname] = useState(null);
  const [successMessage,setSuccessMessage] = useState('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
  const [leadFormHeading,setLeadFormHeading] = useState(null);
  const [leadFormCta,setLeadFormCta] = useState(null);
  const [section,setSection] = useState(null);
  const [popup,setPopup] = useState(false);  
  const [csrf, setCsrf] = useState('');
  const [success,setSuccess] = useState(false);
  const [iserror,setIsError] = useState(false);
  const { platformName } = useParams();
  const navigate = useNavigate();
  const [contact,setContact] = useState({
    fullname:"",
    phone:"",
    email:""
  });
  const recaptchaRef = useRef(null); 
  const [captcha, setCaptcha] = useState(false);

  useEffect(()=>{
    if(!free_courses_list.includes(platformName)){
      navigate('/');
    }
  },[]);

  useEffect(() => {
    if(!freecoursecontent){
      fetchFreeCourseContentHandler(platformName);
    }
    scrollTop();
  }, [fetchFreeCourseContentHandler]);

  useEffect(()=>{
    console.log(freecoursecontent);
  },[freecoursecontent]);

  useEffect(() => { 
    // Function calling for getting blog data for homepage
    if(!blogContent){
      fetchBlogData(process.env.REACT_APP_MARKETING_PAGES,'newmarketingpages')
      .then((data)=>{
        fetchBlogContentSuccessHandler(data);
      })
      .catch((error)=>{
        console.log(error.message);
      })
    }
  }, []);

  useEffect(()=>{
    $(document).ready(function() {
      $(window).scroll(function() {
        if($('#stickyHeader')){
          var targetOffset = $('#stickyHeader').offset().top;
          var windowScroll = $(this).scrollTop();
    
          if (windowScroll >= targetOffset) {
            $('#stickyHeader').addClass('active');
          }
          else{
            $('#stickyHeader').removeClass('active');
          }
        }
      });
    });
  },[])
  
  useEffect(()=>{
    getCSRF()
    .then((data)=>{
      setCsrf(data);
      console.log(data);
    })
    .catch((error)=>{
      console.log(error);
    })
  },[])

  useEffect(()=>{
    const input = document.getElementById("contactPhoneNumber");
    if(input){
      iti1 = intlTelInput(input, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry: country_name?country_name:"IN",
        preferredCountries: ["in", "us"],
      });
    }
  },[])

  useEffect(()=>{
    $('a[href*="#"]:not([href="#"])').click(function(e) {
      addActiveClass(e);
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 100);
        return false;
        }
      }
    });

    $('.scrolling-method[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 100
        }, 100);
        return false;
        }
      }
    });   
  },[]);

  let openCoursesCard = ()=>{
    $('.course-points').toggleClass('active');
    if($('#courseCardBtn').text() == 'Show more'){
      $('#courseCardBtn').text('Show less');
    }
    else{
      $('#courseCardBtn').text('Show more');
    }
  }

  const updateLeadForm = (formname,heading,cta,section)=>{
    setFormname(formname);
    setLeadFormHeading(heading);
    setLeadFormCta(cta);
    setSection(section);
  }

  const saveContactForm = (e)=>{
    e.preventDefault();
    let country_code = '+'+iti1.getSelectedCountryData().dialCode;
    if(contact.fullname === ''){
        $('.error').remove();
        $('#contactFullName').parent().append(`<small class="text-danger error d-block">Please Enter Your Full Name</small>`);
        $('#contactFullName').css('border-color','red');
        $('#contactFullName').css('color','red');
        setTimeout(()=>{
            $('#contactFullName').css('border-color','#383838');
            $('#contactFullName').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }
    if(!validatedMobile(contact.phone)){
      $('.error').remove();
      $('#contactPhoneNumber').closest('.col-md-12').append(`<small class="text-danger error d-block">Enter a Valid Phone Number</small>`);
      $('#contactPhoneNumber').css('border-color','red');
      $('#contactPhoneNumber').css('color','red');
      setTimeout(()=>{
          $('#contactPhoneNumber').css('border-color','#383838');
          $('#contactPhoneNumber').css('color','white');
          $('.error').slideUp();
          $('.error').remove();
      },2000)
      return false;
  }
    if(!validatedEmail(contact.email)){
      $('.error').remove();
      $('#contactEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Email Id</small>`);
      $('#contactEmail').css('border-color','red');
      $('#contactEmail').css('color','red');
      setTimeout(()=>{
          $('#contactEmail').css('border-color','#383838');
          $('#contactEmail').css('color','white');
          $('.error').slideUp();
          $('.error').remove();
      },2000)
      return false;
  }
  

  if(!captcha){
    setIsError(true);
        setTimeout(() => {
          setIsError(false);
    }, 2000);
    return false;
  }
  
    let user_data = {
        "fullname":contact.fullname,
        "email":contact.email,
        "phone":contact.phone,
        "country_code":country_code,
    }
    let additional_detail ={
      "tnc":true,
      "is_whatsapp":true,
      "page_url":window.location.href,
      "section":"contact"
    }
    setContact({
      fullname:'',
      email:'',
      phone:'',
    });
    recaptchaRef.current?.reset();
    setCaptcha(false);
    $('#contact1').prop('checked',true);
    setSuccess(true);
    setTimeout(()=>{
      setSuccess(false);
    },4000)
    submitRegisterationForm(user_data,additional_detail,platformName,"contact",csrf)
    .then((data)=>{
    })
    .catch((error)=>{
      console.log(error)
      // setIsError(true);
      // setTimeout(()=>{
      //   setIsError(false);
      // },4000)
    })
  }

  const addActiveClass = (e)=>{
    $('#stickyHeader a').removeClass('active');
    $(e.target).addClass('active');
  }


  const gradientPositionChecker = ()=>{
    if(freecoursecontent.heroFold.heading.gradientPosition.left){
      return(
      <>
        <span className='text-gradient'>{freecoursecontent.heroFold.heading.gradientHeading.gradientText+" "}</span>
        <span className='text-white'>{freecoursecontent.heroFold.heading.gradientHeading.rightText}</span>
      </>);
    }
    else if(freecoursecontent.heroFold.heading.gradientPosition.middle){
      return(
        <>
          <span className='text-white'>{freecoursecontent.heroFold.heading.gradientHeading.leftText+" "}</span>
          <span className='text-gradient'>{freecoursecontent.heroFold.heading.gradientHeading.gradientText+" "}</span>
          <span className='text-white'>{freecoursecontent.heroFold.heading.gradientHeading.rightText}</span>
        </>);
    }
    else if(freecoursecontent.heroFold.heading.gradientPosition.right){
      return(
        <>
          <span className='text-white'>{freecoursecontent.heroFold.heading.gradientHeading.leftText+" "}</span>
          <span className='text-gradient'>{freecoursecontent.heroFold.heading.gradientHeading.gradientText}</span>
        </>);
    }
    else{
      return(
        <>
          <span className='text-white'>{freecoursecontent.heroFold.heading.gradientHeading.leftText+" "}</span>
          <span className='text-gradient'>{freecoursecontent.heroFold.heading.gradientHeading.gradientText}</span>
        </>);
    }

  }

  function onChange(value) {
    setCaptcha(true);
  }

  return (
  <main className='background-dark-primary'>
    <FreeCoursesHeader islogin={false} />
    <Title title={'Introduction to AI & Machine Learning: Master the Fundamentals '} description={'Embark on a certified Data Science journey with our online training program. Acquire sought-after skills in Python, AI, and ML. Enroll today and chart your path to becoming a certified Data Scientist.'} image_og={cloudflare_size_url+'blackbelt/bb_og_image.jpg?format=avif'} title_og={'Analytics Vidhya | The ultimate place for Generative AI, Data Science and Data Engineering'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
    <LeadModal formname={formname} setSuccess={setSuccess} setIsError={setIsError} country_name={country_name} setSuccessMessage={setSuccessMessage} leadFormCta={leadFormCta} leadFormHeading={leadFormHeading} section={section} setPopup={setPopup} csrf={csrf} setCsrf={setCsrf} platformname={platformName} course_url={"https://courses.analyticsvidhya.com/courses/introduction-to-ai-ml"} />
    {iserror && <ErrorAlert message={"Your details Could not be submitted. please try again later sometimes."} setIsError={setIsError} />}
    {success && <SuccessAlert message={successMessage} />}
    {freecoursecontent && <section id="heroFold" className='background-dark-primary'>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12">
            {/* <nav className='pt-5 mb-3' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item text-dark-tertiary">All Courses</li>
                <li class="breadcrumb-item text-dark-tertiary">Deep learning</li>
                <li class="breadcrumb-item active text-white" aria-current="page">Introduction to AI & ML</li>
              </ol>
            </nav> */}
            <h1 className="fs-56  mb-4 fw-semibold mt-5">
                {gradientPositionChecker()}
                {/* <span className='text-white'>Introduction to </span><span className='text-gradient'>AI & ML</span> */}
            </h1>
            <p className='fs-16 text-dark-secondary mb-5'>{freecoursecontent.heroFold.heading.subheading}</p>
            <ul className='list-style-none d-flex flex-wrap flex-column flex-md-row'>
              {freecoursecontent.heroFold.heroFoldPoints.map((point, index) => (
                <li key={index} className={`fs-16 d-flex align-items-center ${index !== 0 ? 'ms-0 ms-md-4' : ''} mb-3`}>
                  <img className='img-fluid' src={cloudflare_url + point.icon} />
                  <p className='text-white m-0 ms-2'>{point.text}</p>
                </li>
              ))}
            </ul>
            <div className="d-flex align-items-center flex-wrap">
              <svg className='' xmlns="http://www.w3.org/2000/svg" width="10" height="51" viewBox="0 0 10 51" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0.835938C0.895431 0.835938 0 1.73137 0 2.83594V48.8359C0 49.9405 0.895431 50.8359 2 50.8359H2.44444C3.54901 50.8359 4.44444 49.9405 4.44444 48.8359V2.83594C4.44444 1.73137 3.54901 0.835938 2.44444 0.835938H2ZM4.44661 2.7002C4.44661 2.7002 10.0013 22.8359 10.0022 27.1547C10.0022 33.3359 4.44661 49.0312 4.44661 49.0312V2.7002Z" fill="#2674ED"/>
              </svg>
              <div className="col-auto ps-4 border border-start-0 border-top-0 border-bottom-0 border-dark pe-3 pe-md-5">
                <h2 className='fs-20 text-white fw-semibold'>{freecoursecontent.heroFold.studentsEnrolled.number}</h2>
                <p className='fs-14 text-white mb-0'>{freecoursecontent.heroFold.studentsEnrolled.text}</p>
              </div>
              <div className="col-auto ps-3 ps-md-5 pe-4">
                <h2 className='fs-20 text-white fw-semibold d-flex align-items-center'>
                  <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                    <path d="M10 0.835938L12.2451 7.74577H19.5106L13.6327 12.0163L15.8779 18.9261L10 14.6556L4.12215 18.9261L6.36729 12.0163L0.489435 7.74577H7.75486L10 0.835938Z" fill="#F9C823"/>
                  </svg>
                  {freecoursecontent.heroFold.ratings.number}
                </h2>
                <p className='fs-14 text-white mb-0'>{freecoursecontent.heroFold.ratings.text}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 d-flex align-items-center">
            <div className="hero-fold-image-wrapper pb-5">
              <img className='img-fluid rounded-2' src={cloudflare_size_url+freecoursecontent.heroFold.heroImage} alt="hero fold image" />                
              <button className="btn btn-dark btn-dark-primary rounded-3 w-100 mt-3" aria-label="Enroll now" data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('enroll','Discover the Path to Innovation',"Enroll Now","hero-fold")}}>Enroll For Free</button>
            </div>
          </div>
        </div>
      </div>
    </section>}
    <section id="stickyHeader" className='background-dark-primary sticky-top top-0 z-3'>
      <div className="container">
        <ul className='m-0 list-style-none d-flex rounded-2 py-4 background-dark-secondary overflow-auto'>
          <li className='px-3'><a className='text-dark-secondary text-decoration-none text-nowrap active scrolling-method' href="#about">About this Course</a></li>
          <li className='px-3'><a className='text-dark-secondary text-decoration-none text-nowrap' href="#learningOutcomes">Learning Outcomes</a></li>
          <li className='px-3'><a className='text-dark-secondary text-decoration-none text-nowrap' href="#courseHighlights">Course Highlights</a></li>
          <li className='px-3'><a className='text-dark-secondary text-decoration-none text-nowrap' href="#courseCurriculum">Course Curriculum</a></li>
          <li className='px-3 d-none'><a className='text-dark-secondary text-decoration-none text-nowrap' href="#projects">Projects</a></li>
          <li className='px-3 d-none'><a className='text-dark-secondary text-decoration-none text-nowrap' href="#meetTheInstructor">Instructors</a></li>
        </ul>
      </div>
    </section>
    <Suspense fallback={<div>Loading</div>}>
        <CourseSwiperComponent1 openCoursesCard={openCoursesCard} updateLeadForm={updateLeadForm} />
    </Suspense>
    {freecoursecontent && <section className='background-dark-secondary py-5' id='certification'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <div className='text-white heading-with-text mb-5'>
                <h2 className='fs-40 fw-normal mb-4'>{freecoursecontent.certificateCompletion.heading}</h2>
                <p className='text-dark-secondary fs-18'>{freecoursecontent.certificateCompletion.subheading}</p>
              </div>
              <ul className='text-white'>
                {freecoursecontent.certificateCompletion.bulletPoints.map((item,index)=>{
                  return (
                    <li className='fs-18 mb-4' key={index}>{item}</li>
                  )
                })}
              </ul>
              <button className="btn btn-dark btn-dark-primary rounded-3 mt-3 btn-desktop" aria-label="Enroll now" data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll for Free',"Enroll Now","certificate")}}>Enroll For Free</button>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 certificate-image pb-5'>
              <div className="box-annimation z-3 mx-auto">
                <img src={cloudflare_size_url+freecoursecontent.certificateCompletion.image} className="img-fluid d-block rounded-2 mx-auto" />
              </div>
            </div>
            <div className="container">
              <button className="btn btn-dark btn-dark-primary rounded-3 mt-3 btn-mobile w-100" aria-label="Enroll now" data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll for Free',"Enroll Now","certificate")}}>Enroll For Free</button>
            </div>
          </div>
        </div>
    </section>}
    <Suspense fallback={<div>Loading</div>}>
        <CourseSwiperComponent2 />
    </Suspense>
    {freecoursecontent && <section className='common-style background-dark-secondary pb-5' id='faq'>
        <div className='container'>
          <div className='row'>
            <div className='text-white heading-with-text mb-5 text-center'>
              <h2 className='fs-40 fw-normal mb-3 fs-18'>Frequently Asked Questions</h2>
              <p>Looking for answers to other questions?</p>
            </div>
            <div className='col-lg-8 mx-auto mt-2'>
                <div class="accordion" id="accordionFaq">
                  {freecoursecontent.faq.qna.map((item, index) => {
                  return (
                    <div className="accordion-item border-0 mb-3" key={index}>
                      <h2 className="accordion-header border-0">
                        <button className={`accordion-button ${index === 0 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index + 1}`} aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`collapse${index + 1}`}>
                          {item.question}
                        </button>
                      </h2>
                      <div id={`collapse${index + 1}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} data-bs-parent="#accordionFaq">
                        <div className="accordion-body border-0">
                          <p>{item.answer}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
                </div>
              </div>
          </div>
        </div>
    </section>}
    <Suspense fallback={<div>Loading</div>}>
        <CourseSwiperComponent3 />
    </Suspense>
    {freecoursecontent && <section id='popularCategory' className='background-dark-secondary'>
      <div className="container">
        <div className='text-white heading-with-text mb-5 text-center'>
            <h2 className='fs-40 fw-normal mb-3 fs-18'>{freecoursecontent.popularCategories.heading}</h2>
            <p>{freecoursecontent.popularCategories.subheading}</p>
        </div>
        <div className="row">
          {freecoursecontent.popularCategories.tags.map((item,index)=>{
            return (
              <a key={index} href={item.url} target='_blank' className="btn btn-dark btn-dark-primary p-0 w-auto me-3 mb-3">
                <p className='text-white text-center m-0'>{item.tagNames}</p>
              </a>
            )
          })}
        </div>
      </div>
    </section>}
    <Suspense fallback={<div>Loading</div>}>
        <CourseSwiperComponent4 />
    </Suspense>
    <section className='common-style background-dark-primary pb-5' id='contact'>
        <div className='container'>
          <div className='text-white heading-with-text mb-5 text-center'>
            <h2 className='fs-40 fw-normal mb-3 fs-18'>Contact Us Today</h2>
            <p>Take the first step towards a future of innovation & excellence with Analytics Vidhya</p>
          </div>
          <div className='row'>
            <div className='col-lg-7 col-md-12 col-sm-12 mb-3'>
              <div className="card background-dark-secondary shadow-sm rounded-4 py-3 border border-1">
                <div className="card-header border-0">
                  <h2 className='text-center fs-24 text-white fw-medium'>Unlock Your AI & ML Potential</h2>
                </div>
                <div className="card-body border-0">
                  <div className="row">
                      <div className="col-lg col-md-12 col-12 mb-3">
                          <label htmlFor="contactFullName" className="form-label text-white fs-14">Full Name</label>
                          <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactFullName" placeholder="Your Full Name" autoComplete='off' value={contact.fullname} onChange={(e)=>setContact((prev)=>{return{...prev,fullname:e.target.value}})}/>
                      </div>
                      <div className="col-lg col-md-12 col-12 mb-3">
                          <label htmlFor="contactPhoneNumber" className="form-label text-white fs-14">Phone Number</label>
                          <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="contactPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={contact.phone} onChange={(e)=>setContact((prev)=>{return{...prev,phone:e.target.value}})} />
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg col-md-12 col-12">
                          <label htmlFor="contactEmail" className="form-label text-white fs-14">Email Id</label>
                          <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactEmail" placeholder="Your Email Id" autoComplete='off' value={contact.email} onChange={(e)=>setContact((prev)=>{return{...prev,email:e.target.value}})} />
                      </div>
                  </div>
                  <div className="mt-3 mb-2 d-flex flex-wrap">
                      <div className="form-check d-flex align-items-center mb-2 me-4">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                      </div>
                      <div className="form-check d-flex align-items-center">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                      </div>
                  </div>
                </div>
                <div className="card-footer border-0">
                  <ReCAPTCHA
                    className='mb-3'
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                  />
                  <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center" onClick={saveContactForm} disabled={!captcha}>Connect With Us</button>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-12 col-sm-12 mb-3 ps-lg-5 d-flex flex-column justify-content-center'>
              <h3 className='text-white'>Get Expert Guidance</h3>
              <p className='text-dark-tertiary mt-4 fs-20 fw-light'>Need Support? We’ve Got Your Back Anytime!</p>
              <ul>
                <li className='row mb-3'>
                  <div className='col-auto mb-3'>
                    <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18.117 14.702L13.999 15.5C11.217 14.104 9.49901 12.5 8.49902 10L9.26902 5.87L7.81402 2H4.06302C2.93502 2 2.04702 2.932 2.21602 4.047C2.63602 6.83 3.87602 11.877 7.49902 15.5C11.304 19.305 16.785 20.956 19.801 21.613C20.966 21.866 21.999 20.958 21.999 19.765V16.181L18.117 14.702Z" stroke="white" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                  <div className='col-12 col-md'>
                  <p className='fw-bold fs-16 text-white mb-0 text-break'><a className='text-white' href='tel:+91-8068342847'>+91-8068342847</a> | <a className='text-white' href='tel:+91-8046107668'>+91-8046107668</a></p>
                    <span className='d-block text-dark-tertiary'>10AM - 7PM (IST) Mon-Sun</span>
                  </div>
                </li>
                <li className='row mb-3'>
                  <div className='col-auto mb-3'>
                    <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M7 9L12 12.5L17 9" stroke="white" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 17V7C2 6.46957 2.21071 5.96086 2.58579 5.58579C2.96086 5.21071 3.46957 5 4 5H20C20.5304 5 21.0391 5.21071 21.4142 5.58579C21.7893 5.96086 22 6.46957 22 7V17C22 17.5304 21.7893 18.0391 21.4142 18.4142C21.0391 18.7893 20.5304 19 20 19H4C3.46957 19 2.96086 18.7893 2.58579 18.4142C2.21071 18.0391 2 17.5304 2 17Z" stroke="white" stroke-width="1.3125"/>
                      </svg>                    
                    </div>
                  </div>
                  <div className='col-12 col-md'>
                    <a className='text-white' href='mailto:customersupport@analyticsvidhya.com'><p className='fw-bold fs-16 text-white mb-0 text-break'>customersupport@analyticsvidhya.com </p></a>
                    <span className='d-block text-dark-tertiary'>You'll hear back in 24 hours</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </section>
    {freecoursecontent && <section className="seo-links background-dark-secondary d-none" id='seoFooter'>
      <div className="container">
        <div className="row">
            {freecoursecontent.additionalCards.map((card, index) =>{
              return (
                <div className="col-12 col-md-6 col-lg-4" key={index}>
                  <h2 className='text-white fs-24 mb-3'>{card.title}</h2>
                  <ul className='list-style-none mb-5'>
                    {card.bulletPoints.map((point, pointIndex) => (
                      <li className='fs-16 text-white mb-3' key={pointIndex}>
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            })}
        </div>
      </div>
    </section>}
    <Cookies platform={platformName} />
    <Footer platform={platformName} />
    <Flashstrip />
  </main>
  )
}


const mapStateToProps = (state) => ({
  blogContent:state.blogContentReducer.blogcontent,
  freecoursecontent:state.freeCourseContentReducer.freecoursecontent,
  loading:state.freeCourseContentReducer.loading,
  error:state.freeCourseContentReducer.error,
  country_name:state.freeCourseContentReducer.country_code
});

const mapDispatchToProps = dispatch=>({
  fetchBlogContentSuccessHandler:data=>dispatch(fetchBlogContentSuccess(data)),
  fetchFreeCourseContentHandler:data=>dispatch(fetchFreeCourseContent(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildingLanguageModelsForCode);
