import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BuildingLanguageModelsForCode from '../FreeCourses/pages/BuildingLanguageModelsForCode';
import Course from '../FreeCourses/pages/Course';
import Error404 from '../../../common/Alert/Error404';

export default function FreeCoursesRouters() {
  return (
    <Routes>
        <Route path='/' exact element={<Course />} />
        <Route path='/:platformName' exact element={<BuildingLanguageModelsForCode />} />
        <Route path='*' element={<Error404 />} />
    </Routes>
  )
}
