import React, { Suspense, useEffect, useState } from 'react'
import B2Bheader from '../components/B2Bheader';
import Footer from '../../../../common/Footer/Footer';
import $ from 'jquery';
import Modal from '../components/Modal';
import { validatedEmail } from '../../../../../utils/InputValidation';
import { scrollTop, submitRegisterationForm } from '../../../../../utils';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import { connect } from 'react-redux';
import Title from '../../../../common/Header/Title';

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;

function DataCulture({csrf}) {
    const [formData,setFormData] = useState({
        formType:"",
        section:""
    });
    const [email,setEmail] = useState('');
    const [success,setSuccess] = useState(false);
    const [heading,setHeading] = useState(<>Contact us today!</>);
    const [buttonTxt, setButtonTxt] = useState(<>Contact us</>);
    const [message, setMessage] = useState("");

    useEffect(()=>{
        $(document).ready(function() {
            $(window).scroll(function() {
                if ($(this).scrollTop() >= 50) {
                $('.navbar').addClass('active');
                } else {
                $('.navbar').removeClass('active');
                }
            });
        });
    },[]);

    useEffect(()=>{
        $('#experience .nav-link').click((e)=>{
            let height = $('#experience .nav-link.active').index();
            $('#v-pills-tab').css('--height', `${33.33 * (height+1)}%`);
        })
    },[]);

    const saveNewsletterForm = (e)=>{
        e.preventDefault();

        if(!validatedEmail(email)){
        $('.error').remove();
        $('#newsletterEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Email Id</small>`);
        $('#newsletterEmail').css('border-color','red');
        $('#newsletterEmail').css('color','red');
        setTimeout(()=>{
            $('#newsletterEmail').css('border-color','#383838');
            $('#newsletterEmail').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },2000)
        return false;
        }
    
        let user_data = {
            "fullname":null,
            "email":email,
            "phone":null,
            "country_code":null,
        }
        let additional_detail ={
        "page_url":window.location.href
        }
        setEmail("");
        setSuccess(true);
        setTimeout(()=>{
        setSuccess(false);
        window.open('https://newsletter.ai/',"_blank");
        },4000)
        submitRegisterationForm(user_data,additional_detail,"enterprise-data-culture","newsletter",csrf)
        .then((data)=>{
        })
        .catch((error)=>{
        console.log(error)
        // setIsError(true);
        // setTimeout(()=>{
        //   setIsError(false);
        // },4000)
        })
    }

    useEffect(()=>{
        scrollTop();
    },[])

    useEffect(()=>{
        new window.Swiper('.swiper1', {
            loop: true,
            spaceBetween:0,
            slidesPerView:7,
            speed:3000,
            autoplay:{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter:true
            },
            breakpoints:{
                0: {
                    slidesPerView: 2,
                },
                599: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1400: {
                    slidesPerView: 6,
                },
            }
        });
    },[]);

  return (
    <main id='b2b'>
        <Title title={'Transform Your Organization with a Strong Data Culture'} description={'Discover how fostering a data culture empowers teams to make data-driven decisions, enhancing efficiency, innovation, and overall business success.'} image_og={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'og.jpg?format=avif'} title_og={'Analytics Vidhya | The ultimate place for Generative AI, Data Science and Data Engineering'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
        <B2Bheader />
        {success && <SuccessAlert message={message} />}        
        <Modal platform="enterprise-data-culture" formtype={formData.formType} section={formData.section} heading={heading} buttonTxt={buttonTxt} message={message}/>
        <section id="b2bHeroFold" style={{backgroundImage: `url('https://d26nt4befnrop2.cloudfront.net/b2b/data_culture.png'), linear-gradient(to right, #1A181B 0 80%, transparent)`}}>
            <div className="container align-items-center h-100">
                <div className="row align-items-center h-100">
                    <div className="col-lg-7 col-md-12 col-12">
                        <h1 className="fs-56  mb-3 fw-semibold">
                            <span className='text-white'>Build a </span>
                            <span className="text-gradient">Data-First </span>
                            <span className='text-white'>Enterprise</span>
                        </h1>
                        <p className='text-white fs-20 mb-4'>Infuse your enterprise with a data-centric ethos, backed by AI-driven insights</p>
                        <button className='btn btn-dark btn-dark-primary me-3 mb-5'  data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"hero-fold"}); setHeading(<>Contact us today!</>); setButtonTxt(<>Contact us</>); setMessage("Thank you for reaching out. We'll be in touch soon.")}}>Contact us</button>
                        <button className='btn btn-dark btn-dark-primary mb-5'  data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'get_demo',section:"hero-fold"}); setHeading(<>Get Demo</>); setButtonTxt(<>Book a Demo</>); setMessage("Thank you for your inquiry about our demo. We'll get in touch with you soon.")}}>Get demo</button>
                        <ul className='list-style-none mt-5 d-flex justify-content-between flex-wrap'>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>10.1 Million+</h2>
                                <p className='text-dark-secondary fs-18'>Professionals benefited</p>
                            </li>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>350K+</h2>
                                <p className='text-dark-secondary fs-18'>Learners impacted</p>
                            </li>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>400+</h2>
                                <p className='text-dark-secondary fs-18'>Global firms impacted</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className='py-60 background-dark-secondary' id='alumniLogo'>
            <div className='text-white col-lg-8 heading-with-text mb-5 text-center mx-auto'>
            <h2 className='fs-40 fw-normal mb-3'>Trusted by 500+ Enterprises</h2>
            <p className='text-dark-secondary'>Your enterprise could be the next to leverage our proven AI expertise and strong partnership</p>
            </div>
            <div className='swiper1 overflow-hidden'> 
                <div className="swiper-wrapper">
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/ABINBEV-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/AE-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/Artboard 23-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/B&C-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/BOSCH-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/CBRE-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/FRACTAL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/GENPACT-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/HDFC-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/INFOSYS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/JSL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/L&T-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/LM-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/M&c-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/PB-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/PIRAMAL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/PS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/RIPIK.AI-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/SB-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/SYNCHRONY-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/TVS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/WNS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/company_logo/ZEPTO-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                </div>
            </div>
        </section>
        <section className='event-tabs py-120 bg-white' id='experience'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text">
                        <h2 className="fs-40 fw-normal mb-3">Is Your Business Data-Driven?</h2>
                        <p className="fs-18 fw-normal">Data. Not just insights, it's your competitive edge. Unlock potential with a data-driven culture</p>
                    </div>
                </div>
                <div className="d-flex align-items-start col-lg-11 mx-auto" id='experienceTabs'>
                    <div className="nav flex-column nav-pills me-3 col-lg-6" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active p-4 text-start" id="v-pills-discover-tab" data-bs-toggle="pill" data-bs-target="#v-pills-discover" type="button" role="tab" aria-controls="v-pills-discover" aria-selected="true">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Holistic Data Integration</h3>
                        <p className='fs-16 fw-normal'>Foster teamwork, break down barriers, and achieve true data integration by fostering a collaborative approach</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-learn-tab" data-bs-toggle="pill" data-bs-target="#v-pills-learn" type="button" role="tab" aria-controls="v-pills-learn" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Collaborative Data Mindset</h3>
                        <p className='fs-16 fw-normal'>Break down silos, empower teams, and drive data-driven decisions with a unified approach to data and a collaborative culture</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-engage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-engage" type="button" role="tab" aria-controls="v-pills-engage" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Empowering Data Literacy</h3>
                        <p className='fs-16 fw-normal'>Make data accessible, understandable, and actionable for everyone with a comprehensive data integration strategy</p>
                        </button>
                    </div>
                    <div className="tab-content col-lg-6 col-12 rounded-3" id="v-pills-tabContent">
                        <div className="card tab-pane fade show active bg-transparent border-0 m-0" id="v-pills-discover" role="tabpanel" aria-labelledby="v-pills-discover-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-discover">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-discover" data-bs-parent="#experienceTabs" aria-expanded="true" aria-controls="v-collapse-discover">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Holistic Data Integration</h3>
                                <p className='fs-16 fw-normal pe-4 text-white mb-3'>Foster teamwork, break down barriers, and achieve true data integration by fostering a collaborative approach</p>
                                </a>
                            </div>
                            <div id="v-collapse-discover" className="collapse show py-4" role="tabpanel" aria-labelledby="v-collapse-heading-discover" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/holistic_new.jpg?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent tab-pane fade" id="v-pills-learn" role="tabpanel" aria-labelledby="v-pills-learn-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-learn">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-learn" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-learn">
                                    <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Collaborative Data Mindset</h3>
                                    <p className='fs-16 fw-normal pe-4 text-white mb-3'>Break down silos, empower teams, and drive data-driven decisions with a unified approach to data and a collaborative culture</p>
                                </a>
                            </div>
                            <div id="v-collapse-learn" className="collapse py-4" role="tabpanel" aria-labelledby="v-collapse-heading-learn" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/collaborative_new.jpg?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-engage" role="tabpanel" aria-labelledby="v-pills-engage-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-engage">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-engage" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-engage">
                                    <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Empowering Data Literacy</h3>
                                    <p className='fs-16 fw-normal pe-4 text-white mb-3'>Make data accessible, understandable, and actionable for everyone with a comprehensive data integration strategy</p>
                                </a>
                            </div>
                            <div id="v-collapse-engage" className="collapse py-4" role="tabpanel" aria-labelledby="v-collapse-heading-engage" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/empowering_new.jpg?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="background-dark-secondary py-60" id='dataCultureImportant'>
            <div className="container">
                <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Why a Strong Data Culture is Essential?</h2>
                    <p className="fs-18 fw-normal text-dark-secondary">A thriving Data Culture goes beyond usage—it’s about creating an environment where data is the catalyst for growth and foresight</p>
                </div>
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 col-12">
                        <h2 className='text-white fs-40 fw-bold mb-3'>75%</h2>
                        <div className="underline-bold mb-3"></div>
                        <p className='text-dark-secondary fs-18 fw-light'>of business leaders require data for sound decision-making</p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <h2 className='text-white fs-40 fw-bold mb-3'>60%</h2>
                        <div className="underline-bold mb-3"></div>
                        <p className='text-dark-secondary fs-18 fw-light'>of business leaders seek data for competitive positioning</p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <h2 className='text-white fs-40 fw-bold mb-3'>75%</h2>
                        <div className="underline-bold mb-3"></div>
                        <p className='text-dark-secondary fs-18 fw-light'>of business leaders rely on data for operational excellence</p>
                    </div>
                </div>
            </div>
        </div>
        <section className='common-style bg-white' id='trainingSolution'>
            <div className='container'>
                <div className="mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Enabling Data-Driven Excellence</h2>
                    <p className="fs-18 fw-normal">Drive your organization forward with our comprehensive programs designed to foster a culture of data proficiency</p>
                </div>
                <div className='row g-4'>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/data_literacy.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Data Literacy Program</h2>
                            <p className='fs-16 text-light-primary'>Increase data awareness & usage across your teams. Help users in understanding how to gain and interpret the right data to solve business and communicate with stakeholders across your organization</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/analytics_maturity_new.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Analytics Maturity Assessment</h2>
                            <p className='fs-16 text-light-primary'>Assess your organization's analytics maturity, identify key stakeholders and objectives, evaluate current capabilities in data analytics.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/internal_communities.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Building Internal Communities</h2>
                            <p className='fs-16 text-light-primary'>Utilize internal community platforms for collaboration and knowledge sharing, offer skill-building and learning content, assessments and competitions.</p>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary mx-auto d-table mt-5"  data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"enabling-data-driven-excellence"}); setHeading(<>Get in Touch</>); setButtonTxt(<>Connect with us</>); setMessage("Thank you for your interest. We will be in touch shortly.")}}>Get in touch</button>
            </div>
        </section>
        <section className='common-style-py background-light-secondary d-none'>
            <div className="container">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <h2 className='fs-24 fw-normal'>Stay ahead of the curve with top AI stories and insights delivered to your inbox</h2>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <form id='newsletterForm' className='row g-4' onSubmit={saveNewsletterForm}>
                            <div className="col-lg col-md col-12">
                                <input className='form-control h-100 text-dark' type="email" id='newsletterEmail' value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Email address' autoComplete='off' />
                            </div>
                            <div className="col-lg-auto col-md-auto col-12">
                                <button className='btn btn-primary px-4 py-2 w-100'>Sign Me Up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section className='common-style pb-5 bg-white' id='avAdvantage'>
            <div className='container'>
                <div className="mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Process Flow</h2>
                    <p className="fs-18 fw-normal">Streamlined Expertise for Agile Transformation</p>
                </div>
                <div className='row g-4 align-items-center mb-4'>
                    <div className="col-lg-5 col-md-12 col-12">
                        <h2 className='fw-normal'>Understand</h2>
                        <p>We listen, learn, and uncover the unique data narratives that drive your business, ensuring a bespoke strategy that resonates with your core objectives.</p>
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 ms-auto">
                        <img className='img-fluid w-100 rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"b2b/UNDERSTAND.png?format=webp"} alt="av advantage" />
                    </div>
                </div>
                <div className='row g-4 align-items-center mb-4'>
                    <div className="col-lg-5 col-md-12 col-12 order-2 order-lg-1">
                        <img className='img-fluid w-100 rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"b2b/BUILD.png?format=webp"} alt="av advantage" />
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 ms-auto order-1 order-lg-2">
                        <h2 className='fw-normal'>Build</h2>
                        <p>We tailor training and development modules that bridge gaps and amplify your team's data fluency, all designed to integrate seamlessly with your operational flow.</p>
                    </div>
                </div>
                <div className='row g-4 align-items-center'>
                    <div className="col-lg-5 col-md-12 col-12">
                        <h2 className='fw-normal'>Transform</h2>
                        <p>We guide the integration of new data practices, ensuring your team not only embraces but also thrives on a data-centric approach, ready to make informed decisions that propel growth.</p>
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 ms-auto">
                        <img className='img-fluid w-100 rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"b2b/TRANSFORM.png?format=webp"} alt="av advantage" />
                    </div>
                </div>
            </div>
        </section>
        <section id='b2bPartnerWithUs' className='background-light-secondary py-60'>
            <div className="container">
                <div className="text-dark mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Are You Ready to Make Your Organisation AI-ready?</h2>
                    <p className="fs-18 fw-normal">Partner with us to innovate and drive growth with AI</p>
                </div>
                <button className='btn btn-primary mx-auto d-table py-2 px-4' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"partner-with-us"}); setHeading(<>Contact us today!</>); setButtonTxt(<>Contact us</>); setMessage("Thank you for reaching out. We'll be in touch soon.")}}>Contact us</button>
            </div>
        </section>
        <Footer platform={"new_b2b"}/>
    </main>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = dispatch=>({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(DataCulture);