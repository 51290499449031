import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header/Header';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getQueryData, getQueryDataElevateUser } from '../../../../utils';
import { connect } from 'react-redux';
import Pagination from '../../../common/Page/Pagination';
import Footer from '../../../common/Footer/Footer';
import Flashstrip from '../../../common/Header/Flashstrip';
import HackathonModel from '../components/HackathonModel';
import $ from 'jquery';
import Loader from '../../../common/Loader';
import Cookies from '../../../common/Footer/Cookies';

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;

const SkillTestLeaderboard = ({islogin,csrf}) => {

    const [skillTestLeaderboard, setSkillTestLeaderboard] = useState([]);
    const [stageId, setStageId] = useState(null);
    const [tournamentId, setTournamentId] = useState(null);
    const { tournamentSlug,stageSlug } = useParams();
    const [subjectWiseCurrentUserData,setSubjectWiseCurrentUserData] = useState(null);
    const [subjectWiseOtherUserData,setSubjectWiseOtherUserData] = useState(null);
    const [unionKeyList,setUnionKeyList] = useState(null);
    const [showLoader,setShowLoader] = useState(true);

    const [urlSearchParams] = useSearchParams();

    useEffect(()=>{
        let query = `query {
            stageData(stageSlug: "${stageSlug}") {
              id
            }
            tournamentData(tournamentSlug: "${tournamentSlug}") {
                tournament {
                    id
                }
            }
          }`;
        getQueryData(query,"newcompetition")
        .then((data)=>{
            if(data.data.stageData) {
                setStageId(data.data.stageData.id);
            }
            if(data.data.tournamentData?.tournament) {
                setTournamentId(data.data.tournamentData.tournament?.id);
            }
        })
        .catch((error)=>{
            console.error(error);
        })
    },[])

    useEffect(()=>{
        if(islogin == false){
            window.location.href = "/datahack";
        }
    },[islogin])

    useEffect(()=>{
        if(stageId) {
            let query = `
            query {
                skillTestLeaderboard(stageId: "${stageId}", page: 1, limit: 10) {
                  totalPages
                  skilltestLeaderboardData {
                    rank
                    teamName
                    userId
                    score
                    accuracy
                    subjectWiseData
                  }
                  skilltestCurrentUserData {
                    rank
                    score
                    teamName
                    userId
                    accuracy
                    subjectWiseData
                  }
                }
            }`;
            if(islogin) {
                if(csrf) {
                    getQueryDataElevateUser(query, 'newleaderboard',"True",csrf)
                        .then((data) => {
                            setSkillTestLeaderboard(data.data.skillTestLeaderboard);
                            setShowLoader(false);
                            console.log("Skilltest Leaderboard Data:", data.data.skillTestLeaderboard);
                            setSubjectWiseCurrentUserData(data.data.skillTestLeaderboard.skilltestCurrentUserData);
                        })
                        .catch((error) => {
                            console.log(error);
                            setShowLoader(false);
                        })
                }
            } else {
                getQueryData(query, 'newleaderboard')
                    .then((data) => {
                        setSkillTestLeaderboard(data.data.skillTestLeaderboard);
                        setShowLoader(false);
                        console.log("Skilltest Leaderboard Data:", data.data.skillTestLeaderboard);
                        setSubjectWiseCurrentUserData(data.data.skillTestLeaderboard.skilltestCurrentUserData);
                    })
                    .catch((error) => {
                        console.log(error);
                        setShowLoader(false);
                    })
            }
        }
    },[stageId,islogin,csrf])

    const pageChangeHandler = (page) => {
        setShowLoader(true);
        if(stageId) {
            let query = `
            query {
                skillTestLeaderboard(stageId: "${stageId}", page: ${page}, limit: 10) {
                  totalPages
                  skilltestLeaderboardData {
                    rank
                    teamName
                    userId
                    score
                    accuracy
                    subjectWiseData
                  }
                  skilltestCurrentUserData {
                    rank
                    score
                    teamName
                    userId
                    accuracy
                    subjectWiseData
                  }
                }
            }`;
            if(islogin) {
                if(csrf) {
                    getQueryDataElevateUser(query, 'newleaderboard',"True",csrf)
                        .then((data) => {
                            setSkillTestLeaderboard(data.data.skillTestLeaderboard);
                            setShowLoader(false);
                            console.log("Skilltest Leaderboard Data:", data.data.skillTestLeaderboard);
                        })
                        .catch((error) => {
                            console.log(error);
                            setShowLoader(false);
                        })
                }
            } else {
                getQueryData(query, 'newleaderboard')
                    .then((data) => {
                        setSkillTestLeaderboard(data.data.skillTestLeaderboard);
                        setShowLoader(false);
                        console.log("Skilltest Leaderboard Data:", data.data.skillTestLeaderboard);
                    })
                    .catch((error) => {
                        console.log(error);
                        setShowLoader(false);
                    })
            }
        }
    }

    const updateCompareData = (data) =>{
        setSubjectWiseOtherUserData(data);
        var keysUnion = $.extend({}, JSON.parse(subjectWiseCurrentUserData.subjectWiseData), JSON.parse(data.subjectWiseData));
        var keysList = Object.keys(keysUnion);
        setUnionKeyList(keysList);
    }

    const openChallengeModal = (data) =>{
        setSubjectWiseOtherUserData(data);
        window.$('#challengeModal').modal("show");
    }

  return (
    <>
    {showLoader && <Loader />}
    <Header />
    <HackathonModel keysList={unionKeyList} subjectWiseCurrentUserData={subjectWiseCurrentUserData} subjectWiseOtherUserData={subjectWiseOtherUserData} stageId={stageId} tournamentId={tournamentId} urlSearchParams={urlSearchParams}/>
    <section className='common-style background-dark-primary pb-5' id="leaderboard">
        <div className="container">
            <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item text-dark-tertiary"><a className='text-white text-decoration-none text-capitalize pointer' onClick={()=>{window.history.back()}}>Datamin</a></li>
                  <li className="breadcrumb-item active text-white" aria-current="page">Leaderboard</li>
                </ol>
            </nav>
            <div className='text-white heading-with-text mb-5'>
                <h2 className='fs-32 fw-normal d-none'>Datamin</h2>
            </div>
            <div id="hackathonDetailContent">
                <div class="table-responsive my-3">
                    <table class="table text-white background-dark-secondary table-striped">
                        <thead className='text-white border-bottom border-dark'>
                            <td>Rank</td>
                            <td>Username</td>
                            <td>Score</td>
                            <td className='d-none'>View stats</td>
                        </thead>
                        {skillTestLeaderboard && skillTestLeaderboard.skilltestCurrentUserData && <>
                            <tr className='active'>
                            <td>#{skillTestLeaderboard.skilltestCurrentUserData.rank}</td>
                            <td className='row align-items-center px-2'>
                                <div className="leaderboard-avtar-wrapper rounded-circle overflow-hidden col-auto p-0 me-2">
                                    <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{skillTestLeaderboard.skilltestCurrentUserData?.username ? skillTestLeaderboard.skilltestCurrentUserData?.username[0].toUpperCase() : (skillTestLeaderboard.skilltestCurrentUserData?.anonymousName ? skillTestLeaderboard.skilltestCurrentUserData?.anonymousName[0].toUpperCase() : (skillTestLeaderboard.skilltestCurrentUserData?.teamName ? skillTestLeaderboard.skilltestCurrentUserData?.teamName[0].toUpperCase() : ""))}</p>
                                </div>  
                                <p className='bg-transparent text-white col mb-0 text-truncate'>
                                    <a href={skillTestLeaderboard.skilltestCurrentUserData?.username ? `/profile/${skillTestLeaderboard.skilltestCurrentUserData?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">You</a>
                                </p>
                            </td>
                            <td>{skillTestLeaderboard.skilltestCurrentUserData.score}</td>
                            <td className='d-none'>{skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData && Object.keys(JSON.parse(skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData)).length !== 0 && 
                                <button className='btn bg-transparent text-white border-0 text-nowrap' data-bs-toggle="collapse" data-bs-target="#view-stats-user" aria-expanded="false" aria-controls="view-stats-user">
                                    View
                                    <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M2.42442 10.9768C2.13978 10.3575 2.13978 9.64312 2.42442 9.02386C3.742 6.15735 6.63866 4.16699 10.0002 4.16699C13.3617 4.16699 16.2584 6.15735 17.576 9.02386C17.8606 9.64312 17.8606 10.3575 17.576 10.9768C16.2584 13.8433 13.3617 15.8337 10.0002 15.8337C6.63866 15.8337 3.74201 13.8433 2.42442 10.9768Z" stroke="white" stroke-width="1.67"/>
                                        <path d="M12.5002 10.0003C12.5002 11.381 11.3809 12.5003 10.0002 12.5003C8.61948 12.5003 7.50019 11.381 7.50019 10.0003C7.50019 8.61961 8.61948 7.50033 10.0002 7.50033C11.3809 7.50033 12.5002 8.61961 12.5002 10.0003Z" stroke="white" stroke-width="1.67"/>
                                    </svg>
                                </button>}
                            </td>
                            </tr>
                            <tr>
                            <td class="collapse view-stats" id="view-stats-user" colSpan={4}>
                                <div class="table-responsive p-0">
                                    <table class="table text-dark-tertiary background-dark-tertiary table-bordered m-0">
                                        <thead>
                                            <td>Metrics</td>
                                            {skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData && Object.entries(JSON.parse(skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData)).map(([subject, data],index)=>(
                                            <td key={index} className='text-nowrap'>{subject}</td>
                                            ))}
                                        </thead>
                                        <tr className='background-dark-tertiary'>
                                            <td>Score</td>
                                            {skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData && Object.entries(JSON.parse(skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData)).map(([subject, data],index)=>(
                                            <td className='text-white' key={index}>{data?.user_subject_score}</td>
                                            ))}
                                        </tr>
                                        <tr className='background-dark-tertiary'>
                                            <td>Accuracy</td>
                                            {skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData && Object.entries(JSON.parse(skillTestLeaderboard.skilltestCurrentUserData.subjectWiseData)).map(([subject, data],index)=>(
                                            <td className='text-white' key={index}>{data?.subject_accuracy}</td>
                                            ))}
                                        </tr>
                                    </table>
                                </div>
                            </td>
                            </tr>
                            </>
                        }
                        {skillTestLeaderboard && skillTestLeaderboard.skilltestLeaderboardData && skillTestLeaderboard.skilltestLeaderboardData.length>0 && skillTestLeaderboard.skilltestLeaderboardData.map((data,index)=>(
                        <>
                            <tr>
                            <td>#{data.rank}</td>
                            <td className='row align-items-center px-2'>
                                <div className="leaderboard-avtar-wrapper rounded-circle overflow-hidden col-auto p-0 me-2">
                                    <p className='avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle'>{data?.username ? data?.username[0].toUpperCase() : (data?.anonymousName ? data?.anonymousName[0].toUpperCase() : (data?.teamName ? data?.teamName[0].toUpperCase() : ""))}</p>
                                </div>  
                                <p className='bg-transparent text-white col mb-0 text-truncate'>
                                    <a href={data?.username ? `/profile/${data?.username}` : "javascript:void(0)"} className="text-decoration-none text-white">{data?.username ? data?.username : (data?.anonymousName ? data?.anonymousName : data?.teamName)}</a>
                                </p>
                            </td>
                            <td>{data.score}</td>
                            <td className='d-none'>{data.subjectWiseData && Object.keys(JSON.parse(data.subjectWiseData)).length !== 0 && 
                                <>
                                    <button className='btn btn-dark btn-dark-primary text-white me-3 btn-active' onClick={()=>{openChallengeModal(data)}}>
                                        Challenge
                                    </button>
                                    {subjectWiseCurrentUserData && <button className='btn btn-dark btn-dark-primary text-white me-3 btn-active' data-bs-toggle="modal" data-bs-target="#skilltestCompareStatsModal" onClick={()=>{updateCompareData(data)}}>
                                        Compare with me
                                    </button>}
                                    <button className='btn bg-transparent text-white border-0 text-nowrap' data-bs-toggle="collapse" data-bs-target={`#view-stats${index}`} aria-expanded="false" aria-controls={`view-stats${index}`}>
                                        View
                                        <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M2.42442 10.9768C2.13978 10.3575 2.13978 9.64312 2.42442 9.02386C3.742 6.15735 6.63866 4.16699 10.0002 4.16699C13.3617 4.16699 16.2584 6.15735 17.576 9.02386C17.8606 9.64312 17.8606 10.3575 17.576 10.9768C16.2584 13.8433 13.3617 15.8337 10.0002 15.8337C6.63866 15.8337 3.74201 13.8433 2.42442 10.9768Z" stroke="white" stroke-width="1.67"/>
                                            <path d="M12.5002 10.0003C12.5002 11.381 11.3809 12.5003 10.0002 12.5003C8.61948 12.5003 7.50019 11.381 7.50019 10.0003C7.50019 8.61961 8.61948 7.50033 10.0002 7.50033C11.3809 7.50033 12.5002 8.61961 12.5002 10.0003Z" stroke="white" stroke-width="1.67"/>
                                        </svg>
                                    </button>
                                </>}
                            </td>
                            </tr>
                            <tr>
                            <td class="collapse view-stats" id={`view-stats${index}`} colSpan={4}>
                                <div class="table-responsive p-0">
                                    <table class="table text-dark-tertiary background-dark-tertiary table-bordered m-0">
                                        <thead>
                                            <td className=''>Metrics</td>
                                            {data.subjectWiseData && Object.entries(JSON.parse(data.subjectWiseData)).map(([subject, data],index)=>(
                                            <td key={index} className='text-nowrap'>{subject}</td>
                                            ))}
                                        </thead>
                                        <tr className='background-dark-tertiary'>
                                            <td>Score</td>
                                            {data.subjectWiseData && Object.entries(JSON.parse(data.subjectWiseData)).map(([subject, data],index)=>(
                                            <td className='text-white' key={index}>{data?.user_subject_score}</td>
                                            ))}
                                        </tr>
                                        <tr className='background-dark-tertiary'>
                                            <td>Accuracy</td>
                                            {data.subjectWiseData && Object.entries(JSON.parse(data.subjectWiseData)).map(([subject, data],index)=>(
                                            <td className='text-white' key={index}>{data?.subject_accuracy}</td>
                                            ))}
                                        </tr>
                                    </table>
                                </div>
                            </td>
                            </tr>
                        </>
                        ))}
                    </table>
                </div>
                {skillTestLeaderboard && skillTestLeaderboard.totalPages && <Pagination last_page={skillTestLeaderboard.totalPages} updatePageNumber={(page)=>pageChangeHandler(page)}/>}
            </div>
        </div>
    </section>
    <Footer platform={"newcompetition_leaderboard"}/>
    <Cookies platform={'newcompetition'}  />
    <Flashstrip />
</>
  )
}

const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = dispatch=>({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(SkillTestLeaderboard);