// Function to validate an email address.
// It checks if the provided 'value' follows a common email pattern.
// Returns true if it's a valid email address; otherwise, returns false.
export let validatedEmail = (value) => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(value)
}

export let validateWorkEmail = (email) => {
    const forbiddenDomains = /@(?!gmail\.com$|yahoo\.com$|hotmail\.com$|outlook\.com$|aol\.com$|icloud\.com$|protonmail\.com$|zoho\.com$|gmx\.com$|mail\.com$|yandex\.com$)[a-zA-Z0-9.-]+$/;
    return forbiddenDomains.test(email);
}

// Function to validate a mobile number.
// It checks if the provided 'value' is a 10-digit number.
// Returns true if it's a valid 10-digit mobile number; otherwise, returns false.
export let validatedMobile = (value) => {
    var mobilePattern = /^\d{7,12}$/;
    return mobilePattern.test(value)
}

export let validateLinkedInUrl = (value)=> {
    var regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    return regex.test(value)
  }

export let validateZoomLink = (link) => {
    const zoomLinkPattern = /^https:\/\/(?:www\.)?(?:zoom\.us|us02web\.zoom\.us)\/(?:j|my)\/[0-9]+\?*[a-zA-Z0-9\-=&_]*$/;
    return zoomLinkPattern.test(link);
}

export let isValidURL = (link) =>{
    try {
        new URL(link);
        return true;
    } catch (e) {
        return false;
    }
}