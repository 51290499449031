// Function to submit a registration form.
// It sends user data, platform, and UTM parameters to a remote API endpoint.
// - user_data: User data to be submitted.
// - platform: Name of the platform.
// - setIsModalOpen: Function to control the modal open state.
// The function constructs a GraphQL mutation query, appends necessary data, and sends it to the API endpoint for registration.
import moment from 'moment';

export const submitRegisterationForm = (user_data,additional_user_data,platform,formtype,csrfToken)=>{
    const formData = new FormData();
    let utm_params = null;
    if(additional_user_data.section == "homepage_popup"){
      utm_params = {
        "utm_source":"av_homepage",
        "utm_medium":"overlay_popup"
      }
    }
    else{
      utm_params = getUTMParameters();
    }
    utm_params = utm_params ? JSON.stringify(JSON.stringify(utm_params)):utm_params;
    formData.append('query', `
        mutation MyMutation {
            createContact(
            additionalData:${JSON.stringify(JSON.stringify(additional_user_data))}
            formName: "${formtype}"
            platformName:"${platform}"
            utmKwargs: ${utm_params}
            userDataKwargs: ${JSON.stringify(JSON.stringify(user_data))}
            ) {
            contact {
                createdAt
                email
                fullname
                id
            }
            }
        }
    ` );
    formData.append('source', 'newmarketingpages');
    return fetch(process.env.REACT_APP_GENAI_CONTENT,{
        method: 'POST',
        body:formData,
        headers: {
            "X-CSRFToken":csrfToken,
        },
    })
    .then((response) => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then((data) => {
      if(user_data.email){
        window.dataLayer.push({'event': `Lead Drop`,'email':user_data.email});
      }
      else {
        const urlParams = new URLSearchParams(window.location.search);
        const key = 'utm_medium';
        if(urlParams.has(key) && urlParams.get(key)){
          window.dataLayer.push({'event': `Lead Drop`,'email':urlParams.get(key)});
        }
        else{
          window.dataLayer.push({'event': `Lead Drop`,'email':user_data.phone+"@gmail.com"});
        }
      }
      return data;
    })
}

// Function to extract UTM parameters from the URL query string.
// It checks for the presence of specific UTM keys (e.g., utm_source, utm_medium) and retrieves their values.
// Returns an object containing UTM key-value pairs, or null if no UTM parameters are found.
export function getUTMParameters() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content','gcl_id',,'irclickid'];

    const utmValues = {};
  
    utmKeys.forEach((key) => {
      if (urlParams.has(key)) {
        utmValues[key] = urlParams.get(key);
      } else {
        utmValues[key] = null;
      }
    });
  
    const allValuesNull = utmKeys.every((key) => utmValues[key] === null);
    return allValuesNull ? null : utmValues;
}
  
export const submitPlatformFormData = (platform,query_json,csrfToken)=>{
    const formData = new FormData();
    formData.append('query', `
    mutation MyMutation {
        updatePlatformJsonData(
          platformName: "${platform}"
          jsonData: ${JSON.stringify(JSON.stringify(query_json))}
        ) {
          platformJsonData {
            platformData
          }
        }
      }
    ` );
    formData.append('source', 'newmarketingpages');
    console.log(platform,query_json);
    return fetch(process.env.REACT_APP_BACKCHANNEL,{
        method: 'POST',
        body:formData,
        headers: {
          "X-CSRFToken":csrfToken,
        },
        credentials:process.env.REACT_APP_CREDENTIAL_TYPE
    })
    .then((response) => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json();
    })
}

// This function get data from blog and return the data
export const fetchBlogData = (url,platform)=>{
  const formData = new FormData();
  formData.append('url', url);
  formData.append('platform', platform);
  formData.append('payload', '');
  return fetch(process.env.REACT_APP_BLOG_DATA, {
      method: 'POST',
      body: formData,
      credentials: 'omit',
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    return data;
  })
  .catch((error) => {
    throw new Error(error.message);
  });

}


export const fetchDatahackData = (platform)=>{
  let data = {
      "num_events": 5,
      "event_type": platform
  }

  
  const urlParams = new URLSearchParams(data).toString();
  return fetch(`${process.env.REACT_APP_DATAHACK_URL}?${urlParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token dfff2677f82706479cd30c26e03a14e2ed9add10'
      },
    })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then((data) => {
    return data;
  })
  .catch((error) => {
    throw new Error(error.message);
  });

}

export const scrollTop = ()=>{
  window.scrollTo(0, 0);
}

export const getQueryData = (query,platform)=>{
  const formData = new FormData();
  formData.append('query',query);
  formData.append('source', platform);  
  return fetch(process.env.REACT_APP_GENAI_CONTENT,{
      method: 'POST',
      body:formData,
      credentials:"omit",      
  })
  .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then((data) => {
      return data;
  })
}

export const getQueryDataElevate = (query,platform,forward_user_data)=>{
  const formData = new FormData();
  formData.append('query',query);
  formData.append('source', platform);
  formData.append('forward_user_data', forward_user_data);
  return fetch(process.env.REACT_APP_ELEVATE_GRAPHQL_URL,{
      method: 'POST',
      body:formData,
      credentials:"omit",      
  })
  .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then((data) => {
      return data;
  })
}

export const getQueryDataElevateUser = (query,platform,forward_user_data,csrfToken)=>{
  const formData = new FormData();
  formData.append('query',query);
  formData.append('source', platform);
  formData.append('forward_user_data', forward_user_data);
  return fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL,{
      method: 'POST',
      body:formData,
      headers: {
        "X-CSRFToken":csrfToken,
      },
      credentials:process.env.REACT_APP_CREDENTIAL_TYPE     
  })
  .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then((data) => {
      return data;
  })
}

export const getQueryDataUserProfile = (query,username,platform)=>{
  const formData = new FormData();
  formData.append('query',query);
  formData.append('source', platform);
  formData.append('username',username);
  return fetch(process.env.REACT_APP_ELEVATE_USER_PROFILE_URL,{
      method: 'POST',
      body:formData,
      credentials:"omit"     
  })
  .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then((data) => {
      return data;
  })
}

export const convertDateToLocalDatetime = (date) => {
	if(date) {
		const dateTime = new Date(date);
		const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const userLocalDateTime = dateTime.toLocaleString("en-US", {
		timeZone: userTimeZone,
		});
		return userLocalDateTime;
}}

export const convertDateInFormat = (originalDate,format)=>{
  let time = moment(originalDate).format(format);
  return time;
}

export const calculateDays = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = end - start;
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
}

export const getTeamsCountForEvent = async (tournamentId) => {
  try {
    const teamCountQuery = `
    query TeamsCountQuery {
      allTeams(tournamentId: ${tournamentId}) {
      name
      }
    }
    `;

    const data = await getQueryDataElevate(teamCountQuery, 'newcompetition');
    return data?.data?.allTeams?.length || 0;
  } catch (error) {
    console.log(error);
    return 0;
  }
};


export const calculateTimeRemainingInMilliSeconds = (time) => {
  const now = new Date();
  const end = new Date(time);
  const timeDiff = end - now;
  const diff = Math.ceil(timeDiff);
  return diff;
}


export const convertTimeToFormattedString = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours} hour `;
  }

  if (minutes > 0 || hours > 0) {
    formattedTime += `${minutes} min `;
  }

  if (seconds > 0 || (hours === 0 && minutes === 0)) {
    formattedTime += `${seconds} sec`;
  }

  return formattedTime.trim();
};


export const trimNewlines = (inputString) => {
  return inputString.replace(/\n/g, "");
}


export const setImpactIrclickid = () => {
  const getURLParameter = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  };
  const irclickid = getURLParameter('irclickid');
  if (irclickid) {
    localStorage.setItem('irclickid', irclickid);
  }
}

export const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const calculateTimeDifference = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = end - start;
  const diff = timeDiff;
  return diff;
}

export const getTimeInUTCFormat = (time)=>{
  const date = new Date(time);
  const offset = date.getTimezoneOffset(); 
  date.setMinutes(date.getMinutes() - offset - 330); 
  let stringDate = date.toISOString()
  return stringDate;
}