import React, { useEffect, useState } from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import * as $ from 'jquery';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { getUTMParameters, submitRegisterationForm } from '../../../../../utils';

var iti3 = null;

export default function AgenticAiEmailFieldLeadModal({formname,setSuccess,setIsError,country_name,setSuccessMessage,leadFormCta,leadFormHeading,section,csrf,platformName}) {
    const [userDetails,setUserDetails] = useState({
        fullname:null,
        email:null,
        phone:'',
    })

    const [stepForm, setStepForm] = useState(1);
    const [additionalUserInfo, setAdditionalUserInfo] = useState({
      yourExperience:'',
      sourceOfLearning:'',
      yourGoal:''
    })
    const [formId, setFormId] = useState(null);

    useEffect(()=>{
        const input = document.getElementById("leadPhoneNumber");
        if(input){
          iti3 = intlTelInput(input, {
            allowDropdown: true,
            autoHideDialCode: true,
            initialCountry: country_name?country_name:"IN",
            preferredCountries: ["in", "us"],
          });
          if(country_name){
            iti3.setCountry(country_name);
          }
        }
    },[country_name, formname])

    useEffect(()=>{
        setUserDetails({
            fullname:'',
            email:'',
            phone:'',
        });
        setAdditionalUserInfo({
          yourExperience:'',
          sourceOfLearning:'',
          yourGoal:''
        });
        setStepForm(1);
        $('#checkbox1').prop('checked',true);
    },[formname])

    const saveLeadForm = (e)=>{
        e.preventDefault();

        if(!validatedEmail(userDetails.email)){
          $('.error').remove();
          $('#leadEmail').parent().append(`<small class="text-danger error d-block">Enter a valid email id</small>`);
          $('#leadEmail').css('border-color','red');
          $('#leadEmail').css('color','red');
          setTimeout(()=>{
              $('#leadEmail').css('border-color','#383838');
              $('#leadEmail').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
        }
       
        let user_data = {
            "email":userDetails.email,
            "phone":'',
            "country_code":'',
        }
        let additional_detail ={
          "tnc":true,
          "is_whatsapp":true,
          "section":section,
          "page_url":window.location.href
        }
        setUserDetails({
          fullname:'',
          email:'',
          phone:'',
        });
        $('#checkbox1').prop('checked',true);
        // $('#agenticAiLeadModal .close').click();
        if(formname === 'projects'){
          setSuccessMessage('Projects Are on Their Way to Your Inbox')
        }
        else if(formname === 'curriculum'){
          setSuccessMessage('Curriculum Sent. Check Your Inbox')
        }
        else if(formname === 'waitlist'){
          setSuccessMessage("Thank you for showing interest! We will get in touch with you soon!");
        }
        else if(formname === 'instructor_profile'){
          setSuccessMessage('Profiles on the way! Check Your Inbox')
        }
        else if(formname === 'roadmap'){
          setSuccessMessage('Roadmap on Its Way! Check Your Inbox')
        }
        else if(['enroll','full_payment'].includes(formname)){
          setSuccessMessage("Great! You're almost there. Proceed to pay")
        }
        setSuccess(true);
        if(['enroll','full_payment'].includes(formname)){
          if(country_name === 'IN')
          {              
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=IN197735';
          }
          else if(['US','CA'].includes(country_name)){
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP2900748';
          }
          else{
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP197735';
          }
        }
        setTimeout(()=>{
            setSuccess(false);
            setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
        },4000)
        setStepForm(2);
        submitRegisterationForm(user_data,additional_detail,platformName,formname,csrf)
        .then((data)=>{
          console.log("Data", data);
          if(data.data.createContact) {
            setFormId(data.data.createContact?.contact?.id);
          }
        })
        .catch((error)=>{
        console.log(error)
        // setIsError(true);
        // setTimeout(()=>{
        //   setIsError(false);
        // },4000)
        })
    }

    const removebackdrop = ()=>{
      $('.modal-backdrop.show').remove();
      $('body').removeClass('modal-open');
      $('body').removeAttr('style');
    }

    const handleAdditionalInfoSubmit = (e) =>{
      e.preventDefault();

      if(additionalUserInfo.yourExperience == ""){
        $('.error').remove();
          $('#yourExperience').css('border-color','red');
          $('#yourExperience').css('color','red');
          setTimeout(()=>{
              $('#yourExperience').css('border-color','#383838');
              $('#yourExperience').css('color','#8E8E8E');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }

      if(additionalUserInfo.sourceOfLearning == ""){
        $('.error').remove();
          $('#sourceOfLearning').css('border-color','red');
          $('#sourceOfLearning').css('color','red');
          setTimeout(()=>{
              $('#sourceOfLearning').css('border-color','#383838');
              $('#sourceOfLearning').css('color','#8E8E8E');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }

      if(additionalUserInfo.yourGoal == ""){
        $('.error').remove();
          $('#yourGoal').css('border-color','red');
          $('#yourGoal').css('color','red');
          setTimeout(()=>{
              $('#yourGoal').css('border-color','#383838');
              $('#yourGoal').css('color','#8E8E8E');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }

      const formData = new FormData();
  
      let payload = {
          "id": formId,
          "work_experience": additionalUserInfo.yourExperience,
          "information_source": additionalUserInfo.sourceOfLearning,
          "learners_goal": additionalUserInfo.yourGoal,
      };
  
      formData.append("url","api/follow_up_form/");
      formData.append('platform', 'newmarketingpages');
      formData.append("payload", JSON.stringify(payload));
      formData.append("timeout", 100);
  
      fetch(process.env.REACT_APP_ELEVATE_REST_URL, {
        method: 'POST',
        headers: {
            "X-CSRFToken":csrf,
        },
        credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
        body: formData,
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
          window.$('#agenticAiLeadModal').modal('hide');
          setAdditionalUserInfo({
            yourExperience:'',
            sourceOfLearning:'',
            yourGoal:''
          });
          setStepForm(1);
          if (data.response && data.response.status_code === 200) {
            console.log(data.response.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

  return (
    <>
      {(['enroll','full_payment'].includes(formname)) ?
      <div className="modal lead-modal" id='agenticAiLeadModal' data-bs-backdrop="static" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content background-dark-primary shadow-sm rounded-4">
              <div className="modal-header border-0 p-0">
                <h2 className='text-white fs-32'>{leadFormHeading} <span className='text-orange d-none'>20% off</span></h2>
                <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </button>
              </div>
              <div className="modal-body p-0 pt-5">
                  <div className="row">
                      <div className="col-xl-12 col-lg-12 col-12 mb-3">
                          <label htmlFor="leadFullName" className="form-label text-white fs-14">Email Id</label>
                          <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e=>setUserDetails((prev)=>{return{...prev,email:e.target.value}})} />
                      </div>
                  </div>
                  <div className="mt-4 mb-4 d-flex flex-wrap">
                      <div className="form-check d-flex align-items-center mb-2 me-4">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>Terms & Conditions</a></label>
                      </div>
                      <div className="form-check d-flex align-items-center mb-2">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                      </div>
                  </div>
              </div>
              <div className="modal-footer border-0 p-0">
                  <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={saveLeadForm}>{leadFormCta}</button>
              </div>
          </div>
        </div>
      </div>
      :
      <div className="modal lead-modal" id='agenticAiLeadModal' data-bs-backdrop="static" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4">
            {(stepForm == 1) && <div className="modal-header border-0 p-0">
              <h2 className='text-white fs-32'>{leadFormHeading} <span className='text-orange d-none'>20% off</span></h2>
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>}
            {(stepForm == 2) && <div className="modal-header border-0 p-0 align-items-baseline">
              <div>
                <h3 className='text-white fs-24'>We would like to know more</h3>
                <p className='text-dark-secondary fs-14 m-0'>Help us tailor your experience by finding the right course expert</p>
              </div>
              <button type="button" className="btn fs-14 text-dark-secondary border-0" data-bs-dismiss="modal" aria-label="Close" >
                skip
              </button>
            </div>}
            <div className="modal-body p-0 pt-5">
                <div className="row roadmap-mobile mb-4">
                  <div className="col d-flex align-items-center">
                    <span className={`roadmap-number fs-16 fw-regular rounded-circle small-circle d-flex align-items-center justify-content-center ${(stepForm == 1) ? "text-white active":"text-dark-tertiary" } me-2`}>1</span>
                    <span className={`roadmap-text fs-16 fw-regular ${(stepForm == 1) ? "text-white":"text-dark-tertiary" }`} >Basic Info</span>
                  </div>
                  <div className="col d-flex align-items-center">
                    <span className={`roadmap-number fs-16 fw-regular rounded-circle small-circle d-flex align-items-center justify-content-center ${(stepForm == 2) ? "text-white active":"text-dark-tertiary" } me-2`}>2</span>
                    <span className={`roadmap-text fs-16 fw-regular ${(stepForm == 2) ? "text-white":"text-dark-tertiary" }`} >Additional Info</span>
                  </div>
                </div>
                {(stepForm == 1) && <div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Email Id</label>
                        <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e=>setUserDetails((prev)=>{return{...prev,email:e.target.value}})} />
                    </div>
                </div>
                <div className="mt-4 mb-4 d-flex flex-wrap">
                    <div className="form-check d-flex align-items-center mb-2 me-4">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>Terms & Conditions</a></label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                    </div>
                </div>
                </div>}
                {(stepForm == 2) &&
                  <div className="row mb-2">
                    <div className="col-xl-12 col-lg-12 col-12 mb-4">
                        <label htmlFor="yourExperience" className="form-label text-white fs-14">What is your overall experience in the Data Industry?</label>
                        <select className="form-control background-dark-tertiary py-2 pe-3 custom-select" id='yourExperience'  value={additionalUserInfo.yourExperience} onChange={e=>setAdditionalUserInfo((prev)=>{return{...prev,yourExperience:e.target.value}})}>
                            <option value="" disabled>Select an answer</option>
                            <option value="Fresher">Fresher</option>
                            <option value="0-5 years">0-5 years</option>
                            <option value="5-10 years">5-10 years</option>
                            <option value=">10 years">&gt; 10 years</option>
                        </select> 
                    </div>
                    <div className="col-xl-12 col-lg-12 col-12 mb-4">
                        <label htmlFor="sourceOfLearning" className="form-label text-white fs-14">Where did you learn about the Agentic AI Pioneer Program?</label>
                        <select className="form-control background-dark-tertiary py-2 pe-3 custom-select" id='sourceOfLearning' value={additionalUserInfo.sourceOfLearning} onChange={e=>setAdditionalUserInfo((prev)=>{return{...prev,sourceOfLearning:e.target.value}})}>
                            <option value="" disabled>Select an answer</option>
                            <option value="Google">Google</option>
                            <option value="Meta">Meta</option>
                            <option value="Email">Email</option>
                            <option value="Search">Search</option>
                        </select> 
                    </div>
                    <div className="col-xl-12 col-lg-12 col-12 mb-4">
                        <label htmlFor="yourGoal" className="form-label text-white fs-14">What is your goal?</label>
                        <select className="form-control background-dark-tertiary py-2 pe-3 custom-select" id='yourGoal' value={additionalUserInfo.yourGoal} onChange={e=>setAdditionalUserInfo((prev)=>{return{...prev,yourGoal:e.target.value}})}>
                            <option value="" disabled>Select an answer</option>
                            <option value="Transition to the AI domain">Transition to the AI domain</option>
                            <option value="Upskill in the AI domain">Upskill in the AI domain</option>
                            <option value="Fresher looking to start a career in AI">Fresher looking to start a career in AI</option>
                            <option value="Understand the capabilities of AI">Understand the capabilities of AI</option>
                        </select> 
                    </div>
                  </div>
                }
            </div>
            <div className="modal-footer border-0 p-0">
                {(stepForm == 1) && <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={saveLeadForm}>{leadFormCta}</button>}
                {(stepForm == 2) && <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={(e)=>{handleAdditionalInfoSubmit(e)}}>Submit</button>}
            </div>
        </div>
        </div>
      </div>}
    </>
  )
}
